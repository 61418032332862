/* c2027fbdcc211f82c1d6c31c65228dcf52e88a85
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCollectionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCollectionQuery = (
  { __typename?: 'Query' }
  & { collection: Array<(
    { __typename?: 'CollectionType' }
    & Pick<Types.CollectionType, 'value'>
  )> }
);

export type GetDeveloperSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDeveloperSettingQuery = (
  { __typename?: 'Query' }
  & { developerSetting?: Types.Maybe<(
    { __typename?: 'DeveloperSetting' }
    & Pick<Types.DeveloperSetting, 'feedbackAvailable' | 'avoidPoolFormValidate' | 'replicateOneSizeAvailable' | 'batchMaxValue'>
  )> }
);

export type GetGlobalTableViewConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGlobalTableViewConfigQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'globalTableViewConfig'>
);


export const GetCollectionDocument = gql`
    query GetCollection {
  collection @client {
    value
  }
}
    `;

/**
 * __useGetCollectionQuery__
 *
 * To run a query within a React component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, options);
      }
export function useGetCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, options);
        }
export type GetCollectionQueryHookResult = ReturnType<typeof useGetCollectionQuery>;
export type GetCollectionLazyQueryHookResult = ReturnType<typeof useGetCollectionLazyQuery>;
export type GetCollectionQueryResult = Apollo.QueryResult<GetCollectionQuery, GetCollectionQueryVariables>;
export const GetDeveloperSettingDocument = gql`
    query GetDeveloperSetting {
  developerSetting @client {
    feedbackAvailable
    avoidPoolFormValidate
    replicateOneSizeAvailable
    batchMaxValue
  }
}
    `;

/**
 * __useGetDeveloperSettingQuery__
 *
 * To run a query within a React component, call `useGetDeveloperSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeveloperSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeveloperSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeveloperSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetDeveloperSettingQuery, GetDeveloperSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeveloperSettingQuery, GetDeveloperSettingQueryVariables>(GetDeveloperSettingDocument, options);
      }
export function useGetDeveloperSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeveloperSettingQuery, GetDeveloperSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeveloperSettingQuery, GetDeveloperSettingQueryVariables>(GetDeveloperSettingDocument, options);
        }
export type GetDeveloperSettingQueryHookResult = ReturnType<typeof useGetDeveloperSettingQuery>;
export type GetDeveloperSettingLazyQueryHookResult = ReturnType<typeof useGetDeveloperSettingLazyQuery>;
export type GetDeveloperSettingQueryResult = Apollo.QueryResult<GetDeveloperSettingQuery, GetDeveloperSettingQueryVariables>;
export const GetGlobalTableViewConfigDocument = gql`
    query GetGlobalTableViewConfig {
  globalTableViewConfig @client
}
    `;

/**
 * __useGetGlobalTableViewConfigQuery__
 *
 * To run a query within a React component, call `useGetGlobalTableViewConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalTableViewConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalTableViewConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalTableViewConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalTableViewConfigQuery, GetGlobalTableViewConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalTableViewConfigQuery, GetGlobalTableViewConfigQueryVariables>(GetGlobalTableViewConfigDocument, options);
      }
export function useGetGlobalTableViewConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalTableViewConfigQuery, GetGlobalTableViewConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalTableViewConfigQuery, GetGlobalTableViewConfigQueryVariables>(GetGlobalTableViewConfigDocument, options);
        }
export type GetGlobalTableViewConfigQueryHookResult = ReturnType<typeof useGetGlobalTableViewConfigQuery>;
export type GetGlobalTableViewConfigLazyQueryHookResult = ReturnType<typeof useGetGlobalTableViewConfigLazyQuery>;
export type GetGlobalTableViewConfigQueryResult = Apollo.QueryResult<GetGlobalTableViewConfigQuery, GetGlobalTableViewConfigQueryVariables>;