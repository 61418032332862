/**
 * 颜色计算规则说明文档：https://xskydata.feishu.cn/docx/PHs3dYu2JobqOpxjc9pco1jHnXe
 */
const Color = require('color-js');

const MAX_SATURATION = 100;
const MIN_SATURATION = 9;
const MAX_VALUE = 100;
const MIN_VALUE = 30;

/**
 * 目标色色相获取
 * @param {number} baseColorH 基础色色相
 * @param {number} i 目标色色阶
 * @returns {number} 目标色色相
 */
function getHue(baseColorH: number, i: number) {
  // 冷色
  if (60 < baseColorH && baseColorH <= 240) return baseColorH + 2 * (i - 6);
  // 暖色
  return baseColorH - 2 * (i - 6);
}

/**
 * 颜色计算（1~5色阶适用）
 * @param {number} baseColorH 基础色色相
 * @param {string} relationColor 目标色上一个色阶的颜色值
 * @param {number} i 目标色色阶
 * @returns {string} 目标色颜色值
 */
function calculateLightColor(baseColorH: number, relationColor: any, i: number) {
  const hue = getHue(baseColorH, i);
  const { saturation, value } = relationColor.toHSV();

  const rs = Math.round(saturation * 100);
  const s = Math.round(rs - (rs - MIN_SATURATION) / i) / 100;

  const rv = Math.round(value * 100);
  const v = Math.round(rv + (MAX_VALUE - rv) / i) / 100;

  return Color({ hue, saturation: s, value: v }).toCSS();
}

/**
 * 颜色计算（7~10色阶适用）
 * @param {string} baseColor 基础颜色色值
 * @param {number} i 色阶
 * @returns {string} 目标色颜色值
 */
function calculateDarkColor(baseColor: any, i: number) {
  const { saturation, hue, value } = baseColor.toHSV();

  const h = getHue(Math.round(hue), i);

  const baseS = Math.round(saturation * 100);
  const perS = (MAX_SATURATION - baseS) / 4;
  const s = Math.round(baseS + (i - 6) * perS) / 100;

  const baseV = Math.round(value * 100);
  const perV = (baseV - MIN_VALUE) / 4;
  const v = Math.round(baseV - (i - 6) * perV) / 100;

  return Color({ hue: h, saturation: s, value: v }).toCSS();
}

/**
 * 获取基础颜色对应的十个色阶颜色
 * @param {string} baseColor 基础颜色色值
 * @returns {string[]}
 */
export function getAssociatedColor(baseColor: string) {
  const color = baseColor.includes('#') ? baseColor : `#${baseColor}`;
  const normalColor = Color(color);
  const h = Math.round(normalColor.getHue());
  let colors = [];

  for (let i = 10; i > 0; i--) {
    let c;
    if (i >= 7) {
      c = calculateDarkColor(normalColor, i);
    } else if (i === 6) {
      c = color;
    } else {
      c = calculateLightColor(h, Color(colors[0]), i);
    }
    colors.unshift(c);
  }

  return colors;
}
