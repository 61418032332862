/**
 * 全局 Message 组件
 * 负责与notistack通信
 */
import { SnackbarKey, useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';

import Icon from '~/charon/Icon';
import Alert from '~/components/ui/Alert';
import IconButton from '~/components/ui/IconButton';
import message from '~/lib/message';
import { CLOSE_MESSAGE, MESSAGE } from '~/constants/ps-events';
import { MessageData } from '~/lib/message';
import ps from '~/lib/ps';
import { getIconRotateAnimation } from '~/lib/css';
import { ALERT_DURATION } from '~/constants';
import Typography from '~/components/blocks/Typography';
import themeCSSObj from '~/styles/theme.module.scss';

import { alertBoxStyle, alertDetailStyle, operationIconStyle, moreIconStyle } from './lib';
const AutoHideDuration = ALERT_DURATION;

export default function Message() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const token = ps.subscribe(MESSAGE, (info: string, data: MessageData) => {
      let currentKey: SnackbarKey;
      let forwardSetExpand: () => void;

      const NoticeExtraContent = () => {
        const [expand, setExpand] = useState(false);

        forwardSetExpand = useCallback(() => {
          setExpand(!expand);
        }, [expand]);

        return (
          <Box css={alertBoxStyle}>
            <Alert
              action={
                <Box css={operationIconStyle}>
                  {data.more && (
                    <Box css={moreIconStyle}>
                      <Icon
                        name="Down12"
                        css={getIconRotateAnimation(expand)}
                        onClick={() => forwardSetExpand && forwardSetExpand()}
                      />
                    </Box>
                  )}
                  <IconButton
                    icon="Close16"
                    viewSize={16}
                    css={{ marginLeft: '8px' }}
                    onClick={() => {
                      message.close(currentKey);
                    }}
                  />
                </Box>
              }
              loading={data.loading}
              severity={data.type}
              variant="outlined"
            >
              <Typography type="t2" size="s2">
                {data.message}
              </Typography>
            </Alert>
            <Collapse in={expand}>
              <Box css={alertDetailStyle(data.type)}>{data.more}</Box>
            </Collapse>
          </Box>
        );
      };

      enqueueSnackbar(data.message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        action: (key) => {
          currentKey = key;
        },
        content: (
          <Box>
            <NoticeExtraContent />
          </Box>
        ),
        autoHideDuration: AutoHideDuration,
        ...data.options,
      });
    });

    return () => {
      ps.unsubscribe(token);
    };
  });

  useEffect(() => {
    const token = ps.subscribe(CLOSE_MESSAGE, (info: string, key: SnackbarKey) => {
      closeSnackbar(key);
    });

    return () => {
      ps.unsubscribe(token);
    };
  });
  return null;
}
