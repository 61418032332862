import { nanoid } from 'nanoid';

import { initializeApollo } from '~/graphql/app/client';
import { GetVersionDocument } from '~/__generated__/graphql/app/operations/version.graphql';

interface HealthCheck {
  start: () => void;
  stop: () => void;
  listeners: Set<{ id: string; func: (healthy: boolean) => void }>;
  getSnapshot: () => boolean;
  subscribe: (func: (isHealthy: boolean) => void) => () => void;
}

let timer: number;
let healthy: boolean = true;

function checkHealth() {
  const apolloClient = initializeApollo();

  return apolloClient
    .query({
      query: GetVersionDocument,
      fetchPolicy: 'network-only',
    })
    .then((data) => {
      if (!data.error) {
        if (!healthy) {
          healthy = true;
          healthCheck.listeners.forEach((listener) => listener.func(healthy));
        }
      } else {
        if (healthy) {
          healthy = false;
          healthCheck.listeners.forEach((listener) => listener.func(healthy));
        }
      }
    })
    .catch(() => {
      healthy = false;
      healthCheck.listeners.forEach((listener) => listener.func(healthy));
    });
}

const healthCheck: HealthCheck = {
  start: () => {
    const isServerSide = typeof window === 'undefined';
    if (isServerSide) {
      return;
    }

    if (timer === undefined) {
      timer = window.setInterval(() => {
        checkHealth();
      }, 1000 * 10);
    }
  },
  stop: () => {
    clearInterval(timer);
  },
  listeners: new Set(),
  getSnapshot: () => healthy,
  subscribe: (callback: (healthy: boolean) => void) => {
    const id = nanoid();
    const listener = {
      id,
      func: (healthy: boolean) => {
        callback(healthy);
      },
    };
    healthCheck.listeners.add(listener);

    checkHealth();

    return () => healthCheck.listeners.delete(listener);
  },
};

Object.freeze(healthCheck);

export default healthCheck;
