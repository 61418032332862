import { useEffect, useState } from 'react';

import Drawer from '~/components/ui/Drawer';
import { CLOSE_DRAWER, GLOBAL_DRAWER } from '~/constants/ps-events';
import { GlobalDrawerData } from '~/lib/drawer';
import ps from '~/lib/ps';

export default function GlobalDrawer() {
  const [open, setOpen] = useState(false);

  const [drawerData, setDrawerData] = useState<GlobalDrawerData>();

  useEffect(() => {
    const token = ps.subscribe(GLOBAL_DRAWER, (message: string, data: GlobalDrawerData) => {
      setOpen(data?.open);
      setDrawerData(data);
    });

    const closeToken = ps.subscribe(CLOSE_DRAWER, () => {
      setDrawerData(undefined);
      setOpen(false);
    });

    return () => {
      ps.unsubscribe(token);
      ps.unsubscribe(closeToken);
    };
  }, []);

  return (
    <Drawer
      width={drawerData?.width}
      title={drawerData?.title}
      anchor={drawerData?.anchor ?? 'right'}
      bottomHeight={drawerData?.bottomHeight}
      open={open}
      onClose={() => {
        setDrawerData(undefined);
        setOpen(false);
      }}
    >
      {drawerData?.content}
    </Drawer>
  );
}
