/**
 * 全局快捷键
 */
import { useEffect, useRef } from 'react';
import lodash from 'lodash';

import dialog from '~/lib/dialog';
import ps from '~/lib/ps';
import { POP_DIALOG } from '~/constants/ps-events';

export default function GlobalShortcuts() {
  /**
   * 打开开发者设置
   * `shift + alt + X` || `shift + ⌘ + X`
   *
   **/
  const openDeveloperSettingRef = useRef<boolean>(false); // false: 关闭状态；true：打开状态

  const openDeveloperSettings = (ev: KeyboardEvent) => {
    const keyCode = ev.code;
    const OpenDeveloperSettingWidget =
      (ev.metaKey && ev.shiftKey && keyCode === 'KeyX') ||
      (ev.altKey && ev.shiftKey && keyCode === 'KeyX');

    if (OpenDeveloperSettingWidget) {
      if (!openDeveloperSettingRef.current) {
        openDeveloperSettingRef.current = true;
        dialog.widget({
          fullScreen: true,
          disableEsc: false,
          widget: 'DeveloperSettingWidget',
        });
      } else {
        dialog.pop();
      }
    }
  };

  const KeyDownFn = lodash.debounce(openDeveloperSettings, 500);

  useEffect(() => {
    window.addEventListener('keydown', KeyDownFn);
    const token = ps.subscribe(POP_DIALOG, () => {
      openDeveloperSettingRef.current = false;
    });
    return () => {
      window.removeEventListener('keydown', KeyDownFn);
      openDeveloperSettingRef.current = false;
      ps.unsubscribe(token);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
