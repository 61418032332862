import * as yup from 'yup';
import intl from 'react-intl-universal';

import { SelectOption } from '../ui/CSelect/type';

export const FIELD_WIDTH = '452px';
export const FIELD_HEIGHT = '34px';

export const OSD_NUM_PER_HOST_MIN = 1;
export const OSD_NUM_PER_HOST_MAX = 50;
export const CACHE_OSD_NUM_PER_HOST_MIN = 1;
export const CACHE_OSD_NUM_PER_HOST_MAX = 10;
export const READ_CACHE_SIZE_MIN = 256;
export const DATA_CHUNK_NUM_MIN = 1;
export const SIZE_PER_OSD_MIN = 480 * 1000 * 1000 * 1000; // 10 进制 480 GB
export const SIZE_PER_OSD_MAX = 100 * 1000 * 1000 * 1000 * 1000; // 10 进制 100TB
export const BS_VOLUME_NUM_MAX = 100; // 批量创建块存储卷最大个数
export const BS_VOLUME_NUM_MIN = 1; // 批量创建块存储卷最小个数
export const BS_VOLUME_SIZE_MB_MIN = 1024; // 块存储单卷最小容量 1 GiB（单位 MiB）
export const BS_VOLUME_SIZE_MB_MAX = 512 * 1024 * 1024; // 块存储单卷最大容量 512 TiB（单位 MiB）
export const BS_VOLUME_TIB = 1 * 1024 * 1024; //  1 TiB（单位 MiB）

export const GB_100 = 1024 * 100;

// validate regx
export const NO_REQUIRED_GENERAL_NAME_REGX = /^[\u4e00-\u9fa5A-Za-z0-9\._\-]{0,128}$/;
export const NO_REQUIRED_ADDRESS_REGX = /^[\u4e00-\u9fa5A-Za-z0-9\._\-\s]{0,128}$/;
export const GENERAL_NAME_REGX = /^[\u4e00-\u9fa5A-Za-z0-9\._\-]{1,128}$/;
export const GENERAL_NAME_SUPPORT_SPACE_REGX = /^[\u4e00-\u9fa5A-Za-z0-9\._\- ]{1,128}$/;
export const BATCH_NAME_REGX = /^[\u4e00-\u9fa5A-Za-z0-9\._\-]{1,123}$/;
export const IP_REGX =
  /^(((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3})?$/;
export const CONSOLE_PASSWORD_REGX = /^[a-zA-Z0-9]*$/;
export const LOGIN_PASSWORD_REGX =
  /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$)(?![a-z0-9]+$)(?![a-z`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$)(?![0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$)[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{1,}$/;
export const UPDATEPASSWORD_REGX =
  /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{1,}$/;
export const X_SDDC_TOKEN_REGX = /X_SDDC_TOKEN=([^;]*)/;
export const ALLOW_INVALID_BROWSER = /ALLOW_INVALID_BROWSER=([^;]*)/;
export const EXT_SDS_CLUSTER_TOKEN = /^[a-z0-9A-Z]+$/;
export const EXT_SDS_CLUSTER_ENDPOINT =
  /^(http(s)?:\/\/)((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])(:\d{0,5})?$)?$/;
export const SERVER_HOST_IPV4_REGX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const SERVER_HOST_IPV6_REGX =
  /^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,6}:([\da-fA-F]{1,4}:){0,5}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,5}:([\da-fA-F]{1,4}:){0,6}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,4}:([\da-fA-F]{1,4}:){0,7}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,3}:([\da-fA-F]{1,4}:){0,8}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,2}:([\da-fA-F]{1,4}:){0,9}[\da-fA-F]{1,4}|[\da-fA-F]:([\da-fA-F]{1,4}:){0,10}[\da-fA-F]{1,4}|:([\da-fA-F]{1,4}:){0,11}[\da-fA-F]{1,4}$/;
export const SERVER_PORT_REGX = /^[\d]+$/;
export const SERVER_HOST_NAME_REGX = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;

export const UNIT_LIST_iB: SelectOption[] = [
  {
    label: 'GiB',
    value: 'GiB',
  },
  {
    label: 'TiB',
    value: 'TiB',
  },
];

export const UNIT_LIST: SelectOption[] = [
  {
    label: 'GB',
    value: 'GB',
  },
  {
    label: 'TB',
    value: 'TB',
  },
];

export const COOKIE_TOKEN = 'X_SDDC_TOKEN';
export const TOKEN = 'x-sddc-token';

export function getGeneralNameSchema(newEmptyMsg?: string) {
  const emptyMsg = newEmptyMsg || intl.get('请输入名称');
  const errorMsg = intl.get('仅支持中文、大小写字母、数字、特殊字符（._-）');
  const overflowMsg = intl.get('仅支持 1～128 个字符');
  const re = GENERAL_NAME_REGX;
  return yup.string().trim(errorMsg).required(emptyMsg).max(128, overflowMsg).matches(re, errorMsg);
}

export function getGeneralNameSupportSpaceSchema(newEmptyMsg?: string) {
  const emptyMsg = newEmptyMsg || intl.get('请输入名称');
  const errorMsg = intl.get('仅支持中文、大小写字母、数字、特殊字符（._-）');
  const overflowMsg = intl.get('仅支持 1～128 个字符');
  const re = GENERAL_NAME_SUPPORT_SPACE_REGX;
  return yup.string().trim(errorMsg).required(emptyMsg).max(128, overflowMsg).matches(re, errorMsg);
}

export function getBatchNameSchema(newEmptyMsg?: string) {
  const emptyMsg = newEmptyMsg || intl.get('请输入名称');
  const errorMsg = intl.get('仅支持中文、大小写字母、数字、特殊字符（._-）');
  const overflowMsg = intl.get('仅支持 1～123 个字符');
  const re = BATCH_NAME_REGX;
  return yup.string().trim(errorMsg).required(emptyMsg).max(123, overflowMsg).matches(re, errorMsg);
}

export function getBatchCreateVmNameSchema(newEmptyMsg?: string) {
  const emptyMsg = newEmptyMsg || intl.get('请输入名称');
  const errorMsg = intl.get('仅支持中文、大小写字母、数字、特殊字符（._-）');
  const overflowMsg = intl.get('仅支持 1～122 个字符');
  const re = /^[\u4e00-\u9fa5A-Za-z0-9\._\-]{1,122}$/;
  return yup.string().trim(errorMsg).required(emptyMsg).max(122, overflowMsg).matches(re, errorMsg);
}

export function getGeneralNamePlaceholder() {
  return intl.get('支持 1～128 个字符');
}

export function getNoRequiredGeneralNameSchema() {
  const errorMsg = intl.get('仅支持中文、大小写字母、数字、特殊字符（._-）');
  const overflowMsg = intl.get('仅支持 1～128 个字符');
  const re = NO_REQUIRED_GENERAL_NAME_REGX;
  return yup.string().trim(errorMsg).max(128, overflowMsg).matches(re, errorMsg);
}

export function getNoRequiredAddressSchema() {
  const errorMsg = intl.get(
    '支持 1～128 个字符，支持中文、大小写字母、数字、特殊字符（._-）、空格'
  );
  const overflowMsg = intl.get('仅支持 1～128 个字符');
  const re = NO_REQUIRED_ADDRESS_REGX;
  return yup.string().trim(errorMsg).max(128, overflowMsg).matches(re, errorMsg);
}

export function getGeneralDescSchema() {
  const errorMsg = intl.get('仅支持 1～128 个字符');
  // desc can be empty
  return yup.string().min(0, errorMsg).max(128, errorMsg);
}

export function getGeneralConsolePwdSchema() {
  const overflowMsg = intl.get('仅支持 5～8 个字符');
  const errorMsg = intl.get('仅支持大小写字母、数字');
  return yup
    .string()
    .test({
      test: (value) => (value ? value.length <= 8 && value.length >= 5 : true),
      message: overflowMsg,
    })
    .matches(CONSOLE_PASSWORD_REGX, { message: errorMsg, excludeEmptyString: true });
}

export function getEmailPasswordSchema() {
  const errorMsg = intl.get('仅支持 1～50 个字符');
  return yup.string().min(0, errorMsg).max(50, errorMsg);
}

export function getGeneralDescPlaceholder() {
  return intl.get('支持 1～128 个字符');
}

export function getGeneralMonitorViewNameSchema() {
  const emptyMsg = intl.get('请输入名称');
  const errorMsg = intl.get('仅支持 1～16 个字符');
  return yup.string().trim(emptyMsg).required(emptyMsg).max(16, errorMsg);
}
