/*
 * Typography
 *
 * Owner: Yao Bao
 */

import type { ForwardedRef } from 'react';
import React from 'react';
import { Typography as MuiTypography } from '@mui/material';
import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/react';
import clsx from 'clsx';

import type { TypographyPropsType } from './type';
import {
  getStyle,
  getTypeStyle,
  getSizeStyle,
  getInlineStyle,
  getDefaultFontWeight,
  getEllipsisStyle,
} from './lib';

function _Typography(props: TypographyPropsType, ref: ForwardedRef<HTMLDivElement>) {
  const {
    children,
    component = 'div',
    type = 't2',
    disabled = false,
    size = 's2',
    isInline = false,
    color = '',
    fontWeight = getDefaultFontWeight(),
    isEllipsis = false,
    className = '',
    ...rest
  } = props;

  const extraCSS: CSSObject = {};
  if (color) extraCSS.color = color;
  if (fontWeight) extraCSS.fontWeight = fontWeight;

  return (
    <MuiTypography
      component={component}
      ref={ref}
      css={[
        getStyle(),
        getTypeStyle(type, disabled),
        getSizeStyle(size),
        getInlineStyle(isInline),
        isEllipsis ? getEllipsisStyle() : css({}),
        css(extraCSS),
      ]}
      className={clsx('CharonTypography', className)}
      {...rest}
    >
      {children}
    </MuiTypography>
  );
}

const Typography = React.forwardRef(_Typography);

export type { TypographyPropsType };
export default Typography;
