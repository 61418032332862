import { IncomingMessage, ServerResponse } from 'http';
import { useMemo } from 'react';
import Router from 'next/router';
import { ApolloClient, ApolloLink, from } from '@apollo/client/core';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import getConfig from 'next/config';
import compact from 'lodash/compact';
import intl from 'react-intl-universal';
import lodash from 'lodash';

import { ROUTE_LOGIN, ROUTE_VNC } from '~/lib/route';
import { clientStoreInMemoryCacheConfig } from '~/lib/clientStore';
import { insertInterceptContent } from '~/lib/e2e';
import message from '~/lib/message';
import {
  VIRTUAL_MACHINE_VERIFY_GRAPHOCS_PASSWD_BY_ID,
  ALERT_DURATION,
  VIRTUAL_MACHINE_VERIFY_GRAPHOCS_PASSWD_BY_UUID,
} from '~/constants';
import drawer from '~/lib/drawer';

const { publicRuntimeConfig } = getConfig();

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export type ResolverContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
};

const showAuthFailedAlert = lodash.debounce(
  () => {
    message.open('warning', intl.get('暂无查看/操作权限，请重新登录'));
  },
  ALERT_DURATION,
  {
    leading: true,
    trailing: false,
  }
);

const showServerErrorAlert = lodash.debounce(
  () => {
    message.open('error', intl.get('服务器故障'));
  },
  ALERT_DURATION,
  {
    leading: true,
    trailing: false,
  }
);

const redirectLink = new ApolloLink((operation, forward) => {
  let pathname = Router.router?.asPath;

  return forward(operation).map((response) => {
    if (!pathname) {
      throw new Error('pathname is undefined');
    }

    const { errors } = response;
    if (!pathname.startsWith(ROUTE_LOGIN)) {
      if (
        errors &&
        errors[0]?.extensions?.statusCode === 401 &&
        errors[0]?.extensions?.path !== VIRTUAL_MACHINE_VERIFY_GRAPHOCS_PASSWD_BY_ID &&
        errors[0]?.extensions?.path !== VIRTUAL_MACHINE_VERIFY_GRAPHOCS_PASSWD_BY_UUID // 虚拟机远程登录密码认证接口 401的时候不应重定向到登录页
      ) {
        showAuthFailedAlert();
        const redirectTo = pathname.match('redirect_url')
          ? pathname
          : `${ROUTE_LOGIN}?redirect_url=${pathname}`;
        Router.push(redirectTo);
        drawer.close(); // token 失效时，关闭全局抽屉
      }
    }

    // 当后端报错 500 时，弹出错误信息
    if (errors?.[0]?.extensions?.statusCode === 500) {
      showServerErrorAlert();
    }

    return response;
  });
});

const interceptLink = new ApolloLink(function (operation, forward) {
  return forward(operation).map(function (response) {
    insertInterceptContent(operation, response);
    return response;
  });
});

const createIsomorphLink = (context: ResolverContext = {}) => {
  if (typeof window === 'undefined') {
    const { SchemaLink } = require('@apollo/client/link/schema');
    const { schema } = require('./schema');
    return new SchemaLink({ schema, context });
  } else {
    const { createUploadLink } = require('apollo-upload-client');
    return new createUploadLink({
      uri: (operation: any) =>
        `/api/graphql?operation=${encodeURIComponent(operation.operationName)}`,
      credentials: 'same-origin',
    });
  }
};

// URL携带access_token访问，跳过了登录，需要手动设置header用来接口的用户认证
const authMiddleware = new ApolloLink((operation, forward) => {
  if (Router.query.access_token && Router.route === ROUTE_VNC) {
    operation.setContext({
      headers: {
        'x-sddc-token': Router.query.access_token ? Router.query?.access_token : '',
      },
    });
  }
  return forward(operation);
});

function createApolloClient(context?: ResolverContext) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from(
      compact([
        authMiddleware,
        redirectLink,
        publicRuntimeConfig.INTERCEPT_ENABLE && interceptLink,
        createIsomorphLink(context),
      ])
    ),
    cache: new InMemoryCache(clientStoreInMemoryCacheConfig),
  });
}

export function initializeApollo(
  initialState: any = null,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
  context?: ResolverContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState?: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
