/*
 * Route
 *
 * ROUTE_XXXX might change (externally visible).
 * ROUTE_XXXX_VALUE is fixed (internally visible).
 */

import intl from 'react-intl-universal';
import type { NextRouter } from 'next/router';

const ROUTE_PAGE = '/page';
const ROUTE_SDS = '/sds';
const ROUTE_VNC = '/vnc';

const ROUTE_LOGIN = '/login';
const ROUTE_500 = '/500';
const ROUTE_404 = '/404';

enum AppRouteKey {
  ROUTE_ROOT = '/',
  ROUTE_DASHBOARD = 'dashboard',
  ROUTE_SETTINGS = 'settings',

  ROUTE_V_MACHINES = 'virtual-machines',
  ROUTE_V_MACHINES_SNAPS = 'vm-snaps',
  ROUTE_IMAGES = 'images',
  ROUTE_SNAPS_POLICY = 'snap-policy',
  ROUTE_V_MACHINES_SCHEDULING_POLICY = 'vm-scheduling-policy',

  ROUTE_V_SWITCHES = 'virtual-switches',
  ROUTE_V_BRIDGED_NETWORKS = 'bridged-networks',
  ROUTE_SECURITY_POLICIES = 'security-policies',

  ROUTE_TOPOLOGY = 'topology',
  ROUTE_NODES = 'nodes',
  ROUTE_SERVICE_GROUPS = 'service-groups',

  ROUTE_STORAGE_POOL = 'storage-pool',
  ROUTE_OSDS = 'osds',
  ROUTE_BS_POLICIES = 'bs-policies',
  ROUTE_BS_VOLUMES = 'bs-volumes',
  ROUTE_BS_SNAPS = 'bs-snaps',

  ROUTE_OBJECT_USER = 'object-user',
  ROUTE_STORAGE_POLICY = 'storage-policy',
  ROUTE_STORAGE_BUCKET = 'storage-bucket',
  ROUTE_OBJECT_ROUTING = 'object-routing',
  ROUTE_NFS_GATEWAY = 'nfs-gateway',
  ROUTE_MULTI_SITE = 'multi-site',
  ROUTE_OBJECT_SEARCH = 'object-search',

  ROUTE_FILE_OVERVIEW = 'dashboard/clusters/1/dfs-rootfses',
  ROUTE_FILE_SHARE = 'dashboard/clusters/1/dfs-nfs-shares',
  ROUTE_FILE_BIGDATA = 'dashboard/clusters/1/dfs-hdfses',
  ROUTE_FILE_DIR = 'dashboard/clusters/1/dfs-files',

  ROUTE_ALERT_INFO = 'alerts',
  ROUTE_ALERT_RULE = 'alert-rules',
  ROUTE_AUDIT_LOG = 'audit-logs',
  ROUTE_EVENTS = 'events',
  ROUTE_ANALYSIS = 'monitor-analysis',

  ROUTE_LABELS = 'labels',

  ROUTE_HOME = ROUTE_DASHBOARD,
}

enum AppRouteValue {
  ROUTE_LOGIN_VALUE = '_login',
  ROUTE_ROOT_VALUE = '_/',
  ROUTE_DASHBOARD_VALUE = '_dashboard',
  ROUTE_SETTINGS_VALUE = '_settings',
  ROUTE_V_MACHINES_VALUE = '_virtual_machines',
  ROUTE_V_MACHINES_SNAPS_VALUE = '_vm-snaps',
  ROUTE_SNAPS_POLICY_VALUE = '_snap-policy',
  ROUTE_IMAGES_VALUE = '_images',
  ROUTE_V_MACHINES_SCHEDULING_POLICY_VALUE = '_vm_scheduling_policy',
  ROUTE_V_SWITCHES_VALUE = '_virtual_switches',
  ROUTE_V_BRIDGED_NETWORKS_VALUE = '_bridged_networks',
  ROUTE_SECURITY_POLICIES_VALUE = '_security_policies',
  ROUTE_TOPOLOGY_VALUE = '_topology',
  ROUTE_NODES_VALUE = '_nodes',
  ROUTE_SERVICE_GROUPS_VALUE = '_service_groups',
  ROUTE_STORAGE_POOL_VALUE = '_storage_pool',
  ROUTE_OSDS_VALUE = '_osds',
  ROUTE_BS_POLICIES_VALUE = '_bs_policies',
  ROUTE_BS_VOLUMES_VALUE = '_bs_volumes',
  ROUTE_BS_SNAPS_VALUE = '_bs-snaps',
  ROUTE_OBJECT_USER_VALUE = '_object_user',
  ROUTE_STORAGE_POLICY_VALUE = '_storage_policy',
  ROUTE_STORAGE_BUCKET_VALUE = '_storage_bucket',
  ROUTE_OBJECT_ROUTING_VALUE = '_object_routing',
  ROUTE_NFS_GATEWAY_VALUE = '_nfs_gateway',
  ROUTE_MULTI_SITE_VALUE = '_multi_site',
  ROUTE_OBJECT_SEARCH_VALUE = '_object_search',
  ROUTE_FILE_OVERVIEW = '_file_overview',
  ROUTE_FILE_SHARE_VALUE = '_file_share',
  ROUTE_FILE_BIGDATA_VALUE = '_file_bigdata',
  ROUTE_FILE_DIR_VALUE = '_file_dir',
  ROUTE_ALERT_INFO_VALUE = '_alerts',
  ROUTE_ALERT_RULE_VALUE = '_alert-rule',
  ROUTE_AUDIT_LOG_VALUE = '_audit-logs',
  ROUTE_EVENTS_VALUE = '_events',
  ROUTE_ANALYSIS_VALUE = '_analysis',
  ROUTE_LABELS_VALUE = '_labels',
  ROUTE_HOME_VALUE = '_home',
}

const appRoute: Record<AppRouteKey, AppRouteItemType> = {
  [AppRouteKey.ROUTE_ROOT]: {
    value: AppRouteValue.ROUTE_ROOT_VALUE,
    getName: function () {
      return intl.get('根');
    },
    address: AppRouteKey.ROUTE_ROOT,
  },

  [AppRouteKey.ROUTE_HOME]: {
    value: AppRouteValue.ROUTE_HOME_VALUE,
    getName: function () {
      return intl.get('主页');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_HOME}`,
  },

  [AppRouteKey.ROUTE_DASHBOARD]: {
    value: AppRouteValue.ROUTE_DASHBOARD_VALUE,
    getName: function () {
      return intl.get('概览');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_DASHBOARD}`,
  },

  [AppRouteKey.ROUTE_SETTINGS]: {
    value: AppRouteValue.ROUTE_SETTINGS_VALUE,
    getName: function () {
      return intl.get('访问令牌');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_SETTINGS}`,
  },

  [AppRouteKey.ROUTE_V_MACHINES]: {
    value: AppRouteValue.ROUTE_V_MACHINES_VALUE,
    getName: function () {
      return intl.get('虚拟机');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_V_MACHINES}`,
  },
  [AppRouteKey.ROUTE_V_MACHINES_SNAPS]: {
    value: AppRouteValue.ROUTE_V_MACHINES_SNAPS_VALUE,
    getName: function () {
      return intl.get('虚拟机快照');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_V_MACHINES_SNAPS}`,
  },
  [AppRouteKey.ROUTE_SNAPS_POLICY]: {
    value: AppRouteValue.ROUTE_SNAPS_POLICY_VALUE,
    getName: function () {
      return intl.get('定时快照策略');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_SNAPS_POLICY}`,
  },
  [AppRouteKey.ROUTE_IMAGES]: {
    value: AppRouteValue.ROUTE_IMAGES_VALUE,
    getName: function () {
      return intl.get('镜像');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_IMAGES}`,
  },
  [AppRouteKey.ROUTE_V_MACHINES_SCHEDULING_POLICY]: {
    value: AppRouteValue.ROUTE_V_MACHINES_SCHEDULING_POLICY_VALUE,
    getName: function () {
      return intl.get('调度策略');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_V_MACHINES_SCHEDULING_POLICY}`,
  },

  [AppRouteKey.ROUTE_V_SWITCHES]: {
    value: AppRouteValue.ROUTE_V_SWITCHES_VALUE,
    getName: function () {
      return intl.get('虚拟交换机');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_V_SWITCHES}`,
  },
  [AppRouteKey.ROUTE_V_BRIDGED_NETWORKS]: {
    value: AppRouteValue.ROUTE_V_BRIDGED_NETWORKS_VALUE,
    getName: function () {
      return intl.get('桥接网络');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_V_BRIDGED_NETWORKS}`,
  },
  [AppRouteKey.ROUTE_SECURITY_POLICIES]: {
    value: AppRouteValue.ROUTE_SECURITY_POLICIES_VALUE,
    getName: function () {
      return intl.get('安全策略');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_SECURITY_POLICIES}`,
  },

  [AppRouteKey.ROUTE_TOPOLOGY]: {
    value: AppRouteValue.ROUTE_TOPOLOGY_VALUE,
    getName: function () {
      return intl.get('拓扑');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_TOPOLOGY}`,
  },
  [AppRouteKey.ROUTE_NODES]: {
    value: AppRouteValue.ROUTE_NODES_VALUE,
    getName: function () {
      return intl.get('节点');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_NODES}`,
  },
  [AppRouteKey.ROUTE_SERVICE_GROUPS]: {
    value: AppRouteValue.ROUTE_SERVICE_GROUPS_VALUE,
    getName: function () {
      return intl.get('服务组');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_SERVICE_GROUPS}`,
  },

  [AppRouteKey.ROUTE_STORAGE_POOL]: {
    value: AppRouteValue.ROUTE_STORAGE_POOL_VALUE,
    getName: function () {
      return intl.get('存储池');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_STORAGE_POOL}`,
  },
  [AppRouteKey.ROUTE_OSDS]: {
    value: AppRouteValue.ROUTE_OSDS_VALUE,
    getName: function () {
      return intl.get('硬盘');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_OSDS}`,
  },
  [AppRouteKey.ROUTE_BS_POLICIES]: {
    value: AppRouteValue.ROUTE_BS_POLICIES_VALUE,
    getName: function () {
      return intl.get('块存储策略');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_BS_POLICIES}`,
  },
  [AppRouteKey.ROUTE_BS_VOLUMES]: {
    value: AppRouteValue.ROUTE_BS_VOLUMES_VALUE,
    getName: function () {
      return intl.get('块存储卷');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_BS_VOLUMES}`,
  },
  [AppRouteKey.ROUTE_BS_SNAPS]: {
    value: AppRouteValue.ROUTE_BS_SNAPS_VALUE,
    getName: function () {
      return intl.get('卷快照');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_BS_SNAPS}`,
  },

  [AppRouteKey.ROUTE_OBJECT_USER]: {
    value: AppRouteValue.ROUTE_OBJECT_USER_VALUE,
    getName: function () {
      return intl.get('对象用户');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_OBJECT_USER}`,
  },
  [AppRouteKey.ROUTE_STORAGE_POLICY]: {
    value: AppRouteValue.ROUTE_STORAGE_POLICY_VALUE,
    getName: function () {
      return intl.get('存储策略');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_STORAGE_POLICY}`,
  },
  [AppRouteKey.ROUTE_STORAGE_BUCKET]: {
    value: AppRouteValue.ROUTE_STORAGE_BUCKET_VALUE,
    getName: function () {
      return intl.get('存储桶');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_STORAGE_BUCKET}`,
  },
  [AppRouteKey.ROUTE_OBJECT_ROUTING]: {
    value: AppRouteValue.ROUTE_OBJECT_ROUTING_VALUE,
    getName: function () {
      return intl.get('对象路由');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_OBJECT_ROUTING}`,
  },
  [AppRouteKey.ROUTE_NFS_GATEWAY]: {
    value: AppRouteValue.ROUTE_NFS_GATEWAY_VALUE,
    getName: function () {
      return intl.get('NFS 网关');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_NFS_GATEWAY}`,
  },
  [AppRouteKey.ROUTE_MULTI_SITE]: {
    value: AppRouteValue.ROUTE_MULTI_SITE_VALUE,
    getName: function () {
      return intl.get('多站点');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_MULTI_SITE}`,
  },
  [AppRouteKey.ROUTE_OBJECT_SEARCH]: {
    value: AppRouteValue.ROUTE_OBJECT_SEARCH_VALUE,
    getName: function () {
      return intl.get('对象查询');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_OBJECT_SEARCH}`,
  },

  [AppRouteKey.ROUTE_FILE_OVERVIEW]: {
    value: AppRouteValue.ROUTE_FILE_OVERVIEW,
    getName: function () {
      return intl.get('文件总览');
    },
    address: `${ROUTE_SDS}/${AppRouteKey.ROUTE_FILE_OVERVIEW}`,
  },
  [AppRouteKey.ROUTE_FILE_SHARE]: {
    value: AppRouteValue.ROUTE_FILE_SHARE_VALUE,
    getName: function () {
      return intl.get('文件共享');
    },
    address: `${ROUTE_SDS}/${AppRouteKey.ROUTE_FILE_SHARE}`,
  },
  [AppRouteKey.ROUTE_FILE_BIGDATA]: {
    value: AppRouteValue.ROUTE_FILE_BIGDATA_VALUE,
    getName: function () {
      return intl.get('大数据服务');
    },
    address: `${ROUTE_SDS}/${AppRouteKey.ROUTE_FILE_BIGDATA}`,
  },
  [AppRouteKey.ROUTE_FILE_DIR]: {
    value: AppRouteValue.ROUTE_FILE_DIR_VALUE,
    getName: function () {
      return intl.get('目录管理');
    },
    address: `${ROUTE_SDS}/${AppRouteKey.ROUTE_FILE_DIR}`,
  },

  [AppRouteKey.ROUTE_ANALYSIS]: {
    value: AppRouteValue.ROUTE_ANALYSIS_VALUE,
    getName: function () {
      return intl.get('监控分析');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_ANALYSIS}`,
  },
  [AppRouteKey.ROUTE_ALERT_INFO]: {
    value: AppRouteValue.ROUTE_ALERT_INFO_VALUE,
    getName: function () {
      return intl.get('告警信息');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_ALERT_INFO}`,
  },
  [AppRouteKey.ROUTE_ALERT_RULE]: {
    value: AppRouteValue.ROUTE_ALERT_RULE_VALUE,
    getName: function () {
      return intl.get('告警规则');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_ALERT_RULE}`,
  },
  [AppRouteKey.ROUTE_EVENTS]: {
    value: AppRouteValue.ROUTE_EVENTS_VALUE,
    getName: function () {
      return intl.get('事件');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_EVENTS}`,
  },
  [AppRouteKey.ROUTE_AUDIT_LOG]: {
    value: AppRouteValue.ROUTE_AUDIT_LOG_VALUE,
    getName: function () {
      return intl.get('审计日志');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_AUDIT_LOG}`,
  },
  [AppRouteKey.ROUTE_LABELS]: {
    value: AppRouteValue.ROUTE_LABELS_VALUE,
    getName: function () {
      return intl.get('标签');
    },
    address: `${ROUTE_PAGE}/${AppRouteKey.ROUTE_LABELS}`,
  },
};

function isRouteMatchTarget(router: NextRouter, target: string) {
  return router.asPath.startsWith(target);
}

type AppRouteItemType = { value: AppRouteValue; getName: () => string; address: string };

export default appRoute;
export {
  AppRouteKey,
  AppRouteValue,
  ROUTE_LOGIN,
  ROUTE_404,
  ROUTE_500,
  ROUTE_PAGE,
  ROUTE_VNC,
  isRouteMatchTarget,
};
