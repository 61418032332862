/*
 * Lib for Typography
 */

import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/react';

import themeStyle from '~/styles/theme.module.scss';
import {
  getPrimaryTextColor,
  getSuccessTextColor,
  getErrorTextColor,
  getCriticalTextColor,
  getInfoTextColor,
  getWarningTextColor,
  getOfflineTextColor,
  getPrimaryLinkTextColor,
  getSuccessLinkTextColor,
  getErrorLinkTextColor,
  getCriticalLinkTextColor,
  getInfoLinkTextColor,
  getWarningLinkTextColor,
} from '~/lib/color';
import { getEllipsisCSS } from '~/lib/css';

import type { TypographyPropsType } from './type';

function getStyle() {
  return css({
    fontWeight: getDefaultFontWeight(),
  });
}

function getTypeStyle(
  type: TypographyPropsType['type'],
  disabled: boolean,
  isClickable: boolean,
  underline: boolean
) {
  const base = {
    opacity: disabled ? 0.4 : 1,
    textDecoration: underline && isClickable ? 'underline' : '',
  };

  if (type === 't1') {
    return css([
      base,
      {
        color: themeStyle.appFontColor1,
        '&:hover': {
          color: !disabled && isClickable ? getPrimaryLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getPrimaryLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 't2') {
    return css([
      base,
      {
        color: themeStyle.appFontColor2,
        '&:hover': {
          color: !disabled && isClickable ? themeStyle.appFontColor2 : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? themeStyle.appFontColor1 : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 't3') {
    return css([
      base,
      {
        color: themeStyle.appFontColor3,
      },
    ]);
  }

  if (type === 'shade-t1') {
    return css([
      base,
      {
        color: themeStyle.appFontColor4,
      },
    ]);
  }

  if (type === 'shade-t2') {
    return css([
      base,
      {
        color: themeStyle.appFontColor5,
      },
    ]);
  }

  if (type === 'primary') {
    return css([
      base,
      {
        color:
          underline && isClickable
            ? themeStyle.grey9
            : isClickable
            ? getPrimaryLinkTextColor('normal')
            : getPrimaryTextColor('normal'),
        '&:hover': {
          color: !disabled && isClickable ? getPrimaryLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getPrimaryLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 'success') {
    return css([
      base,
      {
        color: isClickable ? getSuccessLinkTextColor('normal') : getSuccessTextColor('normal'),
        '&:hover': {
          color: !disabled && isClickable ? getSuccessLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getSuccessLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 'error') {
    return css([
      base,
      {
        color: isClickable ? getErrorLinkTextColor('normal') : getErrorTextColor('normal'),
        '&:hover': {
          color: !disabled && isClickable ? getErrorLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getErrorLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 'critical') {
    return css([
      base,
      {
        color: isClickable ? getCriticalLinkTextColor('normal') : getCriticalTextColor('normal'),
        '&:hover': {
          color: !disabled && isClickable ? getCriticalLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getCriticalLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 'info') {
    return css([
      base,
      {
        color: isClickable ? getInfoLinkTextColor('normal') : getInfoTextColor('normal'),
        '&:hover': {
          color: !disabled && isClickable ? getInfoLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getInfoLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 'warning') {
    return css([
      base,
      {
        color: isClickable ? getWarningLinkTextColor('normal') : getWarningTextColor('normal'),
        '&:hover': {
          color: !disabled && isClickable ? getWarningLinkTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getWarningLinkTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  if (type === 'offline') {
    return css([
      base,
      {
        color: getOfflineTextColor('normal'),
        opacity: disabled ? 0.4 : 1,
        textDecoration: underline && isClickable ? 'underline' : '',
        '&:hover': {
          color: !disabled && isClickable ? getOfflineTextColor('hover') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
        '&:active': {
          color: !disabled && isClickable ? getOfflineTextColor('click') : '',
          textDecoration: (!disabled || underline) && isClickable ? 'underline' : '',
        },
      },
    ]);
  }

  return css({});
}

function getSizeStyle(size: TypographyPropsType['size']) {
  if (size === 's1') {
    return css({
      fontSize: themeStyle.typographySubtitle2FontSize,
      lineHeight: themeStyle.typographySubtitle2LineHeight,
    });
  }

  if (size === 's2') {
    return css({
      fontSize: themeStyle.typographySubtitle1FontSize,
      lineHeight: themeStyle.typographySubtitle1LineHeight,
    });
  }

  if (size === 's3') {
    return css({
      fontSize: themeStyle.typographyH6FontSize,
      lineHeight: themeStyle.typographyH6LineHeight,
    });
  }

  if (size === 's4') {
    return css({
      fontSize: themeStyle.typographyH5FontSize,
      lineHeight: themeStyle.typographyH5LineHeight,
    });
  }

  if (size === 's5') {
    return css({
      fontSize: themeStyle.typographyH4FontSize,
      lineHeight: themeStyle.typographyH4LineHeight,
    });
  }

  if (size === 's6') {
    return css({
      fontSize: themeStyle.typographyH3FontSize,
      lineHeight: themeStyle.typographyH3LineHeight,
    });
  }

  return css({});
}

function getInlineStyle(isInline: boolean) {
  return css({
    display: isInline ? 'inline' : 'block',
  });
}

function getDefaultFontWeight() {
  return themeStyle.typographyFontWeightRegular as CSSObject['fontWeight'];
}

function getEllipsisStyle() {
  return css([getEllipsisCSS()]);
}

export {
  getStyle,
  getTypeStyle,
  getSizeStyle,
  getInlineStyle,
  getDefaultFontWeight,
  getEllipsisStyle,
};
