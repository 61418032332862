export const popperProps = (size: 'small' | 'medium') => {
  return {
    sx: {
      '.MuiTooltip-tooltip': {
        maxWidth: size === 'small' ? '280px' : '480px',
      },
    },
    modifiers: [
      {
        name: 'flip',
        options: {
          padding: 16,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: {
            top: 16,
            bottom: 16,
            left: 4,
            right: 4,
          },
        },
      },
    ],
  };
};
