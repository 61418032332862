/**
 * 全局任务中心,负责将组件的异步任务抽离到全局，防止组件销毁后对应的任务状态无法找到
 * 并在任务中心有任务未完成时阻止浏览器退出
 */
import { useCallback, useEffect } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import globalTasks, { Task } from '~/lib/global-tasks';

export default function TaskCenter() {
  const tasks = useSyncExternalStore<Task[]>(
    globalTasks.subscribe,
    () => globalTasks.getSnapshot(),
    () => []
  );

  const handleBeforeUnload = useCallback((event) => {
    event.preventDefault();

    return (event.returnValue = '');
  }, []);

  // 阻止页面关闭
  useEffect(
    function () {
      const noPendingTask = tasks.every((task) => !['pending', 'running'].includes(task.status));
      if (noPendingTask) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      } else {
        window.addEventListener('beforeunload', handleBeforeUnload);
      }

      return function () {
        window.addEventListener('beforeunload', handleBeforeUnload);
      };
    },
    [handleBeforeUnload, tasks]
  );

  return null;
}
