/**
 * Author: zhengjin
 */

import { FetchResult } from '@apollo/client';
import intl from 'react-intl-universal';
import { flatten } from 'lodash';

import { BatchResponse } from '~/lib/batch-api';

import message, { MessageType } from '../message';
import { formatContent } from './content';

// eslint-disable-next-line
export type PromiseAllSettledResult = PromiseSettledResult<Record<string, any>>;
export type Detail =
  | FetchResult<Record<string, any>>
  | BatchResponse<Record<string, any>>[]
  | PromiseAllSettledResult[]
  | Error;
export type DetailItemType =
  | FetchResult<Record<string, any>>
  | BatchResponse<Record<string, any>>
  | PromiseAllSettledResult
  | Error;

export type FormatResult =
  | {
      text: string;
      messageType?: MessageType;
      detail?: Detail;
    }
  | undefined;

function formatOne(
  result: FetchResult<Record<string, any>>,
  { action, resourceType, resourceName, silent }: Options
): FormatResult {
  let text = '';
  if (result.errors?.length) {
    text = intl.get('{action}：{resourceType}{resourceName}请求提交失败', {
      action,
      resourceType,
      resourceName: resourceName && `“${resourceName}”`,
    });
    return { text, detail: result, messageType: 'error' };
  }
  if (silent) return;
  text = intl.get('{action}：{resourceType}{resourceName}请求提交成功', {
    action,
    resourceType,
    resourceName: resourceName && `“${resourceName}”`,
  });
  return { text, detail: result };
}

function formatBatch(
  results: BatchResponse<Record<string, any>>[],
  { action, silent }: Options
): FormatResult {
  const total = results.length;
  const success = results.filter((result) => [200, 201, 202, 204].includes(result.statusCode)).length;
  const error = total - success;
  let text = '';
  let messageType: MessageType = 'info';
  if (success === total) {
    if (silent) return;
    text = intl.get('{action}：{success} 个请求提交成功', { action, success });
  } else if (error === total) {
    text = intl.get('{action}：{error} 个请求提交失败', { action, error });
    messageType = 'error';
  } else {
    text = intl.get('{action}：{success} 个提交成功、{error} 个提交失败', {
      action,
      success,
      error,
    });
    messageType = 'warning';
  }
  return { text, detail: results, messageType };
}

function formatAll(
  results: PromiseAllSettledResult[],
  { action, resourceType, resourceName, silent }: Options
): FormatResult {
  const newResults = flatten(
    results?.map((item) => {
      // 如果是批量请求返回具体的请求结果
      if (item.status === 'fulfilled' && Array.isArray(item?.value)) {
        return item.value;
      } else {
        return item;
      }
    })
  );

  // 批量接口的请求
  const batchResults = newResults?.filter((item) => item?.statusCode);
  // 批量请求成功的接口
  const batchSuccess = batchResults.filter((result) => [200, 201, 202, 204].includes(result.statusCode));
  // 批量请求失败的接口数
  const batchErrorNum = batchResults?.length - batchSuccess?.length;

  const totalCount = newResults.length;
  const errorCount =
    newResults.filter((result) => result.status === 'rejected').length + batchErrorNum;

  let text = '';
  let messageType: MessageType = 'info';
  if (errorCount === 0) {
    if (silent) return;
    text = intl.get('{action}：{resourceType}“{resourceName}”请求提交成功', {
      action,
      resourceType,
      resourceName,
    });
  } else if (errorCount === totalCount) {
    text = intl.get('{action}：{resourceType}“{resourceName}”请求提交失败', {
      action,
      resourceType,
      resourceName,
    });
    messageType = 'error';
  } else {
    text = intl.get('{action}：{resourceType}“{resourceName}”请求提交异常', {
      action,
      resourceType,
      resourceName,
    });
    messageType = 'warning';
  }
  return { text, detail: newResults, messageType };
}

function formatError(error: Error, { action, resourceType, resourceName }: Options): FormatResult {
  const text = intl.get('{action}：{resourceType}{resourceName}请求提交失败', {
    action,
    resourceType,
    resourceName: resourceName && `“${resourceName}”`,
  });
  return { text, detail: error, messageType: 'error' };
}

type Options = {
  action: string;
  resourceType?: string;
  resourceName?: string;
  silent?: boolean;
  mode?: 'batch' | 'all';
};

function withMessage<T>(p: Promise<T>, options: Options): Promise<T> {
  return new Promise((resolve, reject) => {
    const mode = options.mode;
    let content: FormatResult;
    p.then((result) => {
      if (mode === 'batch') {
        content = formatBatch(result as BatchResponse<Record<string, any>>[], options);
      } else if (mode === 'all') {
        content = formatAll(result as PromiseAllSettledResult[], options);
      } else {
        content = formatOne(result as FetchResult<Record<string, any>>, options);
      }
      resolve(result);
    })
      .catch((error) => {
        if (error !== undefined) {
          content = formatError(error as Error, options);
          reject(error);
        }
      })
      .finally(() => {
        if (!content) return;
        const messageType = content.messageType ?? 'info';
        // 请求成功则不需要展示“响应详情”
        if (messageType === 'info') delete content.detail;
        const messageContent = formatContent(content);
        message.open(messageType, messageContent);
      });
  });
}

export default withMessage;
