// Owner: Yuliang Liao

import React from 'react';
import { Drawer as MuiDrawer, Box } from '@mui/material';

import themeCSSObj from '~/styles/theme.module.scss';
import IconButton from '~/components/ui/IconButton';

import { getDrawerCSS } from './lib';
import { DrawerProps } from './type';

const Drawer = (props: DrawerProps) => {
  const { children, open, onClose, title, titleRight, width = '400px', bottomHeight = '0px', ...rest } = props;
  return (
    <MuiDrawer onClose={onClose} open={open} css={getDrawerCSS(width, bottomHeight)} {...rest}>
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          flex="0 0"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: themeCSSObj.typographyH6FontSize,
            fontWeight: +themeCSSObj.typographyH6FontWeight,
            color: themeCSSObj.appFontColor1,
            padding: '16px 24px',
          }}
        >
          <Box flex={1}>{title}</Box>
          <Box display="flex" gap="18px">
            {titleRight}
            <IconButton icon="Close16" onClick={onClose} />
          </Box>
        </Box>
        <Box padding="0 24px 16px 24px" flex="1" overflow="auto">
          {children}
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
