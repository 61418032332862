/**
 * 该文件只允许在 _app.tsx 中引入
 */
import lodash from 'lodash';
import * as yup from 'yup';

/**
 * 增加 unique 校验， 用于校验列表中的同一个字段有相同的值
 */
yup.addMethod(yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    if (!array) {
      return true;
    }

    const countResult = lodash.countBy(array, (row) => row[field]);
    const isNotUnique = Object.keys(countResult).filter(
      (key) => countResult[key] > 1 && key !== ''
    );

    let errors: any[] = [];

    array.forEach((row, i) => {
      if (isNotUnique.includes(row[field])) {
        errors.push(
          this.createError({
            path: `${this.path}.${i}.${field}`,
            message,
          })
        );
      }
    });

    if (errors.length > 0) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});
