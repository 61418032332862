/*
 * App Provider
 */

import React from 'react';

import { CollectionListItemType } from '~/components/common/TopNavi/interface';
import { useGetCollectionQuery } from '~/__generated__/graphql/app/operations/clientStore.graphql';

interface AppContextValue {
  collectionList: CollectionListItemType[];
}

const AppContext = React.createContext<AppContextValue>({
  collectionList: []
});

function AppProvider(props: { children: React.ReactNode }) {
  const { data } = useGetCollectionQuery();
  const collectionList = (data?.collection || []) as AppContextValue['collectionList'];

  const appValue: AppContextValue = {
    collectionList,
  };

  return <AppContext.Provider value={appValue}>{props.children}</AppContext.Provider>;
}

function useAppContext() {
  return React.useContext(AppContext);
}

export type { AppContextValue };
export { useAppContext };
export default AppProvider;
