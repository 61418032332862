import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

import dialog from '~/lib/dialog';
import Icon from '~/charon/Icon';
import ps from '~/lib/ps';
import { POP_DIALOG } from '~/constants/ps-events';
import Tooltip from '~/components/ui/Tooltip';
import PRRWeb from '~/lib/p-rrweb';

import { getFeedbackButtonStyle } from './lib';

export default function FeedbackButton() {
  const [hidden, setHidden] = useState(false);

  const refreshRecord = () => {
    PRRWeb.refresh();
  };
  const openFeedback = () => {
    dialog.form({
      form: 'FeedbackForm',
    });
    // 打开表单后隐藏反馈按钮
    setHidden(true);
  };

  useEffect(() => {
    // 关闭表单后显示反馈按钮
    const token = ps.subscribe(POP_DIALOG, () => {
      setHidden(false);
    });
    return () => {
      ps.unsubscribe(token);
    };
  }, []);

  return (
    <Box>
      <Tooltip title={intl.get('重新录制视频')} placement="left">
        <Box
          css={[getFeedbackButtonStyle(hidden), { marginBottom: '8px' }]}
          onClick={refreshRecord}
        >
          <Icon name="Refresh20" />
        </Box>
      </Tooltip>
      <Tooltip title={intl.get('问题反馈')} placement="left">
        <Box css={getFeedbackButtonStyle(hidden)} onClick={openFeedback} data-e2e="feedback">
          <Icon name="Feedback20" />
        </Box>
      </Tooltip>
    </Box>
  );
}
