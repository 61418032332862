import { nanoid } from 'nanoid';
/**
 * 全局任务队列，负责获取任务并执行，限制并发运行的任务数量
 */
export interface Task {
  id: string; // 任务id
  info: TaskInfo;
  func: (...args: any[]) => Promise<any>; // 任务对应的函数
  args: any[];
  resp: any;
  error: any;
  status: 'pending' | 'running' | 'finished' | 'failed'; // 任务当前状态
}

export interface TaskInfo {
  type: 'upload'; // 任务类型，用于后面任务中心不同的视图
  description?: string; // 任务描述，待定
}

export interface Listener {
  id: string;
  func: (task: Task) => void;
}

export interface GlobalTasks {
  queue: Task[];
  listeners: Set<Listener>;
  getSnapshot: () => Task[];
  push: (func: (...args: any[]) => Promise<any>, info: TaskInfo, ...args: any[]) => string;
  subscribe: (func: (task: Task) => void) => () => void;
  subscribeOne: (taskId: string, func: (task: Task) => void) => () => void;
}
const TaskLimit = 4; // 进行中的任务数量限制

const globalTasks: GlobalTasks = {
  queue: [], // 任务队列
  listeners: new Set(),
  getSnapshot: () => globalTasks.queue,
  push: pushTask,
  subscribe,
  subscribeOne,
};

function runTask(task: Task) {
  task.status = 'running';

  globalTasks.listeners.forEach((listener) => listener.func(task));

  task
    .func(...task.args)
    .then((res) => {
      task.status = 'finished';
      task.resp = res;

      globalTasks.listeners.forEach((listener) => listener.func(task));
    })
    .catch((error) => {
      task.status = 'failed';
      task.error = error;

      globalTasks.listeners.forEach((listener) => listener.func(task));
    })
    .finally(() => {
      globalTasks.queue = [...globalTasks.queue];
      const rest = globalTasks.queue.filter((task) => task.status === 'pending');
      if (rest.length) {
        runTask(rest[0]);
      }
    });
}

function pushTask(func: (...args: any[]) => Promise<any>, info: TaskInfo, ...args: any[]) {
  const id = nanoid();

  const task: Task = {
    id,
    info,
    func,
    args,
    resp: null,
    error: null,
    status: 'pending',
  };

  globalTasks.queue.push(task);

  if (globalTasks.queue.filter((task: Task) => task.status === 'running').length < TaskLimit) {
    runTask(task);
  }

  globalTasks.queue = [...globalTasks.queue];

  globalTasks.listeners.forEach((listener: Listener) => listener.func(task));

  return id;
}

function subscribeOne(taskId: string, func: (task: Task) => void) {
  const id = nanoid();
  const listener = {
    id,
    func: (task: Task) => {
      if (task.id === taskId) {
        func(task);
      }
    },
  };

  globalTasks.listeners.add(listener);

  return () => globalTasks.listeners.delete(listener);
}

function subscribe(func: (task: Task) => void) {
  const id = nanoid();
  const listener = {
    id,
    func: (task: Task) => {
      func(task);
    },
  };

  globalTasks.listeners.add(listener);

  return () => globalTasks.listeners.delete(listener);
}

export default globalTasks;
