import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() ?? {};

export const DEFAULT_BATCH_LIMIT = 100;

export const VENUS_PATH = publicRuntimeConfig?.VENUS_PATH;
export const DEMON_PATH = publicRuntimeConfig?.DEMON_PATH;

export enum BatchAPIPath {
  BS_VOLUMES = '/bs-volumes',
  BS_SNAP = '/bs-snaps',
  VIRTUAL_MACHINES = '/virtual-machines',
  VIRTUAL_MACHINES_CLONE = '/virtual-machines/:clone',
  VM_NIC = '/vm-nics',
  VM_GPU = '/vm-gpus',
  VM_DISK = '/vm-disks',
  VM_USB = '/vm-usbs',
  VM_CD_ROM = '/vm-cd-roms',
  VM_IMAGES = '/vm-images',
  DISKS = '/disks',
  VM_SNAPS = '/vm-snaps',
  OSDS = '/osds',
  NODES = '/nodes',
  VM_SCHEDULING_POLICY = '/vm-scheduling-policies',
  VM_SHCEDULING_RULES = '/vm-scheduling-rules',
  VM_SNAP_POLICY = '/vm-snap-policies',
  LABEL_KEY = '/label-keys',
  LABEL_VALUE = '/label-values',
  ALERT = '/alerts',
  SDS_ALERT_INFO = '/alert-infos',
  SDS_ALERT_INFO_GROUP = '/alert-info-groups',
}

export enum BatchApiAction {
  OSD_UNSET_ISOLATION = ':unset-isolation',
  OSD_MAINTAIN = ':maintain',
  OSD_UN_MAINTAIN = ':unmaintain',
  REBOOT = '/:reboot',
  START = '/:start',
  STOP = '/:stop',
  MIGRATE = '/:migrate',
  FAILURE_MIGRATE = '/:failure-migrate',
  RESET = '/:reset',
  SDS_ALERT_INFO_RESOLVE = ':resolve',
  SDS_ALERT_INFO_GROUPS_RESOLVE = ':resolve',
}
