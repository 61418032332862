/* 082ef3dfeeed91557e067b32e21766290298cb6a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetVersionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVersionQuery = (
  { __typename?: 'Query' }
  & { version?: Types.Maybe<(
    { __typename?: 'Version' }
    & Pick<Types.Version, 'version'>
    & { module?: Types.Maybe<(
      { __typename?: 'VersionModule' }
      & { venus?: Types.Maybe<(
        { __typename?: 'ComponentInfo' }
        & Pick<Types.ComponentInfo, 'version'>
      )>, pluto?: Types.Maybe<(
        { __typename?: 'ComponentInfo' }
        & Pick<Types.ComponentInfo, 'version'>
      )>, sds?: Types.Maybe<(
        { __typename?: 'ComponentInfo' }
        & Pick<Types.ComponentInfo, 'version'>
      )>, libvirt?: Types.Maybe<(
        { __typename?: 'ComponentInfo' }
        & Pick<Types.ComponentInfo, 'version'>
      )>, qemuKvm?: Types.Maybe<(
        { __typename?: 'ComponentInfo' }
        & Pick<Types.ComponentInfo, 'version'>
      )> }
    )> }
  )> }
);


export const GetVersionDocument = gql`
    query GetVersion {
  version {
    version
    module {
      venus {
        version
      }
      pluto {
        version
      }
      sds {
        version
      }
      libvirt {
        version
      }
      qemuKvm {
        version
      }
    }
  }
}
    `;

/**
 * __useGetVersionQuery__
 *
 * To run a query within a React component, call `useGetVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
      }
export function useGetVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionQuery, GetVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionQuery, GetVersionQueryVariables>(GetVersionDocument, options);
        }
export type GetVersionQueryHookResult = ReturnType<typeof useGetVersionQuery>;
export type GetVersionLazyQueryHookResult = ReturnType<typeof useGetVersionLazyQuery>;
export type GetVersionQueryResult = Apollo.QueryResult<GetVersionQuery, GetVersionQueryVariables>;