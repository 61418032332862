import intl from 'react-intl-universal';
import { Box } from '@mui/material';

import Typography from '~/components/ui/Typography';

export default function CommonError({ error }: { error: Error }) {
  return (
    <Box width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Typography
        type="error"
        css={{
          maxWidth: '960px',
        }}
      >
        {intl.get('抱歉，我们遇到了意料之外的错误: {error}', {
          error: error.toString(),
        })}
      </Typography>
    </Box>
  );
}
