import pubsub from 'pubsub-js';

function publish(message: symbol, data?: any) {
  return pubsub.publish(message, data);
}

const ps = {
  publish,
  subscribe: pubsub.subscribe,
  unsubscribe: pubsub.unsubscribe,
};

export default ps;
