import { nanoid } from 'nanoid';

import { PUSH_DIALOG, POP_DIALOG, CLEAR_DIALOG } from '~/constants/ps-events';
import forms from '~/components/forms';
import widgets from '~/components/widgets';
import ps from '~/lib/ps';
import { ConfirmDialogProps } from '~/components/common/ConfirmDialog/type';

// "360px" | "500px" | "800px"
type WidthType = 'small' | 'medium' | 'large';

interface DialogBaseType {
  workflowId?: string; // 用于标记当前对话框所属的工作流
  width?: WidthType;
  disableEsc?: boolean;
}

export type FormDialog = {
  _id: string;
  type: 'form';
  form: keyof typeof forms;
  formData?: Record<string, any>;
  fullScreen?: boolean;
  showTopNavi?: boolean;
} & DialogBaseType;

export type WidgetDialog = {
  _id: string;
  type: 'widget';
  widget: keyof typeof widgets;
  widgetData?: Record<string, unknown>;
  fullScreen?: boolean;
  showTopNavi?: boolean;
} & DialogBaseType;

export type ConfirmDialog = Omit<ConfirmDialogProps, 'onClose'> & {
  _id: string;
  type: 'confirm';
  fullScreen?: false;
  top?: string | number;
} & DialogBaseType;

export type Dialog = FormDialog | WidgetDialog | ConfirmDialog;

function form(data: Omit<FormDialog, 'type' | '_id'>) {
  return ps.publish(PUSH_DIALOG, {
    type: 'form',
    ...data,
    _id: nanoid(),
  });
}

function widget(data: Omit<WidgetDialog, 'type' | '_id'>) {
  return ps.publish(PUSH_DIALOG, {
    type: 'widget',
    ...data,
    _id: nanoid(),
  });
}

function confirm(data: Omit<ConfirmDialog, 'type' | '_id'>) {
  return ps.publish(PUSH_DIALOG, {
    type: 'confirm',
    ...data,
    _id: nanoid(),
  });
}

function pop() {
  return ps.publish(POP_DIALOG);
}

function clear() {
  return ps.publish(CLEAR_DIALOG);
}

const dialog = {
  form,
  widget,
  confirm,
  pop,
  clear,
};

export default dialog;
