import intl from 'react-intl-universal';
import { useEffect, useRef } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import healthCheck from '~/lib/health-check';
import message from '~/lib/message';

/**
 * 展示服务端状态 - 在服务端接口异常时弹窗提醒
 */
function ServerStatus() {
  const healthRef = useRef(healthCheck.getSnapshot());

  const healthy = useSyncExternalStore<boolean>(
    healthCheck.subscribe,
    healthCheck.getSnapshot,
    () => true
  );

  useEffect(() => {
    if (!healthy) {
      message.error(intl.get('服务端异常，请联系管理员'));
    } else if (healthRef.current !== healthy) {
      message.success(intl.get('服务端正常'));
    }
  }, [healthy]);

  return null;
}

export default ServerStatus;
