/*
 * Lib for Typography
 */

import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/react';

import themeStyle from '~/styles/theme.module.scss';
import {
  getPrimaryTextColor,
  getSuccessTextColor,
  getErrorTextColor,
  getCriticalTextColor,
  getInfoTextColor,
  getWarningTextColor,
  getOfflineTextColor,
} from '~/lib/color';
import { getEllipsisCSS } from '~/lib/css';

import type { TypographyPropsType } from './type';

function getStyle() {
  return css({
    fontWeight: getDefaultFontWeight(),
    userSelect: 'text',
  });
}

function getTypeStyle(type: TypographyPropsType['type'], disabled: boolean) {
  if (type === 't1') {
    return css({
      color: themeStyle.appFontColor1,
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 't2') {
    return css({
      color: themeStyle.appFontColor2,
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 't3') {
    return css({
      color: themeStyle.appFontColor3,
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 't4') {
    return css({
      color: themeStyle.appFontColor4,
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 't5') {
    return css({
      color: themeStyle.appFontColor5,
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'primary') {
    return css({
      color: getPrimaryTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'success') {
    return css({
      color: getSuccessTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'error') {
    return css({
      color: getErrorTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'critical') {
    return css({
      color: getCriticalTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'info') {
    return css({
      color: getInfoTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'warning') {
    return css({
      color: getWarningTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  if (type === 'offline') {
    return css({
      color: getOfflineTextColor('normal'),
      opacity: disabled ? 0.4 : 1,
    });
  }

  return css({});
}

function getSizeStyle(size: TypographyPropsType['size']) {
  if (size === 's1') {
    return css({
      fontSize: themeStyle.typographySubtitle2FontSize,
      lineHeight: themeStyle.typographySubtitle2LineHeight,
    });
  }

  if (size === 's2') {
    return css({
      fontSize: themeStyle.typographySubtitle1FontSize,
      lineHeight: themeStyle.typographySubtitle1LineHeight,
    });
  }

  if (size === 's3') {
    return css({
      fontSize: themeStyle.typographyH6FontSize,
      lineHeight: themeStyle.typographyH6LineHeight,
    });
  }

  if (size === 's4') {
    return css({
      fontSize: themeStyle.typographyH5FontSize,
      lineHeight: themeStyle.typographyH5LineHeight,
    });
  }

  if (size === 's5') {
    return css({
      fontSize: themeStyle.typographyH4FontSize,
      lineHeight: themeStyle.typographyH4LineHeight,
    });
  }

  if (size === 's6') {
    return css({
      fontSize: themeStyle.typographyH3FontSize,
      lineHeight: themeStyle.typographyH3LineHeight,
    });
  }

  return css({});
}

function getInlineStyle(isInline: boolean) {
  return css({
    display: isInline ? 'inline-block' : 'block',
  });
}

function getDefaultFontWeight() {
  return themeStyle.typographyFontWeightRegular as CSSObject['fontWeight'];
}

function getEllipsisStyle() {
  return css([getEllipsisCSS()]);
}

export {
  getStyle,
  getTypeStyle,
  getSizeStyle,
  getInlineStyle,
  getDefaultFontWeight,
  getEllipsisStyle,
};
