/**
 * P-RRWEB 用于控制整个系统的视频录像
 * ref: https://github.com/rrweb-io/rrweb
 */
import { record, EventType } from 'rrweb';
import { listenerHandler } from 'rrweb/typings/types';

type RRWebOptions = Parameters<typeof record>[0];

export type RRWebEvent = {
  type: EventType;
  data: any;
  timestamp: number;
  delay?: number;
};

// 每10分钟刷新一次
const CheckoutEveryNms = 10 * 60 * 1000;

const defaultRecordOptions: RRWebOptions = {
  checkoutEveryNms: CheckoutEveryNms,
  maskAllInputs: false,
};

const PRRWebFunc = function (recordOptions = defaultRecordOptions): PRRWebType {
  let stopFunc: listenerHandler | undefined;
  return {
    events: [],
    isRecording: false,
    start: function () {
      if (!this.isRecording) {
        stopFunc = record({
          ...recordOptions,
          emit: (event: RRWebEvent, isCheckout?: boolean) => {
            if (isCheckout) {
              this.events = [event];
            } else {
              this.events.push(event);
            }
          },
        });
        this.isRecording = true;
      } else {
        throw Error('rrweb is running, cant start again.');
      }
    },
    stop: function () {
      if (this.isRecording && stopFunc) {
        this.isRecording = false;
        this.events = [];
        stopFunc();
      } else {
        throw Error('rrweb is not running.');
      }
    },
    refresh: function () {
      if (this.isRecording) {
        this.events = [];
        stopFunc = record({
          ...recordOptions,
          emit: (event: RRWebEvent, isCheckout?: boolean) => {
            if (isCheckout) {
              this.events = [event];
            } else {
              this.events.push(event);
            }
          },
        });
      } else {
        throw Error('You need to run start  before refresh rrweb');
      }
    },
  };
};

interface PRRWebType {
  events: RRWebEvent[];
  isRecording: boolean;
  start: () => void;
  stop: () => void;
  refresh: () => void;
}

const PRRWeb: PRRWebType = PRRWebFunc();

export default PRRWeb;
