const externalRegex = /^http/;
export function isExternalLink(link: string) {
  return externalRegex.test(link);
}

export function processExternalLink(link: string, sdsIdpUuid?: string, sdsCallback?: string) {
  if (link.startsWith('/sds/')) {
    if (!sdsIdpUuid || !sdsCallback) return '/';
    const redirect = encodeURIComponent(link.replace(/^\/sds\//, ''));
    // 文件存储的共享，默认从 oauth-app(name=sds).callback 中获取
    const url = new URL(sdsCallback);
    if (sdsIdpUuid) url.searchParams.append('platform', sdsIdpUuid);
    if (redirect) url.searchParams.append('redirect', redirect);
    return url.toString();
  }
  return link;
}