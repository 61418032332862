import { css } from '@emotion/react';

import themeCSSObj from '~/styles/theme.module.scss';
import {
  getIconColor2,
  getIconColor3,
  getIconColor7,
  getIconColor8,
  getIconColor9,
} from '~/lib/color';

import type { IconButtonStyleProps } from './type';

export const ButtonClasses = {
  container: 'CharonButton-container',
};

export const IconButtonClasses = {
  container: 'CharonIconButton-container',
};

export const iconOnlyStyle = (size: string | number) => {
  return css({
    minWidth: size,
    background: 'unset',
    padding: '0px',
    border: 'none',
    '&.MuiButton-containedPrimary.Mui-disabled': {
      background: 'unset',
    },
    '&:hover': {
      border: 'none',
    },
    '&:active': {
      border: 'none',
      background: 'none',
      boxShadow: 'none',
    },
  });
};

const sizePadding = {
  standard: '2px',
  small: '6px',
  medium: '9px',
  large: '9px',
};
export function getIconButtonCSS({
  disabled,
  variant,
  normalColor,
  hoverColor,
  clickColor,
  disabledColor,
  clickableAreaInvisible,
  size,
}: IconButtonStyleProps) {
  return css({
    color: disabled ? disabledColor : normalColor,
    borderRadius: themeCSSObj.appShapeBorderRadius1,
    padding: sizePadding[size],
    '&:hover': {
      cursor: 'pointer',
      color: disabled ? normalColor : hoverColor,
      background: `${
        clickableAreaInvisible
          ? 'none'
          : disabled
          ? 'none'
          : variant === '4'
          ? themeCSSObj.appHotZoneInteractiveColor2
          : themeCSSObj.appHotZoneInteractiveColor1
      } !important`,
    },
    '&:active': {
      color: disabled ? normalColor : clickColor,
      background: `${
        clickableAreaInvisible
          ? 'none'
          : disabled
          ? 'none'
          : variant === '4'
          ? themeCSSObj.appHotZoneInteractiveColor4
          : themeCSSObj.appHotZoneInteractiveColor3
      } !important`,
    },
    '&:disabled': {
      pointerEvents: 'none',
      color: disabledColor,
    },
  });
}

export const iconStyle = css({
  display: 'block',
  background: 'none',
  outline: 'none',
  border: 'none',
  position: 'relative',
  transition: 'all 0.2s',
  padding: '0',
  fontSize: '0',
  borderRadius: themeCSSObj.appShapeBorderRadius3,
});

export const iconVariantColorList = [
  [
    getIconColor9('normal'),
    getIconColor9('hover'),
    getIconColor9('click'),
    getIconColor9('disable'),
  ],
  [
    getIconColor8('normal'),
    getIconColor8('hover'),
    getIconColor8('click'),
    getIconColor8('disable'),
  ],
  [
    getIconColor2('normal'),
    getIconColor2('hover'),
    getIconColor2('click'),
    getIconColor2('disable'),
  ],
  [
    getIconColor3('normal'),
    getIconColor3('hover'),
    getIconColor3('click'),
    getIconColor3('disable'),
  ],
  [
    getIconColor7('normal'),
    getIconColor7('hover'),
    getIconColor7('click'),
    getIconColor7('disable'),
  ],
];
