/*
 * Demon WebSocket
 */

import React from 'react';
import getConfig from 'next/config';
import type { Websocket } from 'websocket-ts/lib/websocket';
import { WebsocketBuilder, ConstantBackoff } from 'websocket-ts/lib';

import ps from '~/lib/ps';
import { LOGIN, LOGOUT, SDS_WS_MESSAGE } from '~/constants/ps-events';
import { X_SDDC_TOKEN_REGX, TOKEN } from '~/components/forms/lib';
import type { SDSWSData } from '~/lib/sdsResourceObserver';

const PING = '--ping--';
const PONG = '--pong--';

function DemonWs(props: { login: boolean }) {
  const { login } = props;

  const { publicRuntimeConfig } = getConfig();

  // handle login/logout
  const [isLogin, setIsLogin] = React.useState(login);
  React.useEffect(function () {
    const loginToken = ps.subscribe(LOGIN, function () {
      setIsLogin(true);
    });
    const logoutToken = ps.subscribe(LOGOUT, function () {
      setIsLogin(false);
    });
    return function () {
      ps.unsubscribe(loginToken);
      ps.unsubscribe(logoutToken);
    };
  }, []);

  // handle ws
  const [demonWs, setDemonWs] = React.useState<Websocket | null>(null);

  // ignore
  const handleOpen = function () {};
  const handleClose = function () {};
  const handleRetry = function () {};
  const handleError = function () {};
  // handle message
  const handleMessage = function (message: MessageEvent) {
    try {
      const data: SDSWSData = JSON.parse(message?.data);
      ps.publish(SDS_WS_MESSAGE, data);
    } catch (_error) {
      // ignore
    }
  };

  React.useEffect(
    function () {
      if (!isLogin && demonWs) {
        demonWs.close();
        setDemonWs(null);
        return;
      }

      if (isLogin && !demonWs) {
        const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const host = publicRuntimeConfig.DEMON_WS_HOSTNAME || window.location.hostname;
        const port = publicRuntimeConfig.DEMON_WS_PORT;
        const path = publicRuntimeConfig.DEMON_WS_PATH;
        const [, token] = document?.cookie?.match(X_SDDC_TOKEN_REGX) || [];

        const url = port
          ? `${protocol}://${host}:${port}/${path}?${TOKEN}=${token}`
          : `${protocol}://${host}/${path}?${TOKEN}=${token}`;
        
        const ws = new WebsocketBuilder(url)
          .withBackoff(new ConstantBackoff(7000)) // 7s between retries
          .onOpen(handleOpen)
          .onClose(handleClose)
          .onRetry(handleRetry)
          .onError(handleError)
          .onMessage(function (_, message) {
            if (message?.data === PING) {
              ws && ws.send(PONG);
            } else {
              handleMessage(message);
            }
          })
          .build();

        setDemonWs(ws);
      }
    },
    [isLogin, demonWs, publicRuntimeConfig]
  );

  return null;
}

export default DemonWs;
