/*
 * Util Functions
 */

import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { ServerError } from '@apollo/client/link/utils';
import intl from 'react-intl-universal';
import lodash from 'lodash';
import moment from 'moment';

import type { RadioGroupPropsType } from '~/components/ui/RadioGroup';
import { EMPTY_LABEL } from '~/constants';
import { SelectOption } from '~/components/ui/CSelect/type';
import { LocaleType } from '~/types/global';

import { xtime, parse, timeFormat } from './xtime';

type BulkOptions = {
  splitUnit?: boolean;
  toUnit?: string;
  withScale?: boolean;
};
/**
 * options optional attributes ‘splitUnit’ & ‘withScale’ & ‘toUnit’;
 * When toUnit is not empty, you can specify the value of the corresponding unit;
 * When splitUnit is true, by setting withScale to true, the conversion ratio of the corresponding unit will be returned together;
 * Similar to [1, '10,000', 1e4].
 */
function bulk(value: number, options?: BulkOptions, lang?: LocaleType) {

  const BULK_MAP =
  lang === 'enUS'
    ? [
      [1, ''],
      [1e3, 'K'],
      [1e6, 'M'],
      [1e9, 'B'],
      [1e12, 'T'],
    ]
    : [
      [1, ''],
      [1e4, '万'],
      [1e8, '亿'],
      [1e12, '兆'],
      [1e16, '京'],
    ];

  let unit = BULK_MAP[0][1];
  if (!value) {
    if (options && options.splitUnit) return [0, unit];
    return `0${unit}`;
  }
  let i = 1;
  while (BULK_MAP[i] && value >= BULK_MAP[i][0]) {
    i++;
  }
  unit = BULK_MAP[i - 1][1];
  let resultValue = value / (BULK_MAP[i - 1][0] as number);
  if (options && options.toUnit) {
    unit = BULK_MAP.filter((b) => b[1] === options.toUnit)[0][1];
    resultValue =
      value / (BULK_MAP.filter((b) => b[1] === options.toUnit)[0][0] as number);
  }
  if (parseInt(`${resultValue}`, 10) !== resultValue) {
    resultValue = Number(resultValue.toFixed(2));
  }
  if (options && options.splitUnit) {
    if (options.withScale)
      return [
        resultValue,
        unit,
        options.toUnit
          ? BULK_MAP.filter((b) => b[1] === options.toUnit)[0][0]
          : BULK_MAP[i - 1][0],
      ];
    return [resultValue, unit];
  } else {
    return `${resultValue} ${unit}`;
  }
}

function getBemClass(blockOrElement: string, modifiers: string | string[]) {
  if (typeof blockOrElement !== 'string' || blockOrElement === '') {
    return '';
  }
  if (typeof modifiers === 'string') {
    return [blockOrElement, [blockOrElement, modifiers].join(EMPTY_LABEL)].join(' ');
  }
  if (modifiers instanceof Array) {
    const modifierClassNames = modifiers
      .filter((item) => typeof item === 'string')
      .map((item) => [blockOrElement, item].join(EMPTY_LABEL));
    return [blockOrElement, ...modifierClassNames].join(' ');
  }
  return blockOrElement;
}

// Parsers: Byte
export const parsers = {
  /**
   * Parse object value to cron expression
   * { minute: '1', hour: '2', ... } -> '1 2 3 4 5'
   */
  cronObj2Expr(obj: Record<string, string>) {
    return lodash.values(obj).join(' ');
  },

  // Unit converters
  kb2bytes: (v: number) => v * Math.pow(1024, 1),
  mb2bytes: (v: number) => v * Math.pow(1024, 2),
  gb2bytes: (v: number) => v * Math.pow(1024, 3),
  tb2bytes: (v: number) => v * Math.pow(1024, 4),
  pb2bytes: (v: number) => v * Math.pow(1024, 5),
  eb2bytes: (v: number) => v * Math.pow(1024, 6),

  bytes2kb: (v: number) => v / Math.pow(1024, 1),
  bytes2mb: (v: number) => v / Math.pow(1024, 2),
  bytes2gb: (v: number) => v / Math.pow(1024, 3),
  bytes2tb: (v: number) => v / Math.pow(1024, 4),
  bytes2pb: (v: number) => v / Math.pow(1024, 5),
  bytes2eb: (v: number) => v / Math.pow(1024, 6),

  mb2gb: (v: number) => v / Math.pow(1024, 1),
  mb2tb: (v: number) => v / Math.pow(1024, 2),

  thousand2number: (v: number) => v * 1000,

  // JSON converter
  jsonParse: JSON.parse,

  // Percentage converter
  percent2num: (v: number) => v / 100,

  // Valid integer converter
  minute2second: (v: number) => parseInt(`${v}`) * 60,
  day2second: (v: number) => parseInt(`${v}`) * 24 * 60 * 60,
  second2minute: (v: number) => parseInt(`${v}`) / 60,
};

export const COLON = () => {return intl.get('：');};
export const COMMA = () => { return intl.get('，'); };

function getLeftParenthesis() {
  return intl.get('（');
}

function getRightParenthesis() {
  return intl.get('）');
}

/**
 * Reordering algorithm
 * @param list : Suitable for all types -> any
 * @param sourceIndex
 * @param destinationIndex
 * @returns
 */
export const reorder = (
  list: any[],
  sourceIndex: number,
  destinationIndex: number
) => {

  const temp = [...list];
  const target = temp[sourceIndex];
  temp.splice(sourceIndex, 1);
  temp.splice(destinationIndex, 0, target);
  return temp;
};

export const getMemorySize = (value: number, customUnit: string): number => {
  switch (customUnit) {
    case 'MiB':
      return value;
    case 'GiB':
      return value * 1024;
    case 'TiB':
      return value * 1024 * 1024;
    default: 
      return 0;
  }
};

function blockEvent(event: React.MouseEvent) {
  event.stopPropagation();
  event.preventDefault();
}

// Used to fill in data, value = '-' means no data
export function fillData(
  target: Record<string, any>[],
  start: number,
  end: number,
) {
  const rTarget = lodash.cloneDeep(target) || [];
  // 如果 target 不存在，返回 []
  if (!rTarget.length ) {
    return [];
  }

  // 由于 parse 计算出来的最小单位是 μs ,因此是 Math.pow(1000, 2);
  const oneSecond =  Math.pow(1000, 2);
  const timeStepNumber = end - start;
  const stepMicrosecond = parse(rangeTimeToStep(timeStepNumber));
  // 如果 μsStepTime 不存在，默认设置 15s 间隔
  const _step =  stepMicrosecond ?  stepMicrosecond / oneSecond : 15;
  const _start = start - (start % _step);
  const _end = end - (end % _step);
  const targetStart = lodash.toNumber(lodash.get(target, '0.time'));
  const targetEnd = lodash.toNumber(lodash.get(target, `${rTarget.length - 1}.time`));

  // 如果后端给了一个不在 [start, end] 区间的监控数据，认为非法数据不做任何处理;
  const isValid = targetStart >= _start &&  targetEnd <= _end;
  if (isValid) {
    const indexValue = targetStart;
    let preStart = indexValue;
    let nextStart = indexValue;
    let index = 0;
    if (_start < indexValue) {
      while (_start < preStart) {
        preStart = preStart - _step;
        rTarget.unshift({ time: preStart, value: '-' });
        index++;
      }
    }
    
    while (nextStart < _end) {
      index++;
      nextStart = nextStart + _step;
      if (nextStart !== lodash.toNumber(lodash.get(rTarget, `${index}.time`))) {
        rTarget.splice(index, 0, { time: nextStart, value: '-' });
      }
    }

    return rTarget;
  }

  return rTarget;
}

export const TIME_VALUE = {
  '3h': 3 * 60 * 60, // 3h
  '1d': 24 * 60 * 60, // 1d
  '7d': 7 * 24 * 60 * 60, // 7d
  '1m': 30 * 24 * 60 * 60, // 1m
  '3m': 3 * 30 * 24 * 60 * 60, // 3m
  '6m': 6 * 30 * 24 * 60 * 60, // 6m
  '9m': 9 * 30 * 24 * 60 * 60, // 9m
  '12m': 12 * 30 * 24 * 60 * 60, // >= 12m
};

export function getRangeTimeSecond(from?: string, to?: string) {
  const start = moment(from, 'YYYY-MM-DD HH:mm:ss');
  const end = moment(to, 'YYYY-MM-DD HH:mm:ss');
  const rangeTimeSecond = end.diff(start, 'seconds');
  return rangeTimeSecond;
}

export function rangeTimeToStep(rangeTimeSecond: number): string {
  if (rangeTimeSecond <= TIME_VALUE['3h']) {
    return '15s';
  } else if (TIME_VALUE['3h']< rangeTimeSecond && rangeTimeSecond <= TIME_VALUE['1d']) {
    return '1m';
  } else if (TIME_VALUE['1d']< rangeTimeSecond && rangeTimeSecond <= TIME_VALUE['7d']) {
    return '30m';
  } else if (TIME_VALUE['7d']< rangeTimeSecond && rangeTimeSecond <= TIME_VALUE['1m']) {
    return '1h';
  } else if (TIME_VALUE['1m']< rangeTimeSecond && rangeTimeSecond <= TIME_VALUE['3m']) {
    return '3h';
  } else if (TIME_VALUE['3m']< rangeTimeSecond && rangeTimeSecond <= TIME_VALUE['6m']) {
    return '6h';
  } else if (TIME_VALUE['6m']< rangeTimeSecond && rangeTimeSecond <= TIME_VALUE['9m']) {
    return '9h';
  } else {
    return '12h';
  }
}

export function getMonitoringTimeStr(timeStepNumber: number): string {
  const _30m = parse('30m') / Math.pow(1000, 2);
  const _3h = parse('3h') / Math.pow(1000, 2);
  const _1d = parse('1d') / Math.pow(1000, 2);
  const _7d = parse('7d') / Math.pow(1000, 2);
  const _30d = parse('30d') / Math.pow(1000, 2);
  const _90d = parse('90d') / Math.pow(1000, 2);
  if (timeStepNumber <= _30m) {
    return '30m';
  } else if (timeStepNumber > _30m && timeStepNumber <= _3h) {
    return '3h';
  } else if (timeStepNumber > _3h && timeStepNumber <= _1d) {
    return '1d';
  } else if (timeStepNumber > _1d && timeStepNumber <= _7d) {
    return '7d';
  } else if (timeStepNumber > _7d && timeStepNumber <= _30d) {
    return '30d';
  } else if (timeStepNumber > _30d && timeStepNumber <= _90d) {
    return '90d';
  } else {
    return '-';
  }
}

function getFirstWrappedIndex(parentElement: HTMLElement) {
  const list = parentElement.children;

  let itemTop = 0;
  let firstIndex = 0;
  for (let i = 0, len = list.length; i < len; i++) {
    const newItemTop = list[i].getBoundingClientRect().top;
    if (newItemTop > itemTop) {
      firstIndex = i;
      break;
    }
    itemTop = newItemTop;
  }

  return firstIndex;
}

export function getMemoryRadioGroupObj(
  memoryRadioItemList: RadioGroupPropsType['list'],
  memoryMb: number,
  memoryCustomUnitList: SelectOption[] 
) {
  const obj: {
    isCustom?: boolean;
    customItem?: {value: number | null, unit: string};
    listValue?: RadioGroupPropsType['value'];
  } = {};

  obj.isCustom = false;
  const memoryRadioItem = memoryRadioItemList.find(
    item => +(item.value ?? 0) === memoryMb
  );
  if (memoryRadioItem) {
    obj.listValue = memoryRadioItem.value;
    return obj;
  }

  obj.isCustom = true;
  if (memoryMb % 1024) {
    obj.customItem = {
      value: memoryMb,
      unit: memoryCustomUnitList[0].value
    };
  } else {
    const sizeMb = memoryMb / 1024;
    obj.customItem = {
      value: sizeMb,
      unit: memoryCustomUnitList[1].value
    };
  }
  return obj;
}

export const getMutationError = (mutationError: ApolloError) => {
  let errorMsg: string;
  const severError = mutationError.graphQLErrors[0]?.extensions?.responseBody;
  if (severError) {
    errorMsg = severError?.message || severError?.msg;
  } else {
    errorMsg = (mutationError.networkError as ServerError)?.result?.errors[0]
      ?.message;
  }
  return errorMsg || intl.get('未知错误');
};

function floatToPercent(f: number, decimal = 2) {
  return `${(f * 100).toFixed(decimal)}%`;
}

// 随机生成指定范围长度密码
export function createPassword(min: number, max: number) {
  //可以生成随机密码的相关数组
  const num = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const english = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  const ENGLISH = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const special = ['-', '_', '#'];
  const config = num.concat(english).concat(ENGLISH).concat(special);

  //先放入一个必须存在的
  const arr = [];
  arr.push(getOne(num));
  arr.push(getOne(english));
  arr.push(getOne(ENGLISH));
  arr.push(getOne(special));

  //获取需要生成的长度
  const len = min + Math.floor(Math.random() * (max - min + 1));

  for (let i = 4; i < len; i++) {
    //从数组里面抽出一个
    arr.push(config[Math.floor(Math.random() * config.length)]);
  }

  //乱序
  const newArr = [];
  for (let j = 0; j < len; j++) {
    newArr.push(arr.splice(Math.random() * arr.length, 1)[0]);
  }

  //随机从数组中抽出一个数值
  function getOne<T extends any>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  return newArr.join('');
}

// 随机生成指定长度大小写字母字符
export const randomCharacter = (len: number, type: 'num' | 'character' | 'numEn') => {
  let str: string = '';
  if (type === 'num') {
    str = '0123456789';
  } else if (type === 'character') {
    str = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
  } else if (type === 'numEn') {
    str = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789';
  }
  let randomStr = '';
  for (let i = 0; i < len; i++) {
    randomStr += str.charAt(Math.floor(Math.random() * str.length));
  }
  return randomStr;
};

/**
 * 大数加法
 * @param a 正整数
 * @param b 正整数
 * @returns 
 */
export function sumBigNumber(a: string, b: string) {
  let res = '';
  let temp = 0;
  // 分割成字符串
  let aArray = a.split('');
  let bArray = b.split('');

  while (aArray.length || bArray.length || temp) {
    //双非按位取反,可以保证~~undefined为0
    temp += ~~(aArray.pop() ?? 0) + ~~(bArray.pop() ?? 0);
    res = (temp % 10) + res;
    temp = temp > 9 ? 1 : 0;
  }
  return res.replace(/^0+/, '');
}

/**
 * 格式化大小，将byte转化为合适的显示格式
 * @param size 
 * @param toFixed 
 * @returns 
 */
export function sizeFormatted(size: number, toFixed: number = 2) {
  if (typeof size !== 'number') {
    return size;
  }

  const KiB = 1024;
  if (size < KiB) {
    return `${size} B`;
  } else if (size > KiB && size < 1024 * KiB) {
    return `${(size / KiB).toFixed(toFixed)} KiB`;
  } else if (size < 1024 * 1024 * KiB) {
    return `${(size / KiB / KiB).toFixed(toFixed)} MiB`;
  } else {
    return `${(size / KiB / KiB / KiB).toFixed(toFixed)} GiB`;
  }
}

export const SortOrderKeyMap: Record<'descend' | 'ascend', string> = {
  'descend': 'desc',
  'ascend': 'asc'
};

/**
 * pipe is an object.
 * pipe.format is a date format, like 'HH:mm:ss'.
 * pipe.diff is an array and has two value, the first value is start time, the second is unit like 'seconds'、'days'.
 */
export function momentWrap(time: string, pipe: {
  format?: string;
  diff?: [string, moment.unitOfTime.Diff];
}) {
  if (!time || time === '0001-01-01T00:00:00Z') return 'N/A';
  if (pipe && pipe.format === null) return moment(time).format();
  if (pipe && pipe.format) return moment(time).format(pipe.format);
  if (pipe) {
    let momentResult;
    if (pipe.format) momentResult = moment(time).format(pipe.format);
    if (pipe.diff)
      momentResult = moment(time).diff(moment(pipe.diff[0]), pipe.diff[1]);
    return momentResult;
  }
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

function fakeWaiting(cb = function () { }) {
  const FAKE_WAITING_TIME = 200;
  setTimeout(function () {
    cb();
  }, FAKE_WAITING_TIME);
}

function formatTime(time: moment.MomentInput) {
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

function formatDuration(start: moment.MomentInput, end: moment.MomentInput) {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return moment.duration(endMoment.diff(startMoment)).humanize();
}

/*
 * Usage: timeBegin getTimeSepText(timeBegin, timeEnd) timeEnd
 * Example 1: 00:00 至今日 01:00
 * Example 2: 01:00 至次日 00:00
 */
function getTimeSepText(timeBegin: string, timeEnd: string) {
  return (
    timeBegin.length > 0 && timeEnd.length > 0
      ? timeBegin > timeEnd
        ? intl.get('至次日')
        : intl.get('至今日')
      : intl.get('至')
  );
}

/**
 * 将字符串转为下划线形式，但是不转换英文句点「.」
 */
function snakeCaseExceptDot(str: string) {
  return str.split('.').map(item => lodash.snakeCase(item)).join('.');
}

/** 
 * 原生 hasOwnProperty 不支持 ts 类型推断，可以用这个
 */
function hasOwnProperty<X extends Record<string, unknown>, Y extends PropertyKey>
  (obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

// 计算最大公约数
function gcd(a: number, b: number): number {
  if (b === 0) return a;
  return gcd(b, a % b);
}

// 根据内容计算宽度
export function useGetFitContentWidth({
  label,
  fontSize,
  fontWeight,
}: {
  label: string | undefined;
  fontSize: number;
  fontWeight: number;
}) {
  
  const width = React.useMemo(() => {
    // not browser env
  if(typeof window === 'undefined') {
    return 0;
  }
    const span = document.createElement('span');
    span.innerText = label ?? '';
    span.style.opacity = '0';
    span.style.padding = '0px';
    span.style.position = 'absolute';
    span.style.fontSize = `${fontSize}px`;
    span.style.fontWeight = `${fontWeight}`;

    window.document.body.appendChild(span);
    const width = span.offsetWidth;
    window.document.body.removeChild(span);
    return width + 1; // + 1 防止小数点时会省略
  }, [fontSize, fontWeight, label]);

  if (!label) {
    return 0;
  }

  return width;
}

function isJSON(str: string): boolean {
  try {
    const obj = JSON.parse(str);
    return obj && typeof obj === 'object';
  } catch (e) {
    return false;
  }
}

function filterBlankSpace(values: string[]) {
  const formateArr = values.map((item) => item.replace(/\s/g, ''));
  const filterVal = formateArr.filter((item) => item !== '');
  return filterVal; 
}

function getIpAddressesView(ipAddresses: string[], hasBrackets: boolean): string {
  const myIpAddresses = ipAddresses.filter(function (ip) {
    return ip.length > 0;
  });

  if (myIpAddresses.length === 0) return '';

  const split = intl.get('，');
  const view = myIpAddresses.join(split);
  if (hasBrackets) {
    return intl.get('（{view}）', { view });
  } else {
    return view;
  }
}

export {
  bulk,
  getBemClass,
  xtime,
  blockEvent,
  parse,
  getFirstWrappedIndex,
  floatToPercent,
  timeFormat,
  filterBlankSpace,
  fakeWaiting,
  formatTime,
  formatDuration,
  getLeftParenthesis,
  getRightParenthesis,
  getTimeSepText,
  snakeCaseExceptDot,
  hasOwnProperty,
  gcd,
  isJSON,
  getIpAddressesView
};
