import { css } from '@mui/styled-engine';

import { getPrimaryColor } from '~/lib/color';
import { getCircleCSS } from '~/lib/css';
import themeCSSObj from '~/styles/theme.module.scss';

export function getFixedToolbarStyle() {
  return css({
    position: 'fixed',
    bottom: '32px',
    right: '32px',
    zIndex: +themeCSSObj.zIndexTooltip,
  });
}

export function getFeedbackButtonStyle(hidden: boolean) {
  return css([
    getCircleCSS(40),
    {
      display: hidden ? 'none' : 'flex',
      background: themeCSSObj.paletteCommonWhite,
      boxShadow: themeCSSObj.shadows7,
      svg: {
        color: `${themeCSSObj.appIconColor2}!important`,
      },
      transition: 'all .25s',
      cursor: 'pointer',
      '&:hover': {
        background: getPrimaryColor('hover'),
        svg: {
          color: `${themeCSSObj.paletteCommonWhite}!important`,
        },
      },
      '&:active': { background: getPrimaryColor('click') },
    },
  ]);
}
