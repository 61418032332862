/**
 * paging hooks
 * @example:
 */

export { useMounted, usePoll, usePreventLeaving };

import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import type { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import lodash from 'lodash';
import router from 'next/router';
import intl from 'react-intl-universal';
import { ApolloQueryResult } from '@apollo/client';

import ps from '~/lib/ps';
import { PREVENT_PAGE_LEAVING, ALLOW_PAGE_LEAVING } from '~/constants/ps-events';
import { PaginationFragment } from '~/__generated__/graphql/app/operations/pagination.fragment.graphql';
import { Exact } from '~/types/utils';

import { getMutationError } from './util';

export interface PaginationVariables {
  limit?: number | null;
  q?: string | null;
  offset?: number | null;
  sort?: string | null;
  [key: string]: any;
}

export interface PagingResult<
  TListData,
  TData,
  TVariables extends PaginationVariables = PaginationVariables
> {
  page: number;
  rowsPerPage: number;
  count: number;
  totalCount: number;
  hasNextPage: boolean;
  nextPage: () => Promise<ApolloQueryResult<TData>>;
  silentRefresh: () => Promise<ApolloQueryResult<TData>>;
  refresh: () => Promise<ApolloQueryResult<TData>>;
  reset: () => Promise<ApolloQueryResult<TData>>;
  setRowsPerPage: (value: number) => Promise<ApolloQueryResult<TData>>;
  setPage: (value: number) => Promise<ApolloQueryResult<TData>>;
  updateVariable: (
    v: Partial<Exclude<TVariables, 'offset' | 'limit'>>
  ) => Promise<ApolloQueryResult<TData>>;
  onRowsPerPageChange: (value: number) => Promise<ApolloQueryResult<TData>>;
  onPageChange: (value: number) => Promise<ApolloQueryResult<TData>>;
  listData: TListData[];
  variables: TVariables | undefined;
  currentData: TData | undefined; // current query data
  loading: boolean;
  errorMsg: string | undefined;
  nativeResult: QueryResult<TData, TVariables>;
}

/** paging hook for sds api */
export const useSDSPaging = <
  TListData,
  TData = any,
  TVariables extends PaginationVariables = PaginationVariables
>(
  useQuery: (baseOptions: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  v: {
    page?: number;
    rowsPerPage?: number;
    loadType?: 'page' | 'scroll';
    variables?: Exclude<TVariables, 'offset' | 'limit'>;
  } = {},
  skip: boolean = false
): PagingResult<TListData, TData, TVariables> => {
  const pagingData = usePaging<TListData, TData, TVariables>(useQuery, v, 'paging', skip);

  return pagingData;
};

/** paging hook for sddc api */
export const useSDDCPaging = <
  TListData,
  TData = any,
  TVariables extends PaginationVariables = PaginationVariables
>(
  useQuery: (
    baseOptions: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, Exact<TVariables>>,
  v: {
    page?: number;
    rowsPerPage?: number;
    loadType?: 'page' | 'scroll';
    variables?: Exclude<TVariables, 'offset' | 'limit'>;
  } = {},
  skip: boolean = false
): PagingResult<TListData, TData, TVariables> => {
  const pagingData = usePaging<TListData, TData, TVariables>(
    useQuery,
    v,
    'metadata.pagination',
    skip
  );

  return pagingData;
};

export const usePaging = <
  TListData,
  TData = any,
  TVariables extends PaginationVariables = PaginationVariables
>(
  useQuery: (baseOptions: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  v: {
    page?: number;
    rowsPerPage?: number;
    loadType?: 'page' | 'scroll';
    variables?: Exclude<TVariables, 'offset' | 'limit'>;
  } = {},
  pagingDataPath: string,
  skip: boolean
): PagingResult<TListData, TData, TVariables> => {
  const pageRef = React.useRef<{ silent: boolean }>({ silent: false });

  const [defaultV] = useState({
    page: v.page ?? 1,
    rowsPerPage: v.rowsPerPage ?? 50,
    loadType: v.loadType ?? 'page',
    variables: v.variables ?? undefined,
  });
  const [page, _setPage] = useState(defaultV.page);
  const [rowsPerPage, _setRowsPerPage] = useState(defaultV.rowsPerPage);
  const [totalCount, _setTotalCount] = useState(0);
  const [count, _setCount] = useState(0);
  const [_listData, _setListData] = useState<TListData[]>([]);
  const [loadType] = useState(defaultV.loadType);

  const hasNextPage = useMemo(
    () => page * rowsPerPage < totalCount,
    [page, rowsPerPage, totalCount]
  );

  const defaultValue: TVariables = useMemo(() => {
    return {
      ...defaultV.variables,
      offset: ((defaultV.page ?? 1) - 1) * (defaultV.rowsPerPage ?? 50),
      limit: defaultV.rowsPerPage ?? 50,
    } as TVariables;
  }, [defaultV.page, defaultV.rowsPerPage, defaultV.variables]);

  const nativeResult = useQuery({
    variables: defaultValue,
    notifyOnNetworkStatusChange: true,
    skip: skip,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { data, refetch, error, fetchMore, networkStatus, variables } = nativeResult;

  const loading = useMemo(() => {
    return pageRef.current.silent ? false : ![7, 8].includes(networkStatus);
  }, [networkStatus]);

  const errorMsg = useMemo(() => {
    return error ? getMutationError(error) : undefined;
  }, [error]);

  let listData = _listData;
  if (data) {
    const main = data[Object.keys(data)[0] as keyof typeof data];

    // handle sds paging
    let sdsItemsKey = '';
    if (pagingDataPath === 'paging') {
      for (const key in main) {
        if (Array.isArray(main[key])) {
          sdsItemsKey = key;
          break;
        }
      }
    }

    const items = lodash.get(main, sdsItemsKey || 'items', []);
    listData = items;
  }

  useEffect(() => {
    if (!data) {
      return;
    }

    const main = data[Object.keys(data)[0] as keyof typeof data];

    // handle sds paging
    let sdsItemsKey = '';
    if (pagingDataPath === 'paging') {
      for (const key in main) {
        if (Array.isArray(main[key])) {
          sdsItemsKey = key;
          break;
        }
      }
    }

    const pagination: PaginationFragment = lodash.get(main, pagingDataPath);

    if (!pagination) {
      // 需要有分页数据才设置分页
      return;
    }

    const items = lodash.get(main, sdsItemsKey || 'items', []);

    _setListData(items);
    _setPage(pagination?.offset / rowsPerPage + 1);

    _setTotalCount(lodash.get(pagination, 'totalCount', 0));
    _setCount(items?.length);
    _setRowsPerPage(lodash.get(pagination, 'limit', 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadType, pagingDataPath]);

  const silentRefresh = useCallback(() => {
    pageRef.current.silent = true;
    return refetch().then((data) => {
      pageRef.current.silent = false;
      return data;
    });
  }, [refetch]);

  /**
   * jump to next page
   */
  const nextPage: () => Promise<ApolloQueryResult<TData>> = useCallback(() => {
    if (loadType === 'scroll') {
      return fetchMore({
        variables: { offset: page * rowsPerPage },
      });
    } else {
      const _variables: TVariables = Object.assign({
        offset: page * rowsPerPage,
      });

      return refetch(_variables);
    }
  }, [loadType, page, rowsPerPage, refetch, fetchMore]);

  /**
   * refresh data base on current variables
   */
  const refresh = useCallback(() => {
    return refetch();
  }, [refetch]);

  /**
   * reset all variable
   */
  const reset = useCallback(() => {
    const resetVariables = lodash.mapValues(variables as Record<string, unknown>, () => undefined);
    const _variables = Object.assign({}, resetVariables, {
      offset: 0,
      limit: defaultV.rowsPerPage,
    });

    return refetch(_variables as TVariables);
  }, [defaultV.rowsPerPage, refetch, variables]);

  /**
   * set current page
   * @param value page
   */
  const setPage: (value: number) => Promise<ApolloQueryResult<TData>> = useCallback(
    (value: number) => {
      if (value) {
        const _variables: TVariables = Object.assign({
          offset: (value - 1) * rowsPerPage,
        });

        return refetch(_variables);
      }
      throw new Error('page must be greater than 0');
    },
    [refetch, rowsPerPage]
  );

  /**
   * set rowsPerPage and send request
   * @param value rowsPerPage
   */
  const setRowsPerPage: (value: number) => Promise<ApolloQueryResult<TData>> = useCallback(
    (value: number) => {
      if (value) {
        const _variables: TVariables = Object.assign({
          offset: 0,
          limit: value,
        });

        return refetch(_variables);
      }
      throw new Error('rowsPerPage must be greater than 0');
    },
    [refetch]
  );

  /**
   * update request variable and send request
   * @param v variable
   */
  const updateVariable: (
    v: Partial<Exclude<TVariables, 'offset' | 'limit'>>
  ) => Promise<ApolloQueryResult<TData>> = useCallback(
    (v: Partial<Exclude<TVariables, 'offset' | 'limit'>>) => {
      let _variables: Partial<TVariables> = Object.assign({}, v, { offset: 0 });
      return refetch(_variables);
    },
    [refetch]
  );

  return {
    page,
    rowsPerPage,
    count,
    totalCount,
    hasNextPage,
    nextPage,
    silentRefresh,
    refresh,
    reset,
    setRowsPerPage,
    setPage,
    updateVariable,
    onRowsPerPageChange: (value) => setRowsPerPage(value),
    onPageChange: (value) => setPage(value),
    listData,
    currentData: data, // current query data
    loading,
    errorMsg,
    variables,
    nativeResult,
  };
};

function useMounted() {
  const [hasMounted, setHasMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}

function usePoll(pollFlag: boolean, interval: number, cb: () => void) {
  const _pollTimer = React.useRef<number>();
  const getPollTimer = function () {
    return _pollTimer.current;
  };
  const resetPollTimer = function () {
    if (typeof _pollTimer.current === 'number') {
      clearInterval(_pollTimer.current);
    }
    _pollTimer.current = undefined;
  };
  const setPollTimer = function (timer: number) {
    resetPollTimer();
    _pollTimer.current = timer;
  };

  React.useEffect(() => {
    return function () {
      resetPollTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (pollFlag) {
      setPollTimer(
        setInterval(function () {
          // avoid memory leak, avoid unnecessary callback
          if (typeof getPollTimer() !== 'number') return;
          // do something
          cb();
        }, interval) as unknown as number
      );
    } else {
      resetPollTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollFlag]);
}

function usePreventLeaving() {
  const _isPreventing = React.useRef(false);

  const [confirmMessage, setConfirmMessage] = useState(intl.get('确认离开？'));

  React.useEffect(
    function () {
      const preventToken = ps.subscribe(PREVENT_PAGE_LEAVING, (message, data) => {
        handlePreventLeaving();
        if (data?.message) {
          setConfirmMessage(data?.message);
        }
      });
      const allowToken = ps.subscribe(ALLOW_PAGE_LEAVING, handleAllowLeaving);
      router.events.on('routeChangeStart', handleRouteChange);
      window.addEventListener('beforeunload', handleBeforeUnload);

      return function () {
        ps.unsubscribe(preventToken);
        ps.unsubscribe(allowToken);
        router.events.off('routeChangeStart', handleRouteChange);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };

      // internal things
      function handlePreventLeaving() {
        _isPreventing.current = true;
      }

      function handleAllowLeaving() {
        _isPreventing.current = false;
      }

      function handleRouteChange() {
        if (!_isPreventing.current) return;

        if (!confirm(confirmMessage)) {
          // reference: https://github.com/vercel/next.js/issues/2476
          throw 'PREVENT_PAGE_LEAVING';
        } else {
          handleAllowLeaving();
        }
      }

      function handleBeforeUnload(event: BeforeUnloadEvent) {
        if (!_isPreventing.current) return;

        const myEvent = event || window.event;
        return (myEvent.returnValue = true);
      }
    },
    [confirmMessage]
  );
}

export function usePromiseLoading(
  defaultValue: boolean = false
): [boolean, (promise: Promise<unknown>) => void] {
  const [loading, setLoading] = React.useState(defaultValue);

  const handlePromise = useCallback((promise: Promise<unknown>) => {
    setLoading(true);

    promise.then(() => {
      setLoading(false);
    });
  }, []);

  return [loading, handlePromise];
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
