import lodash from 'lodash';

/**
 * 将后端响应的 key 转为驼峰的格式
 * @param obj
 * @returns
 */
function getResponseCamelCase(obj: Record<string, any>) {
  const temp: Record<string, any> = {};
  const keys = Object.keys(obj);

  keys.forEach((key) => {
    const value = obj[key];
    temp[lodash.camelCase(key)] =
      lodash.isObject(value) && !lodash.isArray(value) ? getResponseCamelCase(value) : value;
  });

  return temp;
}

export { getResponseCamelCase };

export function SDDCPagination(...keyArgs: string[]) {
  return {
    keyArgs: keyArgs?.length ? keyArgs : ['q', 'sort'],
    merge(existing: any, incoming: any, { args: { offset, limit } }: any) {
      if (existing) {
        if (!incoming?.metadata?.pagination || !existing?.metadata?.pagination) {
          return incoming;
        }
        const mergedItem = existing ? existing.items.slice(0) : [];

        for (let i = 0; i < limit ?? incoming.items.length; ++i) {
          mergedItem[offset + i] = incoming.items[i];
        }

        // 处理稀疏数组
        for (let i = 0; i < mergedItem.length; i++) {
          if (mergedItem[i] === undefined) {
            mergedItem[i] = null;
          }
        }
        // 裁剪掉稀疏数组后面的 null
        for (let i = mergedItem.length - 1; i >= 0; i--) {
          if (mergedItem[i] === null) {
            mergedItem.pop();
          } else {
            break;
          }
        }

        return {
          items: mergedItem,
          metadata: incoming.metadata,
        };
      } else {
        return incoming;
      }
    },
  };
}

export function SDSPagination(...keyArgs: string[]) {
  return {
    keyArgs: keyArgs?.length ? keyArgs : ['q', 'sort'],
    merge(existing: any, incoming: any, { args: { offset, limit } }: any) {
      if (existing) {
        if (!incoming.paging || !existing.paging) {
          return incoming;
        }
        const key = Object.keys(incoming).filter(
          (key) => key !== 'paging' && key !== '__typename'
        )[0];
        const mergedItem = existing ? existing[key].slice(0) : [];

        for (let i = 0; i < limit ?? incoming[key].length; ++i) {
          mergedItem[offset + i] = incoming[key][i];
        }

        // 处理稀疏数组
        for (let i = 0; i < mergedItem.length; i++) {
          if (mergedItem[i] === undefined) {
            mergedItem[i] = null;
          }
        }
        // 裁剪掉稀疏数组后面的 null
        for (let i = mergedItem.length - 1; i >= 0; i--) {
          if (mergedItem[i] === null) {
            mergedItem.pop();
          } else {
            break;
          }
        }

        return {
          [key]: mergedItem,
          paging: incoming.paging,
        };
      } else {
        return incoming;
      }
    },
  };
}
