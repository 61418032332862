// Owner: Zhen Liu
// Link: https://www.figma.com/file/mQT3n2JUgmw1D4luhqlmat/Overlord-%E5%9B%BE%E6%A0%87%E5%BA%93

import { SvgIcon } from '@mui/material';
import clsx from 'clsx';
import * as icons from 'pluto-icon';
import React, { forwardRef } from 'react';

import { IconProps } from './type';

const Icon = forwardRef(_Icon);

function _Icon({ name, viewSize, color, className, ...rest }: IconProps, ref: any) {
  const getName = name.match(/\d+/);
  const size = getName?.[0];
  return (
    <SvgIcon
      ref={ref}
      css={{ color, transition: 'color .25s' }}
      style={{ fontSize: `${viewSize || size}px` }}
      viewBox={`0 0 ${size} ${size}`}
      component={icons[name]}
      className={clsx('CharonIcon', className)}
      {...rest}
    />
  );
}

export default Icon;
