/**
 * 统一管理 uiProfile.data.settings 字段，用于各种隐藏配置项
 * Author: liu.zhen@xksy.com
 * 当前配置信息:
 * tableView: 列表的视图配置
 */
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import {
  useGetUserUiProfileQuery,
  useUserUpdateUiProfileMutation,
} from '~/__generated__/graphql/app/operations/users.graphql';
import { TableViewConfigItem } from '~/components/blocks/ResourceTable/type';

import { getUserId, getUserSettings, setUserSettings } from '../lib/store';

interface UserSetting {
  tableView?: Record<string, TableViewConfigItem[]>;
}

interface UserSettingContextProps {
  tableView?: Record<string, TableViewConfigItem[]>;
  updateTableView?: (tableViewConfigItems: TableViewConfigItem[], key: string) => Promise<any>;
  clearUserSetting?: () => Promise<any>;
}

const UserProfileContext = createContext<UserSettingContextProps>({});

export default function UserSettingProvider({ children }: { children: ReactNode }) {
  const userSettingsFromLocalStorage = getUserSettings();
  const userId = getUserId();

  const { data: userProfile } = useGetUserUiProfileQuery({
    variables: {
      id: userId,
    },
    skip: !userId,
  });

  const [updateUserSetting] = useUserUpdateUiProfileMutation();

  // 当后端没有数据时，使用本地的 tableView 配置
  const userSetting = useMemo(() => {
    if (userProfile) {
      if (userProfile.uiProfile?.data.settings) {
        try {
          return JSON.parse(userProfile.uiProfile?.data.settings);
        } catch (e) {
          console.error('user setting cant be parsed');
          return userSettingsFromLocalStorage;
        }
      }
    }
    return userSettingsFromLocalStorage;
  }, [userSettingsFromLocalStorage, userProfile]);

  // 同步远程和本地， 远程覆盖本地
  useEffect(() => {
    if (userProfile?.uiProfile?.data.settings) {
      try {
        const userSetting = JSON.parse(userProfile.uiProfile?.data.settings);

        setUserSettings(userSetting);
      } catch (e) {
        // 解析配置失败
        console.error('user setting cant be parsed');
      }
    }
  }, [userProfile?.uiProfile?.data.settings]);

  const tableView = useMemo(() => {
    return userSetting?.tableView;
  }, [userSetting?.tableView]);

  // 更新列表视图
  function updateTableView(tableViewConfigItems: TableViewConfigItem[], key: string) {
    const newTableView = {
      ...tableView,
      [key]: tableViewConfigItems,
    };

    const newUserSetting: UserSetting = {
      ...userSetting,
      tableView: newTableView,
    };

    return updateUserSetting({
      variables: {
        id: userId,
        input: {
          data: {
            settings: JSON.stringify(newUserSetting),
          },
        },
      },
    });
  }

  function clearUserSetting() {
    setUserSettings({});
    return updateUserSetting({
      variables: {
        id: userId,
        input: {
          data: {
            settings: JSON.stringify({}),
          },
        },
      },
    });
  }

  return (
    <UserProfileContext.Provider
      value={{
        tableView,
        updateTableView,
        clearUserSetting,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}

export function useUserSetting(): UserSettingContextProps {
  const result = useContext(UserProfileContext);
  return result;
}
