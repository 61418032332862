/*
 * Lib for Color
 *
 * Note: HSB is identical to HSV.
 */

import colorJS from 'color-js';

import themeCSSObj from '~/styles/theme.module.scss';
import { getAssociatedColor } from '~/lib/colorCalculation';

type ColorKindType = 'normal' | 'hover' | 'click' | 'disable' | 'statusBackground';

function getHoverColor(color: string) {
  const originColor = colorJS(color);

  // H:same, S:+10, V:-5
  const retColor = originColor.saturateByAmount(+0.1).valueByAmount(-0.05);

  return retColor.toString();
}

function getClickColor(color: string) {
  const originColor = colorJS(color);

  // H:same, S:+20, V:-10
  const retColor = originColor.saturateByAmount(+0.2).valueByAmount(-0.1);

  return retColor.toString();
}

function getDisableColor(color: string) {
  const originColor = colorJS(color);

  // H:same, S:same, V:same, Alpha:0.4
  const retColor = originColor.setAlpha(0.4);

  return retColor.toString();
}

function getStatusBackgroundColor(color: string) {
  const originColor = colorJS(color);

  // H:same, S:6, V:99
  const retColor = originColor.setSaturation(0.06).setValue(0.99);

  return retColor.toString();
}

function getKindColor(kind: ColorKindType, color: string) {
  let retColor = color;

  switch (kind) {
    case 'normal':
      retColor = color;
      break;
    case 'hover':
      retColor = getHoverColor(color);
      break;
    case 'click':
      retColor = getClickColor(color);
      break;
    case 'disable':
      retColor = getDisableColor(color);
      break;
    case 'statusBackground':
      retColor = getStatusBackgroundColor(color);
      break;
    default:
      break;
  }

  return retColor;
}

function getKindTextColor(kind: ColorKindType = 'normal', color: string) {
  let retColor = color;

  switch (kind) {
    case 'normal':
      retColor = getAssociatedColor(color)[6];
      break;
    case 'hover':
      retColor = getAssociatedColor(color)[7];
      break;
    case 'click':
      retColor = getAssociatedColor(color)[8];
      break;
    default:
      break;
  }

  return retColor;
}

function getKindTextLinkColor(kind: ColorKindType = 'normal', color: string) {
  let retColor = color;

  switch (kind) {
    case 'normal':
      retColor = getAssociatedColor(color)[color === themeCSSObj.appPrimaryColor ? 5 : 6];
      break;
    case 'hover':
      retColor = getAssociatedColor(color)[color === themeCSSObj.appPrimaryColor ? 6 : 7];
      break;
    case 'click':
      retColor = getAssociatedColor(color)[color === themeCSSObj.appPrimaryColor ? 7 : 8];
      break;
    default:
      break;
  }

  return retColor;
}

function getKindOfflineTextColor(kind: ColorKindType = 'normal') {
  let retColor = themeCSSObj.grey7;

  switch (kind) {
    case 'normal':
      retColor = themeCSSObj.grey7;
      break;
    case 'hover':
      retColor = themeCSSObj.grey8;
      break;
    case 'click':
      retColor = themeCSSObj.grey9;
      break;
    default:
      break;
  }
  return retColor;
}

function getPrimaryColor(kind?: ColorKindType) {
  const color = themeCSSObj.palettePrimaryMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getSuccessColor(kind?: ColorKindType) {
  const color = themeCSSObj.paletteSuccessMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getWarningColor(kind?: ColorKindType) {
  const color = themeCSSObj.paletteWarningMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getErrorColor(kind?: ColorKindType) {
  const color = themeCSSObj.paletteErrorMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getCriticalColor(kind?: ColorKindType) {
  const color = themeCSSObj.appCriticalMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getInfoColor(kind?: ColorKindType) {
  const color = themeCSSObj.paletteInfoMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getOfflineColor(kind?: ColorKindType) {
  const color = themeCSSObj.appOfflineMain;
  if (!kind) return color;
  return getKindColor(kind, color);
}

function getPrimaryTextColor(kind?: ColorKindType) {
  return getKindTextColor(kind, themeCSSObj.appPrimaryColor);
}

function getSuccessTextColor(kind?: ColorKindType) {
  return getKindTextColor(kind, themeCSSObj.appSuccessMain);
}

function getErrorTextColor(kind?: ColorKindType) {
  return getKindTextColor(kind, themeCSSObj.appErrorMain);
}

function getCriticalTextColor(kind?: ColorKindType) {
  return getKindTextColor(kind, themeCSSObj.appCriticalMain);
}

function getInfoTextColor(kind?: ColorKindType) {
  return getKindTextColor(kind, themeCSSObj.appInfoMain);
}

function getWarningTextColor(kind?: ColorKindType) {
  return getKindTextColor(kind, themeCSSObj.appWarningMain);
}

function getOfflineTextColor(kind?: ColorKindType) {
  return getKindOfflineTextColor(kind);
}

function getPrimaryLinkTextColor(kind?: ColorKindType) {
  return getKindTextLinkColor(kind, themeCSSObj.appPrimaryColor);
}

function getSuccessLinkTextColor(kind?: ColorKindType) {
  return getKindTextLinkColor(kind, themeCSSObj.appSuccessMain);
}

function getErrorLinkTextColor(kind?: ColorKindType) {
  return getKindTextLinkColor(kind, themeCSSObj.appErrorMain);
}

function getCriticalLinkTextColor(kind?: ColorKindType) {
  return getKindTextLinkColor(kind, themeCSSObj.appCriticalMain);
}

function getInfoLinkTextColor(kind?: ColorKindType) {
  return getKindTextLinkColor(kind, themeCSSObj.appInfoMain);
}

function getWarningLinkTextColor(kind?: ColorKindType) {
  return getKindTextLinkColor(kind, themeCSSObj.appWarningMain);
}

function getIconColor1(kind?: ColorKindType) {
  const color = themeCSSObj.appIconColor1;
  if (!kind) return color;
  if (kind === 'normal') return color;
  if (kind === 'disable') return getKindColor(kind, color);
  return getPrimaryColor(kind);
}

function getIconColor2(kind?: ColorKindType) {
  const color = themeCSSObj.appIconColor2;
  if (!kind) return color;
  if (kind === 'normal') return color;
  if (kind === 'disable') return getKindColor(kind, color);
  return getPrimaryColor(kind);
}

function getIconColor3(kind?: ColorKindType) {
  const color = themeCSSObj.grey8;
  if (!kind) return color;
  if (kind === 'normal') return color;
  if (kind === 'hover') return getAssociatedColor(getErrorColor())[6];
  if (kind === 'click') return getAssociatedColor(getErrorColor())[7];
  if (kind === 'disable') return getKindColor(kind, color);
}

function getIconColor4(kind?: ColorKindType) {
  return getPrimaryColor(kind);
}

function getIconColor5(kind?: ColorKindType) {
  const color = themeCSSObj.appIconColor5;
  if (!kind) return color;
  if (kind === 'normal') return color;
  return getPrimaryColor(kind);
}

function getIconColor6(kind?: ColorKindType) {
  const color = getWarningColor();
  if (!kind) return color;
  if (kind === 'normal') return color;
  return getWarningColor(kind);
}

function getIconColor7(kind?: ColorKindType) {
  const color = themeCSSObj.appIconColor7;
  if (!kind) return color;
  if (kind === 'normal') return color;
  if (kind === 'hover') return themeCSSObj.paletteCommonWhite;
  if (kind === 'click') return themeCSSObj.paletteCommonWhite;
  if (kind === 'disable') return 'rgba(255, 255, 255, 0.4)';
  return color;
}

function getIconColor8(kind?: ColorKindType) {
  const color = themeCSSObj.grey8;
  if (!kind) return color;
  if (kind === 'normal') return color;
  if (kind === 'hover') return themeCSSObj.grey9;
  if (kind === 'click') return themeCSSObj.grey11;
  if (kind === 'disable') return getKindColor(kind, color);
}

function getIconColor9(kind?: ColorKindType) {
  const color = themeCSSObj.grey7;
  if (!kind) return color;
  if (kind === 'normal') return color;
  if (kind === 'hover') return themeCSSObj.grey8;
  if (kind === 'click') return themeCSSObj.grey9;
  if (kind === 'disable') return getKindColor(kind, color);
}

export type ColorExtensionType =
  | 0
  | 0.05
  | 0.1
  | 0.2
  | 0.3
  | 0.35
  | 0.4
  | 0.5
  | 0.6
  | 0.7
  | 0.8
  | 0.9
  | 1;

function getPrimaryColorExtension(type: ColorExtensionType) {
  const color = themeCSSObj.palettePrimaryMain;
  const originColor = colorJS(color);

  const retColor = originColor.setAlpha(type);

  return retColor.toString();
}

function getWhiteColorExtension(type: ColorExtensionType) {
  const color = themeCSSObj.paletteCommonWhite;
  const originColor = colorJS(color);

  const retColor = originColor.setAlpha(type);

  return retColor.toString();
}

function getBlackColorExtension(type: ColorExtensionType) {
  const color = themeCSSObj.paletteCommonBlack;
  const originColor = colorJS(color);

  const retColor = originColor.setAlpha(type);

  return retColor.toString();
}

type ColorAlphaType = 0 | ColorExtensionType;

function getColorAlpha(color: string, type: ColorAlphaType) {
  const originColor = colorJS(color);

  const retColor = originColor.setAlpha(type);

  return retColor.toString();
}

export {
  getPrimaryColor,
  getSuccessColor,
  getWarningColor,
  getErrorColor,
  getCriticalColor,
  getInfoColor,
  getOfflineColor,
  getPrimaryTextColor,
  getSuccessTextColor,
  getErrorTextColor,
  getCriticalTextColor,
  getInfoTextColor,
  getWarningTextColor,
  getPrimaryLinkTextColor,
  getSuccessLinkTextColor,
  getErrorLinkTextColor,
  getCriticalLinkTextColor,
  getInfoLinkTextColor,
  getWarningLinkTextColor,
  getOfflineTextColor,
  getIconColor1,
  getIconColor2,
  getIconColor3,
  getIconColor4,
  getIconColor5,
  getIconColor6,
  getIconColor7,
  getIconColor8,
  getIconColor9,
  getPrimaryColorExtension,
  getWhiteColorExtension,
  getBlackColorExtension,
  getKindColor,
};

// 不属于「设计规范/主题」
export { getColorAlpha };
