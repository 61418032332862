/**
 * 全局 message 服务
 */
import React from 'react';
import { SnackbarKey, OptionsObject } from 'notistack';

import { CLOSE_MESSAGE, MESSAGE } from '~/constants/ps-events';
import ps from '~/lib/ps';

export type MessageType = 'success' | 'error' | 'warning' | 'info';
export interface MessageData {
  type: MessageType;
  message: string;
  more: React.ReactNode;
  options: OptionsObject;
  loading: boolean;
}

function open(
  type: MessageType,
  message: string | React.ReactNode,
  loading?: boolean,
  more?: React.ReactNode,
  option?: OptionsObject
) {
  return ps.publish(MESSAGE, {
    type,
    message,
    more,
    loading,
  });
}

function close(key: SnackbarKey) {
  ps.publish(CLOSE_MESSAGE, key);
}

function success(
  message: string | React.ReactNode,
  loading?: boolean,
  more?: React.ReactNode,
  option?: OptionsObject
) {
  open('success', message, loading, more, option);
}

function error(
  message: string | React.ReactNode,
  loading?: boolean,
  more?: React.ReactNode,
  option?: OptionsObject
) {
  open('error', message, loading, more, option);
}

function warning(
  message: string | React.ReactNode,
  loading?: boolean,
  more?: React.ReactNode,
  option?: OptionsObject
) {
  open('warning', message, loading, more, option);
}

function info(
  message: string | React.ReactNode,
  loading?: boolean,
  more?: React.ReactNode,
  option?: OptionsObject
) {
  open('info', message, loading, more, option);
}

const message = {
  open,
  success,
  error,
  warning,
  info,
  close,
};


export default message;
