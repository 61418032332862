// Owner: Anqi Shu
// Link: https://www.figma.com/file/yH2At4lyrzmEgK6cnNGRTG/SDS-%E8%AE%BE%E8%AE%A1%E7%B3%BB%E7%BB%9F?node-id=162%3A2369

import React, { MutableRefObject } from 'react';
import { Alert as MuiAlert, Box } from '@mui/material';
import * as icons from 'pluto-icon';
import clsx from 'clsx';
import Image from 'next/image';

import Icon from '~/charon/Icon';
import Typography from '~/components/blocks/Typography';
import LoadingImg from '~/public/images/loading.png';

import { AlertProps } from './type';
import { alertContainer } from './lib';

const Alert = React.forwardRef(_Alert);

function _Alert(props: AlertProps, ref: MutableRefObject<HTMLDivElement>) {
  const StatusIcon = {
    error: 'CloseCircle16' as icons.TIconName,
    success: 'DoneFill16' as icons.TIconName,
    info: 'NoticeFill16' as icons.TIconName,
    warning: 'WarnFill16' as icons.TIconName,
  };
  const {
    action,
    children,
    loading,
    severity = 'success',
    alignCenter = false,
    className,
    icon,
    nonBackground = false,
    ...rest
  } = props;
  return (
    <MuiAlert
      css={alertContainer(nonBackground)}
      className={clsx('CharonAlert-root', className)}
      icon={false}
      severity={severity}
      elevation={6}
      ref={ref}
      action={<Typography type={severity} size="s1">{action}</Typography>}
      {...rest}
    >
      <Box>
        <Typography
          type={severity}
          css={{
            display: 'flex',
            justifyContent: `${alignCenter ? 'center' : ''}`,
          }}
        >
          {loading ? (
            <Box css={{ minWidth: '20px', maxHeight: '14px', padding: '1px 2px' }}>
              <Image
                width="14"
                height="14"
                className="CharonAlert-spin"
                src={`${LoadingImg}`}
                alt="loading"
              />
            </Box>
          ) : icon === false ? (
            ''
          ) : (
            icon || (
              <Icon
                viewSize={16}
                css={{ height: '22px' }}
                name={StatusIcon[severity] ? StatusIcon[severity] : 'Done16'}
              />
            )
          )}
          <Box
            className="CharonAlert-children"
            css={{ marginLeft: icon === false ? '' : '8px' }}
          >
            {children}
          </Box>
        </Typography>
      </Box>
    </MuiAlert>
  );
}

export default Alert;
