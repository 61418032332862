/*
 * localStorage Store
 */

import store from 'store';
import getConfig from 'next/config';

import {
  collectionVar,
  developerSettingVar,
  globalAutoCompleteVar,
  globalTableViewConfigVar,
} from '~/lib/clientStore';
import type { CollectionType } from '~/__generated__/__types__';
import { IDeveloperSetting } from '~/components/common/DeveloperSetting/type';
import { GlobalAutoComplete } from '~/types/global';
import appRoute from '~/lib/route';
import { AppRouteKey } from '~/lib/route';
import { DEFAULT_BATCH_LIMIT } from '~/constants/batch-api';
import { TableViewConfigItem } from '~/components/blocks/ResourceTable/type';

/* Constants */

const LOCAL = 'local';
const COLLECTION_KEY = 'collection';
const TOP_RESOURCE_VISIBLE = 'top-resource-visible';
const DEVELOPER_SETTING = 'developer-setting'; // 开发者设置
const USER_NAME = 'sddc-user-name';
const USER_ID = 'sddc-user-id';
const NEED_TO_SHOW_EMAIL_ALERT = 'need-to-show-email-alert';
const GLOBAL_AUTOCOMPLETE = 'global-autocomplete'; // 全局的自动填充
const GLOBAL_TABLE_VIEW_CONFIG = 'table-view-config'; // 列表的视图配置
const DASHBOARD_STORAGE_ACTIVE_TAB = 'dashboardStorageActiveTab';
const MONITOR_VIEW_CONFIG_VISIBLE = 'monitor-view-config-visible';
const SECURITY_POLICY_VISITED_KEY = 'securityPolicyVisited';
const USER_SETTINGS = 'user-settings';

const { publicRuntimeConfig } = getConfig() ?? {};

/* Functions */

function getLocalLanguage() {
  return store.get(LOCAL);
}

function setLocalLanguage($value: string) {
  store.set(LOCAL, $value);
}

function initCollection() {
  const init: CollectionType[] = [
    AppRouteKey.ROUTE_V_MACHINES,
    AppRouteKey.ROUTE_IMAGES,
    AppRouteKey.ROUTE_BS_VOLUMES,
    AppRouteKey.ROUTE_V_BRIDGED_NETWORKS,
    AppRouteKey.ROUTE_NODES,
    AppRouteKey.ROUTE_STORAGE_POOL,
    AppRouteKey.ROUTE_ANALYSIS,
  ].map(function (route) {
    return { value: appRoute[route].value };
  });
  store.set(COLLECTION_KEY, init);
}

function getCollection(): CollectionType[] {
  const collection = store.get(COLLECTION_KEY);
  if (collection === undefined) initCollection();
  return (store.get(COLLECTION_KEY) as CollectionType[]) || [];
}

function addCollection(collectionItem: CollectionType) {
  const collection = getCollection().concat(collectionItem);
  store.set(COLLECTION_KEY, collection);
  collectionVar(collection);
}

function removeCollection(collectionItem: CollectionType) {
  const collection = getCollection().filter((a) => a.value !== collectionItem.value);
  store.set(COLLECTION_KEY, collection);
  collectionVar(collection);
}

function setCollection(collection: CollectionType[]) {
  store.set(COLLECTION_KEY, collection);
  collectionVar(collection);
}

function getTopResourceVisible() {
  return store.get(TOP_RESOURCE_VISIBLE) === undefined || !!store.get(TOP_RESOURCE_VISIBLE);
}

function setTopResourceVisible(visible: boolean) {
  store.set(TOP_RESOURCE_VISIBLE, visible);
}

function getUserName() {
  return store.get(USER_NAME) || '';
}

function setUserName(userName: string) {
  store.set(USER_NAME, userName);
}

function getUserId() {
  return store.get(USER_ID);
}

function setUserId(userId: number) {
  store.set(USER_ID, userId);
}

function removeUserId() {
  store.remove(USER_ID);
}

function getEmailSettingAlert() {
  return store.get(NEED_TO_SHOW_EMAIL_ALERT);
}

function setEmailSettingAlert(click: boolean) {
  store.set(NEED_TO_SHOW_EMAIL_ALERT, click);
}

function getDeveloperSetting(): IDeveloperSetting {
  return store.get(DEVELOPER_SETTING, {
    feedbackAvailable:
      publicRuntimeConfig?.FEEDBACK === 'yes' && publicRuntimeConfig?.SENTRY_ENABLE === 'yes',
    avoidPoolFormValidate: false,
    replicateOneSizeAvailable: false,
    batchMaxValue: DEFAULT_BATCH_LIMIT,
  });
}

function setDeveloperSetting(settings: IDeveloperSetting) {
  developerSettingVar(settings);
  return store.set(DEVELOPER_SETTING, settings);
}

function getGlobalAutoComplete(): GlobalAutoComplete {
  return store.get(GLOBAL_AUTOCOMPLETE) || {};
}

function setGlobalAutoComplete(key: keyof GlobalAutoComplete, value: any) {
  const old = globalAutoCompleteVar();

  globalAutoCompleteVar({
    ...old,
    [key]: value,
  });

  store.set(GLOBAL_AUTOCOMPLETE, {
    ...old,
    [key]: value,
  });
}

function getGlobalTableViewConfig() {
  return store.get(GLOBAL_TABLE_VIEW_CONFIG) || {};
}

function setGlobalTableViewConfig(tableViewConfigItems: TableViewConfigItem[], key: string) {
  const old = globalTableViewConfigVar();

  globalTableViewConfigVar({
    ...old,
    [key]: tableViewConfigItems,
  });

  store.set(GLOBAL_TABLE_VIEW_CONFIG, {
    ...old,
    [key]: tableViewConfigItems,
  });
}

function getUserSettings() {
  return store.get(USER_SETTINGS);
}

function setUserSettings(userSettings: Record<string, any>) {
  return store.set(USER_SETTINGS, userSettings);
}
function getDashboardStorageActiveTab() {
  return store.get(DASHBOARD_STORAGE_ACTIVE_TAB) || '';
}

function setDashboardStorageActiveTab(dashboardStorageActiveTab: string) {
  store.set(DASHBOARD_STORAGE_ACTIVE_TAB, dashboardStorageActiveTab);
}

function setLocalMonitorViewConfigVisible(visible: boolean) {
  store.set(MONITOR_VIEW_CONFIG_VISIBLE, visible);
}

function getLocalMonitorViewConfigVisible() {
  return (
    store.get(MONITOR_VIEW_CONFIG_VISIBLE) === undefined || !!store.get(MONITOR_VIEW_CONFIG_VISIBLE)
  );
}

function getSecurityPolicyVisited(): boolean {
  return store.get(SECURITY_POLICY_VISITED_KEY) === 'yes';
}

function setSecurityPolicyVisited() {
  store.set(SECURITY_POLICY_VISITED_KEY, 'yes');
}

export {
  getLocalLanguage,
  setLocalLanguage,
  getCollection,
  addCollection,
  removeCollection,
  setCollection,
  getTopResourceVisible,
  setTopResourceVisible,
  getUserName,
  setUserName,
  getUserId,
  setUserId,
  removeUserId,
  getEmailSettingAlert,
  setEmailSettingAlert,
  getDeveloperSetting,
  setDeveloperSetting,
  getGlobalAutoComplete,
  setGlobalAutoComplete,
  getDashboardStorageActiveTab,
  setDashboardStorageActiveTab,
  getLocalMonitorViewConfigVisible,
  setLocalMonitorViewConfigVisible,
  getSecurityPolicyVisited,
  setSecurityPolicyVisited,
  getGlobalTableViewConfig,
  setGlobalTableViewConfig,
  getUserSettings,
  setUserSettings,
};
