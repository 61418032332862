import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { ApolloError } from '@apollo/client';
import intl from 'react-intl-universal';

import Typography from '~/components/blocks/Typography';
import { getMutationError } from '~/lib/util';
import drawer from '~/lib/drawer';

import { Detail, DetailItemType } from '.';

interface ContentProps {
  text: string;
  detail?: Detail;
}

export function formatContent({ text, detail }: ContentProps) {
  return (
    <div>
      {text}
      {detail && <DetailLink detail={detail} />}
    </div>
  );
}

interface DetailLinkProps {
  detail: Detail;
}

function DetailLink({ detail }: DetailLinkProps) {
  let content: React.ReactNode;
  if (detail.hasOwnProperty('graphQLErrors')) {
    content = getMutationError(detail as ApolloError);
  } else if (detail instanceof Error) {
    content = detail.message;
  } else {
    const arr = Array.isArray(detail) ? detail : [detail];
    content = arr.map((item, index) => <DetailItem key={index} item={item} />);
  }

  const handleClick = useCallback(() => {
    drawer.open({
      content: <Box>{content}</Box>,
      title: intl.get('查看响应详情'),
      anchor: 'right',
    });
  }, [content]);

  return (
    <Typography
      onClick={handleClick}
      isInline
      component="span"
      underline={true}
      css={{ marginLeft: '8px' }}
    >
      {intl.get('查看响应详情')}
    </Typography>
  );
}

interface DetailItemProps {
  item: DetailItemType;
}

interface PromiseRejectedResult {
  status: 'rejected';
  reason: any;
}

function DetailItem({ item }: DetailItemProps) {
  if (item.hasOwnProperty('graphQLErrors')) {
    return <Typography>{getMutationError(item as ApolloError)}</Typography>;
  } else if ((item as PromiseRejectedResult)?.reason?.hasOwnProperty('graphQLErrors')) {
    return (
      <Typography>
        {getMutationError((item as PromiseRejectedResult).reason as ApolloError)}
      </Typography>
    );
  } else {
    return (
      <Typography>
        <pre>{JSON.stringify(item, undefined, 2)}</pre>
      </Typography>
    );
  }
}
