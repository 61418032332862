import React from 'react';

import { CLOSE_DRAWER, GLOBAL_DRAWER } from '~/constants/ps-events';

import ps from './ps';

export interface GlobalDrawerData {
  open: boolean;
  content: string | React.ReactNode;
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  title?: string | React.ReactNode;
  bottomHeight?: string;
  width?: string;
}

interface OpenType extends Omit<GlobalDrawerData, 'open'> {}

function open({ content, title, width = '400px', anchor, bottomHeight }: OpenType) {
  return ps.publish(GLOBAL_DRAWER, {
    open: true,
    content,
    anchor,
    title,
    width,
    bottomHeight,
  });
}

function close() {
  ps.publish(CLOSE_DRAWER);
}

const drawer = {
  open,
  close,
};

export default drawer;
