export const PUSH_DIALOG = Symbol('PUSH_DIALOG');
export const POP_DIALOG = Symbol('POP_DIALOG');
export const CLEAR_DIALOG = Symbol('CLEAR_DIALOG');

export const RES_MUTATION = Symbol('RES_MUTATION');
export const RES_QUERY = Symbol('RES_QUERY');

export const WS_MESSAGE = Symbol('WS_MESSAGE');
export const SDS_WS_MESSAGE = Symbol('SDS_WS_MESSAGE');

export const PREVENT_PAGE_LEAVING = Symbol('PREVENT_PAGE_LEAVING');
export const ALLOW_PAGE_LEAVING = Symbol('ALLOW_PAGE_LEAVING');

export const MESSAGE = Symbol('NOTICE');
export const CLOSE_MESSAGE = Symbol('CLOSE_NOTICE');

export const LOGIN = Symbol('LOGIN');
export const LOGOUT = Symbol('LOGOUT');

export const EMAIL_CONFIG = Symbol('EMAIL_CONFIG');

export const VNC_LOGIN = Symbol('VNC_LOGIN');

export const OPEN_LICENSE = Symbol('OPEN_LICENSE');
export const CLOSE_LICENSE = Symbol('CLOSE_LICENSE');

export const OPEN_COMPONENT_VERSION = Symbol('OPEN_COMPONENT_VERSION');
export const CLOSE_COMPONENT_VERSION = Symbol('CLOSE_COMPONENT_VERSION');

export const GLOBAL_DRAWER = Symbol('GLOBAL_DRAWER');

export const CLOSE_DRAWER = Symbol('CLOSE_DRAWER');

export const GLOBAL_SECURITY_RULE_LIST_REFETCH = Symbol('GLOBAL_SECURITY_RULE_LIST_REFETCH');

export const SECURITY_POLICY_TOPOLOGY = Symbol('SECURITY_POLICY_TOPOLOGY');
export const SECURITY_POLICY_LIST_REFETCH = Symbol('SECURITY_POLICY_LIST_REFETCH');
export const SECURITY_POLICY_LIST_CLEAR_SELECTION = Symbol('SECURITY_POLICY_LIST_CLEAR_SELECTION');

export const NFTABLE_VM_RULE_LIST_REFETCH = Symbol('NFTABLE_VM_RULE_LIST_REFETCH');

export const CLOSE_LAUNCH_BOX = Symbol('CLOSE_LAUNCH_BOX');
export const WORKFLOW = Symbol('WORKFLOW');
