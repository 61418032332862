import lodash from 'lodash';
import { WebsocketBuilder, ConstantBackoff } from 'websocket-ts/lib';
import { Websocket } from 'websocket-ts/lib/websocket';
import { useEffect, useState } from 'react';
import getConfig from 'next/config';

import ps from '~/lib/ps';
import { WS_MESSAGE, LOGIN, LOGOUT } from '~/constants/ps-events';
import { WSData } from '~/lib/resourceObserver';
import { X_SDDC_TOKEN_REGX, TOKEN } from '~/components/forms/lib';

export default function VenusWS(props: { login: boolean }) {
  const { login } = props;
  const { publicRuntimeConfig } = getConfig();

  const [isLogin, setIsLogin] = useState(login);
  const [venusWS, setVenusWS] = useState<Websocket | null>();

  const handleWebsocketOpen = (instance: Websocket, ev: Event) => {
    // TODO ignore logic
  };

  const handleWebsocketClose = (instance: Websocket, ev: Event) => {
    // TODO ignore logic
  };

  const handleWebsocketError = (instance: Websocket, ev: Event) => {
    // TODO ignore logic
  };

  const handleWebsocketRetry = (instance: Websocket, ev: Event) => {
    // TODO ignore logic
  };

  const handleWebsocketMessage = (instance: Websocket, ev: Event) => {
    const data: WSData = JSON.parse(lodash.get(ev, 'data'));

    ps.publish(WS_MESSAGE, data);
  };

  useEffect(() => {
    const loginToken = ps.subscribe(LOGIN, () => {
      setIsLogin(true);
    });
    const logoutToken = ps.subscribe(LOGOUT, () => {
      setIsLogin(false);
    });

    return function cleanup() {
      ps.unsubscribe(loginToken);
      ps.unsubscribe(logoutToken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venusWS]);

  useEffect(() => {
    if (!isLogin && !!venusWS) {
      venusWS.close();
      setVenusWS(null);
      return;
    }
    if (isLogin && !venusWS) {
      const VENUS_WS_HOSTNAME = publicRuntimeConfig.VENUS_WS_HOSTNAME || window.location.hostname;
      const VENUS_WS_PORT = publicRuntimeConfig.VENUS_WS_PORT;
      const VENUS_WS_PATH = publicRuntimeConfig.VENUS_WS_PATH;
      const PROTOCOL = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const [, token] = document?.cookie?.match(X_SDDC_TOKEN_REGX) || [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const url = VENUS_WS_PORT
        ? `${PROTOCOL}://${VENUS_WS_HOSTNAME}:${VENUS_WS_PORT}/${VENUS_WS_PATH}?${TOKEN}=${token}`
        : `${PROTOCOL}://${VENUS_WS_HOSTNAME}/${VENUS_WS_PATH}?${TOKEN}=${token}`;
        
      let ws = new WebsocketBuilder(url)
        .withBackoff(new ConstantBackoff(7000)) // 7s between retries
        .onOpen(handleWebsocketOpen)
        .onClose(handleWebsocketClose)
        .onError(handleWebsocketError)
        .onMessage(handleWebsocketMessage)
        .onRetry(handleWebsocketRetry)
        .build();
      setVenusWS(ws);
    }
  }, [
    venusWS,
    isLogin,
    publicRuntimeConfig.VENUS_WS_HOSTNAME,
    publicRuntimeConfig.VENUS_WS_PATH,
    publicRuntimeConfig.VENUS_WS_PORT,
    publicRuntimeConfig.VENUS_WS_HOSTNAME,
  ]);

  return null;
}
