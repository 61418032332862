import { Box } from '@mui/material';
import React from 'react';
import getConfig from 'next/config';

import { useGetDeveloperSettingQuery } from '~/__generated__/graphql/app/operations/clientStore.graphql';
import { useMounted } from '~/lib/hooks';

import { getFixedToolbarStyle } from './lib';
import FeedbackButton from './Feedback';

const { publicRuntimeConfig } = getConfig();

export default function FixedToolBar() {
  const { data } = useGetDeveloperSettingQuery();

  const hasMounted = useMounted();
  if (!hasMounted) return null;

  return (
    <Box css={getFixedToolbarStyle()}>
      {publicRuntimeConfig.SENTRY_ENABLE === 'yes' && data?.developerSetting?.feedbackAvailable && (
        <FeedbackButton />
      )}
    </Box>
  );
}
