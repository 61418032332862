// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';

import healthCheck from '~/lib/health-check';
import { PlutoIntegration } from '~/lib/sentry/integration';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = publicRuntimeConfig.SENTRY_DSN;
const SENTRY_RELEASE = publicRuntimeConfig.SENTRY_RELEASE;
const SENTRY_ENVIRONMENT = publicRuntimeConfig.SENTRY_ENVIRONMENT;
const NTOS_VERSION = publicRuntimeConfig.NTOS_VERSION;
const SENTRY_ENABLE = publicRuntimeConfig.SENTRY_ENABLE === 'yes';

function eventNeedSend(event) {
  // transaction 为 /login，说明是由于登录过期跳转回登录页
  if (event?.tags?.transaction === '/login') {
    return false;
  }

  return true;
}

Sentry.init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  environment: SENTRY_ENVIRONMENT,
  enabled: SENTRY_ENABLE && !!SENTRY_RELEASE,
  attachStacktrace: true,
  autoSessionTracking: true,
  tracesSampleRate: 0.3,
  integrations: [new PlutoIntegration()],
  beforeSend(event) {
    // 健康检查
    if (healthCheck.getSnapshot() === true) {
      const needSend = eventNeedSend(event);
      if (needSend) {
        return event;
      }
      return null;
    }
    return null;
  },
  // 过滤没有太大参考意义的 issue
  ignoreErrors: ['AbortError: The user aborted a request.', 'ResizeObserver loop limit exceeded'],
});

Sentry.setTag('rrweb.active', 'yes');
Sentry.setTag('NTOS.version', NTOS_VERSION);
