/*
 * App Theme
 */

import React from 'react';
import { createTheme } from '@mui/material/styles';
import type { ThemeOptions } from '@mui/material/styles';
import { outlinedInputClasses, OutlinedInputProps } from '@mui/material/OutlinedInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { enUS, zhCN } from '@mui/material/locale';
import { inputClasses } from '@mui/material/Input';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { chipClasses } from '@mui/material/Chip';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import lodash from 'lodash';

import type { GetThemeProps } from '~/types/global';
import themeCSSObj from '~/styles/theme.module.scss';
import paletteCSSObj from '~/styles/palette.module.scss';
import {
  getErrorTextColor,
  getInfoTextColor,
  getSuccessTextColor,
  getWarningTextColor,
  getWhiteColorExtension,
} from '~/lib/color';
import { getAssociatedColor } from '~/lib/colorCalculation';

const spacing = lodash.memoize(function (n: number) {
  const base = 8;
  return `${base * n}px`;
});

/*
 * Our theme is based on MUI, with some extentions.
 *
 * If an MUI component has been encapsulated by our app,
 * the styles should be moved into that app component (not in the theme).
 */
function getTheme(props: GetThemeProps) {
  const fontVariantNumeric = 'tabular-nums';

  const themeObj: ThemeOptions = {
    spacing: function (factor: number) {
      return spacing(factor);
    },
    palette: {
      common: {
        black: paletteCSSObj.black,
        white: paletteCSSObj.white,
      },
      primary: {
        main: paletteCSSObj.purple6,
        contrastText: paletteCSSObj.white,
      },
      success: {
        main: themeCSSObj.appSuccessMain,
        contrastText: paletteCSSObj.white,
      },
      warning: {
        main: themeCSSObj.appWarningMain,
        contrastText: paletteCSSObj.white,
      },
      error: {
        main: themeCSSObj.appErrorMain,
        contrastText: paletteCSSObj.white,
      },
      info: {
        main: themeCSSObj.appInfoMain,
        contrastText: paletteCSSObj.white,
      },
      text: {
        primary: paletteCSSObj.grey8,
        secondary: paletteCSSObj.grey7,
      },
      divider: getWhiteColorExtension(0.2),
      background: {
        paper: paletteCSSObj.white,
        default: paletteCSSObj.grey2,
      },
      action: {
        active: themeCSSObj.paletteActionActive,
        hover: themeCSSObj.paletteActionHover,
        hoverOpacity: +themeCSSObj.paletteActionHoverOpacity,
        selected: themeCSSObj.paletteActionSelected,
        selectedOpacity: +themeCSSObj.paletteActionSelectedOpacity,
        disabled: '',
        disabledBackground: '',
        disabledOpacity: +themeCSSObj.paletteActionDisabledOpacity,
        focus: themeCSSObj.paletteActionFocus,
        focusOpacity: +themeCSSObj.paletteActionFocusOpacity,
        activatedOpacity: +themeCSSObj.paletteActionActivatedOpacity,
      },
    },
    shadows: [
      themeCSSObj.shadows0 as 'none',
      themeCSSObj.shadows1,
      themeCSSObj.shadows2,
      themeCSSObj.shadows3,
      themeCSSObj.shadows4,
      themeCSSObj.shadows5,
      themeCSSObj.shadows6,
      themeCSSObj.shadows7,
      themeCSSObj.shadows8,
      themeCSSObj.shadows9,
      themeCSSObj.shadows10,
      themeCSSObj.shadows11,
      themeCSSObj.shadows12,
      themeCSSObj.shadows13,
      themeCSSObj.shadows14,
      themeCSSObj.shadows15,
      themeCSSObj.shadows16,
      themeCSSObj.shadows17,
      themeCSSObj.shadows18,
      themeCSSObj.shadows19,
      themeCSSObj.shadows20,
      themeCSSObj.shadows21,
      themeCSSObj.shadows22,
      themeCSSObj.shadows23,
      themeCSSObj.shadows24,
    ],
    typography: {
      fontFamily: themeCSSObj.appFontFamily,
      htmlFontSize: +themeCSSObj.typographyHtmlFontSize,
      fontSize: +themeCSSObj.typographyFontSize,
      fontWeightLight: +themeCSSObj.typographyFontWeightLight,
      fontWeightRegular: +themeCSSObj.typographyFontWeightRegular,
      fontWeightMedium: +themeCSSObj.typographyFontWeightMedium,
      fontWeightBold: +themeCSSObj.typographyFontWeightBold,
      h3: {
        fontWeight: +themeCSSObj.typographyH3FontWeight,
        fontSize: themeCSSObj.typographyH3FontSize,
        lineHeight: themeCSSObj.typographyH3LineHeight,
      },
      h4: {
        fontWeight: +themeCSSObj.typographyH4FontWeight,
        fontSize: themeCSSObj.typographyH4FontSize,
        lineHeight: themeCSSObj.typographyH4LineHeight,
      },
      h5: {
        fontWeight: +themeCSSObj.typographyH5FontWeight,
        fontSize: themeCSSObj.typographyH5FontSize,
        lineHeight: themeCSSObj.typographyH5LineHeight,
      },
      h6: {
        fontWeight: +themeCSSObj.typographyH6FontWeight,
        fontSize: themeCSSObj.typographyH6FontSize,
        lineHeight: themeCSSObj.typographyH6LineHeight,
      },
      subtitle1: {
        fontWeight: +themeCSSObj.typographySubtitle1FontWeight,
        fontSize: themeCSSObj.typographySubtitle1FontSize,
        lineHeight: themeCSSObj.typographySubtitle1LineHeight,
      },
      subtitle2: {
        fontWeight: +themeCSSObj.typographySubtitle2FontWeight,
        fontSize: themeCSSObj.typographySubtitle2FontSize,
        lineHeight: themeCSSObj.typographySubtitle2LineHeight,
      },
      button: {
        textTransform:
          themeCSSObj.typographyButtonTextTransform as React.CSSProperties['textTransform'],
      },
    },
    shape: {
      borderRadius: +themeCSSObj.shapeBorderRadius,
    },
    zIndex: {
      mobileStepper: +themeCSSObj.zIndexMobileStepper,
      speedDial: +themeCSSObj.zIndexSpeedDial,
      appBar: +themeCSSObj.zIndexAppBar,
      drawer: +themeCSSObj.zIndexDrawer,
      modal: +themeCSSObj.zIndexModal,
      snackbar: +themeCSSObj.zIndexSnackbar,
      tooltip: +themeCSSObj.zIndexTooltip,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
            backgroundColor: paletteCSSObj.grey2,
          },
          body: {
            height: '100%',
            backgroundColor: paletteCSSObj.grey2,
            fontFamily: themeCSSObj.appFontFamily,
            fontVariantNumeric,
          },
          '#__next': {
            height: '100%',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { disabled: true },
            style: {
              opacity: 0.4,
            },
          },
        ],
      },
      MuiAlert: {
        variants: [
          {
            props: {
              variant: 'filled',
            },
            style: {
              '& .MuiAlert-icon': {
                color: paletteCSSObj.white,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            minHeight: 36,
            borderRadius: themeCSSObj.appShapeBorderRadius2,
            padding: '9px 16px',
            alignItems: 'center',
            boxShadow: 'none',
            '& .MuiPaper-root': {
              alignItems: 'flex-start',
            },
          },
          message: {
            padding: 0,
            width: '100%',
          },
          icon: {
            padding: 0,
            paddingTop: '1px',
          },
          action: {
            padding: 0,
            wordBreak: 'keep-all',
            button: {
              padding: 0,
            },
          },
          outlinedSuccess: {
            background: getAssociatedColor(themeCSSObj.appSuccessMain)[0],
            border: `1px solid ${themeCSSObj.appSuccessMain}`,
            color: getSuccessTextColor(),
          },
          standardSuccess: {
            background: getAssociatedColor(themeCSSObj.appSuccessMain)[0],
          },
          outlinedInfo: {
            background: getAssociatedColor(themeCSSObj.appInfoMain)[0],
            border: '1px solid',
            borderColor: 'rgba(68,138,255, 0.4)',
            color: getInfoTextColor(),
            '& .MuiAlert-icon': {
              color: getInfoTextColor(),
            },
          },
          standardInfo: {
            background: getAssociatedColor(themeCSSObj.appInfoMain)[0],
            '& .MuiAlert-icon': {
              color: getInfoTextColor(),
            },
          },
          outlinedWarning: {
            background: getAssociatedColor(themeCSSObj.appWarningMain)[0],
            border: '1px solid',
            borderColor: 'rgba(215,150,60, 0.4)',
            color: getWarningTextColor(),
          },
          standardWarning: {
            background: getAssociatedColor(themeCSSObj.appWarningMain)[0],
          },
          outlinedError: {
            background: getAssociatedColor(themeCSSObj.appErrorMain)[0],
            border: '1px solid',
            borderColor: 'rgba(235,85,85, 0.4)',
            color: getErrorTextColor(),
          },
          standardError: {
            background: getAssociatedColor(themeCSSObj.appErrorMain)[0],
          },
          filledError: {
            '& .MuiTypography-root': {
              color: paletteCSSObj.white,
            },
          },
          filledInfo: {
            '& .MuiTypography-root': {
              color: paletteCSSObj.white,
            },
          },
          filledSuccess: {
            '& .MuiTypography-root': {
              color: paletteCSSObj.white,
            },
          },
          filledWarning: {
            '& .MuiTypography-root': {
              color: paletteCSSObj.white,
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 24px 16px',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
            fontSize: themeCSSObj.typographyH6FontSize,
            color: paletteCSSObj.grey9,
            '&.Mui-expanded': {
              minHeight: '50px',
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '16px',
          },
          fontSizeSmall: {
            fontSize: '12px',
          },
          fontSizeLarge: {
            fontSize: '20px',
          },
        },
      },
      MuiCheckbox: {
        variants: [
          {
            props: {
              disabled: true,
            },
            style: {
              backgroundColor: paletteCSSObj.grey1,
            },
          },
        ],
        styleOverrides: {
          root: {
            padding: 0,
            color: paletteCSSObj.grey8,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            zIndex: +themeCSSObj.zIndexModal,
          },
          paper: {
            background: themeCSSObj.appFontColor4,
            boxShadow: themeCSSObj.shadows5,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: {
              size: 'small',
            },
            style: {
              fontSize: '12px',
              padding: '2px 7px',
            },
          },
          {
            props: {
              size: 'large',
            },
            style: {
              fontSize: '14px',
              padding: '5px 23px',
            },
          },
          {
            props: {
              color: 'primary',
            },
            style: {
              '&:active': {
                boxShadow: '0px 0px 0px 2px rgba(72, 31, 224, 0.24)',
              },
            },
          },
          {
            props: {
              color: 'error',
            },
            style: {
              '&:active': {
                boxShadow: '0px 0px 0px 2px rgba(230,80,80, 0.24)',
              },
            },
          },
          {
            props: {
              color: 'warning',
            },
            style: {
              '&:active': {
                boxShadow: '0px 0px 0px 2px rgba(255,178,11, 0.24)',
              },
            },
          },
          {
            props: {
              color: 'info',
            },
            style: {
              '&:active': {
                boxShadow: '0px 0px 0px 2px rgba(68,138,255, 0.24)',
              },
            },
          },
          {
            props: {
              color: 'success',
            },
            style: {
              '&:active': {
                boxShadow: '0px 0px 0px 2px rgba(20,165,90, 0.24)',
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            boxShadow: 'none',
            padding: '5px 15px',
            borderRadius: themeCSSObj.appShapeBorderRadius1,
            '&:hover': {
              boxShadow: 'none',
            },
            '&.MuiButton-containedSuccess': {
              border: `1px solid ${themeCSSObj.appSuccessMain}`,
              '&:hover': {
                background: getAssociatedColor(themeCSSObj.appSuccessMain)[6],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appSuccessMain)[6]}`,
              },
              '&:active': {
                background: getAssociatedColor(themeCSSObj.appSuccessMain)[7],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appSuccessMain)[7]}`,
              },
            },
            '&.MuiButton-containedWarning': {
              border: `1px solid ${themeCSSObj.appWarningMain}`,
              '&:hover': {
                background: getAssociatedColor(themeCSSObj.appWarningMain)[6],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appWarningMain)[6]}`,
              },
              '&:active': {
                background: getAssociatedColor(themeCSSObj.appWarningMain)[7],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appWarningMain)[7]}`,
              },
            },
            '&.MuiButton-containedError': {
              border: `1px solid ${themeCSSObj.appErrorMain}`,
              '&:hover': {
                background: getAssociatedColor(themeCSSObj.appErrorMain)[6],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appErrorMain)[6]}`,
              },
              '&:active': {
                background: getAssociatedColor(themeCSSObj.appErrorMain)[7],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appErrorMain)[7]}`,
              },
            },
            '&.MuiButton-containedInfo': {
              border: `1px solid ${themeCSSObj.appInfoMain}`,
              '&:hover': {
                background: getAssociatedColor(themeCSSObj.appInfoMain)[6],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appInfoMain)[6]}`,
              },
              '&:active': {
                background: getAssociatedColor(themeCSSObj.appInfoMain)[7],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appInfoMain)[7]}`,
              },
            },
            '&.MuiButton-outlinedError': {
              border: `1px solid ${themeCSSObj.appErrorMain}`,
              color: themeCSSObj.appErrorMain,
              '& .MuiSvgIcon-root': {
                color: themeCSSObj.appErrorMain,
              },
              '&:hover': {
                color: getAssociatedColor(themeCSSObj.appErrorMain)[6],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appErrorMain)[6]}`,
                '& .MuiSvgIcon-root': {
                  color: getAssociatedColor(themeCSSObj.appErrorMain)[6],
                },
              },
              '&:active': {
                color: getAssociatedColor(themeCSSObj.appErrorMain)[7],
                border: `1px solid ${getAssociatedColor(themeCSSObj.appErrorMain)[7]}`,
                '& .MuiSvgIcon-root': {
                  color: getAssociatedColor(themeCSSObj.appErrorMain)[7],
                },
              },
            },
          },
          containedPrimary: {
            border: `1px solid ${paletteCSSObj.purple6}`,
            '&:hover': {
              background: getAssociatedColor(themeCSSObj.appPrimaryColor)[6],
              border: `1px solid ${getAssociatedColor(themeCSSObj.appPrimaryColor)[6]}`,
            },
            '&:active': {
              background: getAssociatedColor(themeCSSObj.appPrimaryColor)[7],
              border: `1px solid ${getAssociatedColor(themeCSSObj.appPrimaryColor)[7]}`,
            },
          },
          outlined: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          outlinedPrimary: {
            border: `1px solid ${paletteCSSObj.grey4}`,
            color: paletteCSSObj.grey9,
            '& .MuiSvgIcon-root': {
              color: paletteCSSObj.grey8,
            },
            '&.Mui-disabled': {
              border: `1px solid ${paletteCSSObj.grey3}`,
            },
            '&:hover': {
              color: getAssociatedColor(themeCSSObj.appPrimaryColor)[6],
              border: `1px solid ${getAssociatedColor(themeCSSObj.appPrimaryColor)[6]}`,
              '& .MuiSvgIcon-root': {
                color: getAssociatedColor(themeCSSObj.appPrimaryColor)[6],
              },
            },
            '&:active': {
              color: getAssociatedColor(themeCSSObj.appPrimaryColor)[7],
              border: `1px solid ${getAssociatedColor(themeCSSObj.appPrimaryColor)[7]}`,
              '& .MuiSvgIcon-root': {
                color: getAssociatedColor(themeCSSObj.appPrimaryColor)[7],
              },
            },
          },
          outlinedSecondary: {
            color: paletteCSSObj.grey9,
            border: `1px solid ${paletteCSSObj.grey3}`,
            '&:hover': {
              color: paletteCSSObj.grey9,
              border: `1px solid ${paletteCSSObj.grey9}`,
            },
            '&:active': {
              color: paletteCSSObj.black,
            },
          },
          text: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              boxShadow: 'none !important',
            },
          },
          textPrimary: {
            border: '1px solid transparent',
            color: themeCSSObj.appPrimaryColor,
            '&:hover': {
              color: getAssociatedColor(themeCSSObj.appPrimaryColor)[6],
              border: `1px solid ${paletteCSSObj.grey4}`,
              backgroundColor: `${paletteCSSObj.grey4} !important`,
            },
            '&:active': {
              color: getAssociatedColor(themeCSSObj.appPrimaryColor)[7],
              border: `1px solid ${paletteCSSObj.grey5}`,
              backgroundColor: `${paletteCSSObj.grey5} !important`,
            },
          },
          textSecondary: {
            color: paletteCSSObj.grey9,
            border: '1px solid transparent',
            '&:hover': {
              color: paletteCSSObj.grey11,
              border: `1px solid ${paletteCSSObj.grey2}`,
              backgroundColor: paletteCSSObj.grey2,
            },
            '&:active': {
              border: `1px solid ${paletteCSSObj.grey3}`,
              backgroundColor: paletteCSSObj.grey3,
            },
            '&.Mui-disabled': {
              color: paletteCSSObj.grey9,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: themeCSSObj.shadows7,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            marginRight: '4px',
            padding: 0,
            fontSize: '4px',
            color: paletteCSSObj.grey8,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiSvgIcon-fontSizeMedium': {
              fontSize: '15px',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '34px',
            height: '18px',
            borderRadius: '9px',
            padding: 0,
          },
          thumb: {
            color: paletteCSSObj.white,
            width: '14px',
            height: '14px',
            boxShadow: themeCSSObj.shadows7,
          },
          track: {
            opacity: 1,
            backgroundColor: themeCSSObj.appIconColor1,
          },
          switchBase: {
            padding: 0,
            margin: '2px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              '& + .MuiSwitch-track': {
                opacity: 1,
              },
            },
            '&.Mui-disabled': {
              opacity: 0.8,
              '& + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
          },
          sizeSmall: {
            '&.MuiSwitch-root': {
              width: '30px',
              height: '16px',
            },
            '& .MuiSwitch-switchBase': {
              padding: 0,
              '&.Mui-checked': {
                transform: 'translateX(14px)',
              },
            },
            '& .MuiSwitch-thumb': {
              width: '12px',
              height: '12px',
            },
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            '& .MuiStepContent-root': {
              borderLeftWidth: '2px',
              borderColor: paletteCSSObj.grey3,
              marginLeft: '13px',
            },
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            marginLeft: '13px',
            '& .MuiStepConnector-line': {
              borderLeftWidth: '2px',
              minHeight: '20px',
            },
            '&.Mui-completed, &.Mui-active': {
              '& .MuiStepConnector-line': {
                borderColor: paletteCSSObj.purple6,
              },
            },
            '&.Mui-disabled': {
              '& .MuiStepConnector-line': {
                borderColor: paletteCSSObj.grey3,
              },
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            padding: 0,
            '& .MuiStepLabel-iconContainer': {
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
                '&.Mui-active, &.Mui-completed': {
                  color: paletteCSSObj.purple6,
                  '& .MuiStepIcon-text': {
                    fill: paletteCSSObj.white,
                  },
                },
                '& .MuiStepIcon-text': {
                  fontSize: '14px',
                  fill: paletteCSSObj.grey7,
                },
              },
              '&.Mui-disabled': {
                '& .MuiStepIcon-root': {
                  color: paletteCSSObj.grey3,
                },
              },
            },
            '& .MuiStepLabel-labelContainer': {
              marginLeft: '8px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        variants: [
          {
            props: {
              size: 'large',
            },
            style: {},
          },
        ],
        styleOverrides: {
          notchedOutline: {
            borderColor: paletteCSSObj.grey4,
            transition: 'all .2s',
          },
          sizeSmall: {
            [`& .${outlinedInputClasses['input']}`]: {
              padding: '4px 8px',
            },
          },
          adornedStart: {
            paddingLeft: '8px',
            [`& .${inputAdornmentClasses['root']}`]: {
              marginRight: 0,
            },
          },
          root: {
            borderRadius: themeCSSObj.appShapeBorderRadius1,
            fontSize: themeCSSObj.typographySubtitle1FontSize,
            lineHeight: themeCSSObj.typographySubtitle1LineHeight,
            background: paletteCSSObj.white,
            [`&.${outlinedInputClasses['error']}`]: {
              borderColor: themeCSSObj.appErrorMain,
            },
            [`&.${outlinedInputClasses['error']}:hover`]: {
              [`& .${outlinedInputClasses['notchedOutline']}`]: {
                borderWidth: '1px',
                borderColor: themeCSSObj.appErrorMain,
              },
            },
            [`&:hover .${outlinedInputClasses['notchedOutline']}`]: {
              borderColor: paletteCSSObj.purple6,
              borderWidth: '1px',
            },
            [`&.${outlinedInputClasses['focused']}`]: {
              [`& .${outlinedInputClasses['notchedOutline']}`]: {
                borderWidth: '1px',
                boxShadow: themeCSSObj.activeShadow,
              },
              [`&.${outlinedInputClasses['error']}`]: {
                [`& .${outlinedInputClasses['notchedOutline']}`]: {
                  boxShadow: 'none',
                },
              },
            },
            [`&.${outlinedInputClasses['disabled']}`]: {
              background: paletteCSSObj.grey1,
              [`& .${outlinedInputClasses['notchedOutline']}`]: {
                borderColor: paletteCSSObj.grey3,
              },
              [`&.${outlinedInputClasses['focused']}`]: {
                boxShadow: 'none',
              },
            },
            [`&.${outlinedInputClasses['disabled']}:hover`]: {
              [`& .${outlinedInputClasses['notchedOutline']}`]: {
                borderColor: paletteCSSObj.grey3,
              },
            },
          },
          input: ({ ownerState }: { ownerState: OutlinedInputProps }) => ({
            padding: '6px 8px',
            ...(ownerState.size === 'large' && {
              padding: '9px 8px',
            }),
            ':disabled': {
              cursor: 'not-allowed',
            },
          }),
          adornedEnd: {
            paddingRight: '8px',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses['input']}`]: {
              [`&.${autocompleteClasses['input']}`]: {
                padding: 0,
              },
            },
            [`& .${inputClasses['root']}`]: {
              [`& .${inputClasses['input']}`]: {
                padding: 0,
              },
            },
            [`&.${autocompleteClasses['hasPopupIcon']}`]: {},
            [`& .${outlinedInputClasses['root']}`]: {
              [`& .${autocompleteClasses['endAdornment']}`]: {
                right: '8px',
              },
            },
          },
          input: {
            padding: 0,
          },
          listbox: {
            maxHeight: 'none',
            [`& .${autocompleteClasses['option']}`]: {
              '&:focus': {
                background: themeCSSObj.appInteractiveColor3,
              },
              [`&.${autocompleteClasses['focused']}`]: {
                background: themeCSSObj.appInteractiveColor1,
                '&[aria-selected="true"]': {
                  background: themeCSSObj.appInteractiveColor2,
                },
              },
              '&[aria-selected="true"]': {
                background: themeCSSObj.appInteractiveColor3,
              },
              '&[aria-selected="false"]:hover': {
                background: themeCSSObj.appInteractiveColor1,
              },
              '&[aria-disabled="true"]': {
                'pointer-events': 'unset',
              },
              '&:active': {
                background: themeCSSObj.appInteractiveColor2,
              },
            },
          },
          inputRoot: ({ ownerState }: { ownerState: any }) => {
            return {
              fontSize: themeCSSObj.typographySubtitle1FontSize,
              lineHeight: themeCSSObj.typographySubtitle1LineHeight,
              height: 'auto',
              padding: '6px 8px',
              gap: '4px',
              ...(ownerState.size === 'large' && {
                padding: '9px 8px',
              }),
              [`&.${outlinedInputClasses['sizeSmall']}`]: {
                padding: '4px 8px',
                [`& .${autocompleteClasses['input']}`]: {
                  padding: 0,
                },
              },
            };
          },
          tag: {
            margin: 0,
          },
          endAdornment: {
            top: 'calc(50% - 8px)',
            display: 'flex',
            width: '16px',
            [`& .${autocompleteClasses['popupIndicator']}`]: {
              padding: 0,
            },
          },
          clearIndicator: {
            color: themeCSSObj.appIconColor1,
            position: 'absolute',
            right: '-4px',
            top: 'calc(50% - 12px)',
            opacity: 0,
            zIndex: '1',
          },
          hasClearIcon: {
            '&:hover': {
              [`& .${autocompleteClasses['popupIndicator']}`]: {
                opacity: 0,
              },
              [`& .${autocompleteClasses['clearIndicator']}`]: {
                opacity: 1,
              },
            },
          },
          hasPopupIcon: {
            [`&.${autocompleteClasses['hasClearIcon']}`]: {
              [`& .${outlinedInputClasses['root']}`]: {
                paddingRight: '32px',
              },
            },
            [`& .${outlinedInputClasses['root']}`]: {
              paddingRight: '32px',
            },
            [`& .${autocompleteClasses['inputRoot']}`]: {
              paddingRight: '20px!important',
            },
            [`& .${outlinedInputClasses['root']}`]: {
              [`&.${autocompleteClasses['inputRoot']}`]: {
                paddingRight: '28px!important',
              },
            },
          },
          noOptions: {
            padding: 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: themeCSSObj.appFontColor3,
              opacity: 1,
            },
            fontSize: themeCSSObj.typographySubtitle1FontSize,
            lineHeight: themeCSSObj.typographySubtitle1LineHeight,
            height: 'auto',
            padding: 0,
          },
        },
      },
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            [`& .${inputClasses['input']}`]: {
              '&::placeholder': {
                fontSize: themeCSSObj.typographySubtitle1FontSize,
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: themeCSSObj.appShapeBorderRadius1,
            background: themeCSSObj.appFillColor2,
            padding: '2px 0',
            height: 'auto',
            color: themeCSSObj.appFontColor2,
          },
          sizeSmall: {
            padding: '2px 0',
            [`& .${chipClasses['label']}`]: {
              lineHeight: themeCSSObj.typographySubtitle2LineHeight,
              fontSize: themeCSSObj.typographySubtitle2FontSize,
            },
          },
          label: {
            padding: '0 8px',
            lineHeight: themeCSSObj.typographySubtitle1LineHeight,
            fontSize: themeCSSObj.typographySubtitle1FontSize,
          },
          deleteIcon: {
            margin: '0 8px 0 -4px',
            color: themeCSSObj.appIconColor2,
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            [`& .${toggleButtonClasses['root']}`]: {
              background: themeCSSObj.grey1,
              transition: 'all .2s',
            },
            [`& .${toggleButtonClasses['root']}.${toggleButtonClasses['selected']}`]: {
              color: themeCSSObj.palettePrimaryMain,
              background: themeCSSObj.paletteCommonWhite,
            },
            [`& .${toggleButtonClasses['root']}:hover`]: {
              color: themeCSSObj.palettePrimaryMain,
              background: themeCSSObj.grey2,
            },
            [`& .${toggleButtonClasses['root']}:active`]: {
              background: themeCSSObj.grey3,
            },
            [`& .${toggleButtonClasses['root']}.${toggleButtonClasses['selected']}:hover`]: {
              color: themeCSSObj.palettePrimaryMain,
              background: themeCSSObj.paletteCommonWhite,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: themeCSSObj.grey9,
            border: 0,
            borderRadius: themeCSSObj.appShapeBorderRadius1,
            padding: `${spacing(0.5)} ${spacing(1)}`,
            background: themeCSSObj.appFillColor3,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: themeCSSObj.appShapeBorderRadius1,
            backgroundColor: themeCSSObj.grey10,
            padding: spacing(1),
            fontSize: themeCSSObj.typographySubtitle1FontSize,
            fontWeight: +themeCSSObj.typographySubtitle1FontWeight,
            lineHeight: themeCSSObj.typographySubtitle1LineHeight,
            boxShadow: themeCSSObj.shadows3,
          },
          arrow: {
            color: themeCSSObj.grey10,
          },
          popper: {
            '&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
              marginBottom: spacing(1.5),
            },
            '&[data-popper-placement*="left"] .MuiTooltip-tooltip': {
              marginRight: spacing(1.5),
            },
            '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
              marginLeft: spacing(1.5),
            },
            '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
              marginTop: spacing(1.5),
            },
          },
        },
      },
    },
  };

  const locales = { enUS, zhCN };

  return createTheme(themeObj, locales[props && props.locale]);
}

export { getTheme };
