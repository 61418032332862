/* 35fcb2d80e548e760c30eaf2d52795c986c0b345
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UserVerifyPasswordMutationVariables = Types.Exact<{
  input?: Types.Maybe<Types.PasswordVerifyReqInput>;
}>;


export type UserVerifyPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'userVerifyPassword'>
);

export type UserUpdateUiProfileMutationVariables = Types.Exact<{
  id: Types.Scalars['BigInt'];
  input?: Types.Maybe<Types.UiProfileUpdateReqInput>;
}>;


export type UserUpdateUiProfileMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateUiProfile?: Types.Maybe<(
    { __typename?: 'UiProfile' }
    & { data: (
      { __typename?: 'UiProfileData' }
      & Pick<Types.UiProfileData, 'createdAt' | 'creationFinish' | 'deletionBegin' | 'deletionFinish' | 'id' | 'monitorViewsDefault' | 'monitorViewsOrder' | 'settings' | 'name' | 'updatedAt' | 'userId'>
    ) }
  )> }
);

export type UserUpdateOneMutationVariables = Types.Exact<{
  id: Types.Scalars['BigInt'];
  input?: Types.Maybe<Types.UserUpdateReqInput>;
}>;


export type UserUpdateOneMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateOne?: Types.Maybe<(
    { __typename?: 'User' }
    & { data: (
      { __typename?: 'UserData' }
      & Pick<Types.UserData, 'createdAt' | 'id' | 'name' | 'email' | 'updatedAt'>
    ) }
  )> }
);

export type GetUserInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['BigInt'];
}>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { user?: Types.Maybe<(
    { __typename?: 'User' }
    & { data: (
      { __typename?: 'UserData' }
      & Pick<Types.UserData, 'id' | 'displayName' | 'email' | 'lastLogin' | 'passwordLastUpdate'>
    ) }
  )> }
);

export type GetUserUiProfileQueryVariables = Types.Exact<{
  id: Types.Scalars['BigInt'];
}>;


export type GetUserUiProfileQuery = (
  { __typename?: 'Query' }
  & { uiProfile?: Types.Maybe<(
    { __typename?: 'UiProfile' }
    & { data: (
      { __typename?: 'UiProfileData' }
      & Pick<Types.UiProfileData, 'createdAt' | 'creationFinish' | 'deletionBegin' | 'deletionFinish' | 'id' | 'monitorViewsDefault' | 'monitorViewsOrder' | 'settings' | 'name' | 'updatedAt' | 'userId'>
    ) }
  )> }
);


export const UserVerifyPasswordDocument = gql`
    mutation UserVerifyPassword($input: PasswordVerifyReqInput) {
  userVerifyPassword(passwordVerifyReqInput: $input)
}
    `;
export type UserVerifyPasswordMutationFn = Apollo.MutationFunction<UserVerifyPasswordMutation, UserVerifyPasswordMutationVariables>;

/**
 * __useUserVerifyPasswordMutation__
 *
 * To run a mutation, you first call `useUserVerifyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserVerifyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userVerifyPasswordMutation, { data, loading, error }] = useUserVerifyPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserVerifyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserVerifyPasswordMutation, UserVerifyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserVerifyPasswordMutation, UserVerifyPasswordMutationVariables>(UserVerifyPasswordDocument, options);
      }
export type UserVerifyPasswordMutationHookResult = ReturnType<typeof useUserVerifyPasswordMutation>;
export type UserVerifyPasswordMutationResult = Apollo.MutationResult<UserVerifyPasswordMutation>;
export type UserVerifyPasswordMutationOptions = Apollo.BaseMutationOptions<UserVerifyPasswordMutation, UserVerifyPasswordMutationVariables>;
export const UserUpdateUiProfileDocument = gql`
    mutation UserUpdateUiProfile($id: BigInt!, $input: UiProfileUpdateReqInput) {
  userUpdateUiProfile(id: $id, uiProfileUpdateReqInput: $input) {
    data {
      createdAt
      creationFinish
      deletionBegin
      deletionFinish
      id
      monitorViewsDefault
      monitorViewsOrder
      settings
      name
      updatedAt
      userId
    }
  }
}
    `;
export type UserUpdateUiProfileMutationFn = Apollo.MutationFunction<UserUpdateUiProfileMutation, UserUpdateUiProfileMutationVariables>;

/**
 * __useUserUpdateUiProfileMutation__
 *
 * To run a mutation, you first call `useUserUpdateUiProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateUiProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateUiProfileMutation, { data, loading, error }] = useUserUpdateUiProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateUiProfileMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateUiProfileMutation, UserUpdateUiProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateUiProfileMutation, UserUpdateUiProfileMutationVariables>(UserUpdateUiProfileDocument, options);
      }
export type UserUpdateUiProfileMutationHookResult = ReturnType<typeof useUserUpdateUiProfileMutation>;
export type UserUpdateUiProfileMutationResult = Apollo.MutationResult<UserUpdateUiProfileMutation>;
export type UserUpdateUiProfileMutationOptions = Apollo.BaseMutationOptions<UserUpdateUiProfileMutation, UserUpdateUiProfileMutationVariables>;
export const UserUpdateOneDocument = gql`
    mutation UserUpdateOne($id: BigInt!, $input: UserUpdateReqInput) {
  userUpdateOne(id: $id, userUpdateReqInput: $input) {
    data {
      createdAt
      id
      name
      email
      updatedAt
    }
  }
}
    `;
export type UserUpdateOneMutationFn = Apollo.MutationFunction<UserUpdateOneMutation, UserUpdateOneMutationVariables>;

/**
 * __useUserUpdateOneMutation__
 *
 * To run a mutation, you first call `useUserUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateOneMutation, { data, loading, error }] = useUserUpdateOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateOneMutation, UserUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateOneMutation, UserUpdateOneMutationVariables>(UserUpdateOneDocument, options);
      }
export type UserUpdateOneMutationHookResult = ReturnType<typeof useUserUpdateOneMutation>;
export type UserUpdateOneMutationResult = Apollo.MutationResult<UserUpdateOneMutation>;
export type UserUpdateOneMutationOptions = Apollo.BaseMutationOptions<UserUpdateOneMutation, UserUpdateOneMutationVariables>;
export const GetUserInfoDocument = gql`
    query GetUserInfo($id: BigInt!) {
  user(id: $id) {
    data {
      id
      displayName
      email
      lastLogin
      passwordLastUpdate
    }
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUserUiProfileDocument = gql`
    query GetUserUiProfile($id: BigInt!) {
  uiProfile(id: $id) {
    data {
      createdAt
      creationFinish
      deletionBegin
      deletionFinish
      id
      monitorViewsDefault
      monitorViewsOrder
      settings
      name
      updatedAt
      userId
    }
  }
}
    `;

/**
 * __useGetUserUiProfileQuery__
 *
 * To run a query within a React component, call `useGetUserUiProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserUiProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserUiProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserUiProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserUiProfileQuery, GetUserUiProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserUiProfileQuery, GetUserUiProfileQueryVariables>(GetUserUiProfileDocument, options);
      }
export function useGetUserUiProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserUiProfileQuery, GetUserUiProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserUiProfileQuery, GetUserUiProfileQueryVariables>(GetUserUiProfileDocument, options);
        }
export type GetUserUiProfileQueryHookResult = ReturnType<typeof useGetUserUiProfileQuery>;
export type GetUserUiProfileLazyQueryHookResult = ReturnType<typeof useGetUserUiProfileLazyQuery>;
export type GetUserUiProfileQueryResult = Apollo.QueryResult<GetUserUiProfileQuery, GetUserUiProfileQueryVariables>;