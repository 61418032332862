// Owner: Anqi Shu
// Link: https://www.figma.com/file/ue3opXLbohGZ3XMd2fXJPC/ICON%E5%8F%AF%E6%93%8D%E4%BD%9C%E8%A7%84%E8%8C%83%E4%BC%98%E5%8C%96?node-id=355%3A26201&t=LZuSJMhbQf2sVaOg-1

import React, { forwardRef, useMemo } from 'react';
import { Button as MuiButton, Box } from '@mui/material';
import * as icons from 'pluto-icon';
import clsx from 'clsx';

import Icon from '~/charon/Icon';
import Tooltip from '~/components/ui/Tooltip';
import { useMounted } from '~/lib/hooks';

import { IconButtonProps } from './type';
import {
  ButtonClasses,
  IconButtonClasses,
  iconOnlyStyle,
  iconStyle,
  getIconButtonCSS,
  iconVariantColorList,
} from './lib';

const IconButton = forwardRef(
  (props: IconButtonProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
    const {
      icon,
      tooltip = '',
      disabled = false,
      variant = '2',
      clickableAreaInvisible = false,
      viewSize,
      tooltipProps,
      className,
      size = 'standard',
      ...rest
    } = props;

    let IconColors = iconVariantColorList[+variant - 1];

    const iconButtonCSS =
      IconColors &&
      getIconButtonCSS({
        disabled,
        variant,
        normalColor: IconColors[0],
        hoverColor: IconColors[1],
        clickColor: IconColors[2],
        disabledColor: IconColors[3],
        clickableAreaInvisible,
        size,
      });

    const iconSize = useMemo(() => {
      let iconName = icon || '';
      let iconSize = iconName.match(/\d+/) || [16];
      return viewSize || iconSize[0];
    }, [icon, viewSize]);

    // fix warning in Firefox
    // reference: https://github.com/vercel/next.js/discussions/21999
    const hasMounted = useMounted();
    if (!hasMounted) return null;

    return (
      <Tooltip title={tooltip} placement="top" {...tooltipProps} disableFocusListener>
        {/* 往后不应再使用CharonButton-container解决IconButton的样式问题，应该使用CharonIconButton-container */}
        <Box
          className={clsx(ButtonClasses['container'], IconButtonClasses['container'], className)}
          css={{ lineHeight: 1, cursor: disabled ? 'not-allowed' : 'default' }}
        >
          <MuiButton
            disabled={disabled}
            css={[iconOnlyStyle(`${iconSize}px`), iconButtonCSS]}
            data-e2e="CharonIconButton"
            ref={ref}
            {...rest}
          >
            <Icon css={iconStyle} name={icon as icons.TIconName} viewSize={viewSize} />
          </MuiButton>
        </Box>
      </Tooltip>
    );
  }
);

export default IconButton;
