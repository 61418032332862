/*
 * Custom App
 */

import App, { AppContext } from 'next/app';
import Bowser from 'bowser';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client/react/context';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import intl from 'react-intl-universal';
import { CacheProvider } from '@emotion/react';
import Head from 'next/head';
import moment from 'moment';
import lodash from 'lodash';
import 'moment/locale/zh-cn';
import * as Sentry from '@sentry/nextjs';
import '~/styles/tailwind.css';

import { useApollo } from '~/graphql/app/client';
import { getTheme } from '~/lib/theme';
import { getLocalLanguage } from '~/lib/store';
import { LocaleType, ChangeLocaleType } from '~/types/global';
import zhCN from '~/lang/locales/zhCN.json';
import enUS from '~/lang/locales/enUS.json';
import createEmotionCache from '~/lib/createEmotionCache';
import type { AppProps } from '~/types/global';
import { ROUTE_LOGIN, ROUTE_500, ROUTE_404, ROUTE_VNC } from '~/lib/route';
import VenusWS from '~/components/common/VenusWS';
import DemonWs from '~/components/common/DemonWs';
import { ALLOW_INVALID_BROWSER, X_SDDC_TOKEN_REGX } from '~/components/forms/lib';
import Message from '~/components/common/Message';
import GlobalShortcuts from '~/components/common/GlobalShortcuts';
import FixedToolBar from '~/components/common/FixedToolbar';
import TaskCenter from '~/components/common/TaskCenter';
import '~/lib/global';
import GlobalDrawer from '~/components/common/GlobalDrawer';
import healthCheck from '~/lib/health-check';
import ServerStatus from '~/components/common/ServerStatus';
import { ACCESS_TOKEN } from '~/constants';

import AppProvider from '../providers/AppProvider';
import CommonError from './error';
import UserSettingProvider from '../providers/UserSettingsProvider';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function _App(props: AppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, login = false } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') healthCheck.start();

    return () => {
      healthCheck.stop();
    };
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);
  const apolloClient = useApollo();

  const locales = { zhCN, enUS };
  const [locale, setLocale] = React.useState<LocaleType>(getLocalLanguage() || 'zhCN');
  intl.init({ currentLocale: locale, locales });
  moment.locale(lodash.snakeCase(locale));

  const changeLocale = React.useCallback<ChangeLocaleType>(($locale: LocaleType) => {
    setLocale($locale);
    moment.locale(lodash.snakeCase($locale));
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={CommonError}>
      <ApolloProvider client={apolloClient}>
        <AppProvider>
          <CacheProvider value={emotionCache}>
            <UserSettingProvider>
              <ThemeProvider theme={getTheme({ locale })}>
                <SnackbarProvider maxSnack={5}>
                  <CssBaseline />
                  <VenusWS login={login} />
                  <DemonWs login={login} />
                  <Message />
                  <ServerStatus />
                  <GlobalDrawer />
                  <GlobalShortcuts />
                  <FixedToolBar />
                  <TaskCenter />
                  <Head>
                    <title>NeutonOS</title>
                  </Head>
                  <Sentry.ErrorBoundary fallback={CommonError}>
                    {getLayout(<Component {...pageProps} changeLocale={changeLocale} />)}
                  </Sentry.ErrorBoundary>
                </SnackbarProvider>
              </ThemeProvider>
            </UserSettingProvider>
          </CacheProvider>
        </AppProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

_App.getInitialProps = async (appContext: AppContext) => {
  const pathname = appContext.ctx?.asPath;
  const cookie = appContext?.ctx?.req?.headers?.cookie || '';

  // check browsers
  const ua = appContext?.ctx.req?.headers['user-agent'];
  if (typeof ua === 'string') {
    const browser = Bowser.getParser(ua);
    const isValidBrowser = browser.satisfies({
      chrome: '>=90',
      edge: '>=91',
      firefox: '>=78',
      electron: '>=21',
    });

    const [, allowInvalidBrowser] = cookie.match(ALLOW_INVALID_BROWSER) || [];
    if (!isValidBrowser && allowInvalidBrowser !== 'yes') {
      appContext?.ctx.res?.writeHead(302, {
        Location: '/supported-browsers.html',
      });
      appContext?.ctx.res?.end();
    }
  }

  const [, token] = cookie.match(X_SDDC_TOKEN_REGX) || [];
  const logined = !!token;

  const isVncAccessToken =
    pathname?.match(ROUTE_VNC) && pathname.match(ACCESS_TOKEN) ? true : false;

  const isServerSide = typeof window === 'undefined';
  if (isServerSide) {
    if (!pathname) {
      throw new Error('pathname is required');
    }

    if (!appContext.ctx.res) {
      throw new Error('res is required');
    }

    // 如果访问的是 vnc页面并且携带了 access token，就不redirect
    if (!logined && !pathname.match(ROUTE_LOGIN) && !isVncAccessToken) {
      let location =
        !pathname.match(ROUTE_500) && !pathname.match(ROUTE_404)
          ? `${ROUTE_LOGIN}?redirect_url=${encodeURIComponent(pathname)}`
          : `${ROUTE_LOGIN}`;
      appContext.ctx.res.writeHead(302, {
        Location: location,
      });
      appContext.ctx.res.end();
    }
  }

  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, login: logined };
};
