import { css } from '@emotion/react';

export const alertContainer = (nonBackground: boolean) => {
  return css({
    background: nonBackground ? 'none' : '',
    padding: nonBackground ? '0' : '',
    '& .CharonAlert-spin': {
      animation: 'spin 1s infinite linear',
    },
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  });
};
