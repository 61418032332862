/*
 * Create Emotion Cache
 */

import createCache from '@emotion/cache';

export default createEmotionCache;

function createEmotionCache()
{
  return createCache({ key: 'css' });
}
