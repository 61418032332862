/*
 * CSS Lib for our App
 */

import React from 'react';
import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/serialize';

import themeCSSObj from '~/styles/theme.module.scss';

import { getInfoColor, getWarningColor } from './color';

/* Constants */

// 7 + 2 + 15 = 24
const helperBoxStyle = css({
  marginLeft: '7px',
  borderLeft: `2px solid ${themeCSSObj.appFillColor5}`,
  paddingLeft: '15px',
});

const DETAIL_BOX_BOTTOM_GAP = '16px';

const DETAIL_BOX_PADDING = `${'16px'}  ${'16px'} ${'16px'} ${'8px'}`;

const detailBoxStyle = css({
  height: '100%',
  padding: DETAIL_BOX_PADDING,
  paddingBottom: DETAIL_BOX_BOTTOM_GAP,
  display: 'flex',
  flexDirection: 'column',
});

const detailBoxFullPageScrollStyle = css({
  height: '100%',
  overflow: 'auto',
  padding: DETAIL_BOX_PADDING,
});

const detailBoxTabsStyle = css({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '& .CharonTabs-body': {
    flex: 1,
    overflow: 'hidden',
  },
});

const detailBoxFullPageScrollTabsStyle = css({
  flex: 1,
  overflow: 'unset',
  display: 'flex',
  flexDirection: 'column',
  '& .CharonTabs-body': {
    flex: 1,
    overflow: 'unset',
  },
});

const contentCardStyle = css({
  height: '100%',
  backgroundColor: themeCSSObj.paletteCommonWhite,
  borderRadius: themeCSSObj.appShapeBorderRadius2,
  padding: '16px',
  boxShadow: themeCSSObj.shadows7,
});

const detailPageContentStyle = css([
  contentCardStyle,
  {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
]);

const detailPageContentListStyle = css({
  flex: 1,
  overflow: 'hidden',
});

const dotStyle = css({
  backgroundColor: themeCSSObj.appFontColor3,
  marginRight: '4px',
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  flex: '0 0 4px',
});

const optionListStyle = css({
  width: '100%',
  maxHeight: '128px',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '8px 16px',
  marginTop: '8px',
  backgroundColor: themeCSSObj.appFillColor2,
  '& .PDot': dotStyle,
});

const optionTipsStyle = css({
  backgroundColor: getInfoColor('statusBackground'),
  border: `1px solid ${getInfoColor()}`,
  borderRadius: themeCSSObj.appShapeBorderRadius1,
  padding: '8px 16px',
  marginBottom: '24px',
});

const optionWaringStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  marginBottom: '24px',
  padding: '8px 16px',
  border: `1px solid ${themeCSSObj.paletteWarningMain}`,
  borderRadius: themeCSSObj.appShapeBorderRadius1,
  background: getWarningColor('statusBackground'),
});

const normalAlertLogScatterStyle: React.CSSProperties = {
  height: '10px',
  top: '2px',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  marginRight: 0,
  padding: 0,
  background: themeCSSObj.paletteCommonWhite,
};

const scrollbarHideStyle = css({
  scrollbarWidth: 'none', // firefox
  '&::-webkit-scrollbar': {
    display: 'none' // chrome and safari
  }
});

/* Functions */

function avoidEventTargetCSS() {
  return css({
    pointerEvents: 'none', // avoid svg elements becoming an event.target
  });
}

function getEllipsisCSS() {
  return css({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  });
}

function getCircleCSS(width: number, background?: string) {
  return css({
    width: width,
    height: width,
    background,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
}

function getIconRotateAnimation(isRotated: boolean) {
  return css({
    transform: `rotate(${isRotated ? 180 : 0}deg)`,
    transition: 'transform .2s',
  });
}

function noScrollbar() {
  return css({
    scrollbarWidth: 'none', // for Firefox
    '&::-webkit-scrollbar': {
      // for Chrome and Safari
      display: 'none',
    },
  });
}

function textUnderline() {
  return css({
    textDecoration: 'underline',
  });
}

function getLinkBaseStyle(): CSSObject {
  return {
    userSelect: 'none',
    cursor: 'pointer',
  };
}

function getDisabledBaseStyle(): CSSObject {
  return {
    pointerEvents: 'none',
  };
}

function getTextUnderlineStyle(): CSSObject {
  return {
    textDecoration: 'underline',
  };
}

/**
 * 展开收起的样式
 * @param expanded
 * @param height 内容高度
 * @param maxHeight 没有提供内容高度则提供最大高度
 * @returns
 */
function getExpandableStyle(
  expanded: boolean,
  height?: number,
  maxHeight: number = 999
): CSSObject {
  return {
    overflow: 'hidden',
    maxHeight: expanded ? (height ? height : maxHeight) : 0,
    transition: 'max-height .2s',
  };
}

/**
 * 修复inlineBlock导致的空隙问题
 */
const inlineBlockGapFixStyle = css({
  '&::after': {
    content: '"\\a0"',
    display: 'inline-block',
    visibility: 'hidden',
    width: 0,
  },
});

export {
  // Constants
  helperBoxStyle,
  DETAIL_BOX_BOTTOM_GAP,
  detailBoxStyle,
  detailBoxFullPageScrollStyle,
  detailBoxTabsStyle,
  detailBoxFullPageScrollTabsStyle,
  contentCardStyle,
  detailPageContentStyle,
  detailPageContentListStyle,
  dotStyle,
  optionListStyle,
  optionTipsStyle,
  optionWaringStyle,
  inlineBlockGapFixStyle,
  normalAlertLogScatterStyle,
  scrollbarHideStyle,
  // Functions
  getEllipsisCSS,
  avoidEventTargetCSS,
  getCircleCSS,
  getIconRotateAnimation,
  noScrollbar,
  textUnderline,
  getLinkBaseStyle,
  getDisabledBaseStyle,
  getTextUnderlineStyle,
  getExpandableStyle,
};
