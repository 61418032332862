/*
 * Pluto Constants
 */

export const IMAGE_QUALITY = 100;

export const EMPTY_LABEL = '--';

export const CHART_TOP_SEPARATOR = ' — ';

export const VIRTUAL_MACHINE_VERIFY_GRAPHOCS_PASSWD_BY_ID =
  '/virtual-machines/{id}/:verify-gpasswd';
export const VIRTUAL_MACHINE_VERIFY_GRAPHOCS_PASSWD_BY_UUID =
  '/virtual-machines/{uuid}/:verify-gpasswd-by-uuid';

export const ACCESS_TOKEN = 'access_token';

export const ALERT_DURATION = 3000;
export const FR_ALERT_DURATION = 15000;

export const DIALOG_MAX_HEIGHT = 64; // MUI DIALOG 默认的最大高度边距值

export const TABLE_THUMB_WIDTH = 10; // TABLE 右侧为滚动条预留的宽度
