import { css } from '@emotion/react';

import themeCSSObj from '~/styles/theme.module.scss';
import { getSuccessColor, getWarningColor, getErrorColor, getInfoColor } from '~/lib/color';
import { MessageType } from '~/lib/message';

export const operationIconStyle = css({
  display: 'inline-flex',
  placeSelf: 'flex-start',
  cursor: 'pointer',
  marginRight: '10px',
});

export const moreIconStyle = css({
  marginRight: '4px',
});

export const alertBoxStyle = css({
  minWidth: '264px',
  maxWidth: '500px',
  wordBreak: 'break-all',
  '& .MuiAlert-action': {
    display: 'inline-flex',
    alignSelf: 'flex-start',
  },
  '& .CharonAlert-children': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  '& .MuiAlert-message': {
    overflow: 'initial',
  },
});

export function alertDetailStyle(type: MessageType) {
  let borderColor;
  let fontColor;
  let backgroundColor;

  switch (type) {
    case 'success':
      borderColor = themeCSSObj.paletteSuccessMain;
      fontColor = themeCSSObj.paletteSuccessMain;
      backgroundColor = getSuccessColor('statusBackground');
      break;

    case 'warning':
      borderColor = themeCSSObj.paletteWarningMain;
      fontColor = themeCSSObj.paletteWarningMain;
      backgroundColor = getWarningColor('statusBackground');
      break;

    case 'error':
      borderColor = themeCSSObj.paletteErrorMain;
      fontColor = themeCSSObj.paletteErrorMain;
      backgroundColor = getErrorColor('statusBackground');
      break;
    case 'info':
      borderColor = themeCSSObj.paletteInfoMain;
      fontColor = themeCSSObj.paletteInfoMain;
      backgroundColor = getInfoColor('statusBackground');
      break;
    default:
      borderColor = themeCSSObj.paletteSuccessMain;
      fontColor = themeCSSObj.paletteSuccessMain;
      backgroundColor = getSuccessColor('statusBackground');
      break;
  }
  return css({
    background: themeCSSObj.paletteCommonWhite,
    border: `1px solid ${borderColor}`,
    borderTop: 'none',
    padding: '8px 16px',
    fontSize: themeCSSObj.typographySubtitle1FontSize,
    color: fontColor,
    backgroundColor,
    borderBottomLeftRadius: themeCSSObj.appShapeBorderRadius2,
    borderBottomRightRadius: themeCSSObj.appShapeBorderRadius2,
  });
}
