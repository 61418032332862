/*
 * This file maintains all of the client side store.
 * The client side store is maintained in localStorage of the browser.
 */

import { makeVar, InMemoryCacheConfig } from '@apollo/client/cache';

import { CollectionType } from '~/__generated__/__types__';
import {
  getCollection,
  getDeveloperSetting,
  getGlobalAutoComplete,
  getGlobalTableViewConfig,
} from '~/lib/store';
import { IDeveloperSetting } from '~/components/common/DeveloperSetting/type';
import { GlobalAutoComplete, GlobalTableViewConfig } from '~/types/global';
import { SDDCPagination, SDSPagination } from '~/graphql/app/lib';
import { Query } from '~/__generated__/__types__';

type Fields = Partial<Record<keyof Query, any>>;

const collectionVar = makeVar<CollectionType[]>(getCollection());
const developerSettingVar = makeVar<IDeveloperSetting>(getDeveloperSetting());
const globalAutoCompleteVar = makeVar<GlobalAutoComplete>(getGlobalAutoComplete());
const globalTableViewConfigVar = makeVar<GlobalTableViewConfig>(getGlobalTableViewConfig());

const fields: Fields = {
  bsVolumes: SDDCPagination(),
  bsPolicies: SDDCPagination(),
  alerts: SDDCPagination(),
  alertRules: SDDCPagination(),
  auditLogs: SDDCPagination(),
  brNets: SDDCPagination(),
  bsSnaps: SDDCPagination(),
  events: SDDCPagination(),
  l3Nets: SDDCPagination(),
  nics: SDDCPagination(),
  nodes: SDDCPagination(),
  users: SDDCPagination(),
  virtualMachines: SDDCPagination('q', 'sort', 'labelValues'),
  vmCdRomSnaps: SDDCPagination(),
  vmCdRomsRespAPIResponse: SDDCPagination(),
  vmDiskSnaps: SDDCPagination(),
  vmDisksRespAPIResponse: SDDCPagination(),
  vmImages: SDDCPagination(),
  vmMigrationJobs: SDDCPagination(),
  vmNicSnaps: SDDCPagination(),
  vmNicsRespAPIResponse: SDDCPagination(),
  vmSnaps: SDDCPagination(),
  vsws: SDDCPagination(),
  labelKeys: SDDCPagination(),
  labelValuesRespAPIResponse: SDDCPagination(),
  osReleases: SDDCPagination('vmImageUsed', 'vmUsed'),
  serviceGroups: SDDCPagination(),
  osdsResp: SDSPagination('q', 'sort', 'poolId', 'bindPoolId'),
  poolsResp: SDSPagination(),
  actionLogsResp: SDSPagination(),
  accessTokens: SDDCPagination(),
  ntpServers: SDDCPagination(),
  vmSchedulingPolicies: SDDCPagination(),
  vmSnapPolicies: SDDCPagination(),
  vmSnapPolicyJobs: SDDCPagination(),
  collection: {
    read() {
      return collectionVar();
    },
  },
  developerSetting: {
    read() {
      return developerSettingVar();
    },
  },
  globalAutoComplete: {
    read() {
      return globalAutoCompleteVar();
    },
  },
  globalTableViewConfig: {
    read() {
      return globalTableViewConfigVar();
    },
  },
};

const clientStoreInMemoryCacheConfig: InMemoryCacheConfig = {
  dataIdFromObject: (object: any) => {
    if (object.id) {
      return `${object.__typename}:${object.id}`;
    } else if (object.metadata?.id !== undefined) {
      return `${object.__typename}:${object.metadata.id}`;
    } else if (object.spec?.id !== undefined) {
      return `${object.__typename}:${object.spec.id}`;
    } else if (object.status?.id !== undefined) {
      return `${object.__typename}:${object.status.id}`;
    } else if (object.data?.id) {
      return `${object.__typename}:${object.data.id}`;
    }
    return false;
  },
  typePolicies: {
    ResourceMeta: {
      keyFields: false,
      merge: true,
    },
    Query: {
      fields: fields,
    },
  },
};

export {
  clientStoreInMemoryCacheConfig,
  collectionVar,
  developerSettingVar,
  globalAutoCompleteVar,
  globalTableViewConfigVar,
};
