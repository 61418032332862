/*
 * Typography
 *
 * Owner: Anqi Shu
 */

import { ForwardedRef, useCallback } from 'react';
import React from 'react';
import { Typography as MuiTypography } from '@mui/material';
import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { isExternalLink } from '~/lib/external-link';

import type { TypographyPropsType } from './type';
import {
  getStyle,
  getTypeStyle,
  getSizeStyle,
  getInlineStyle,
  getDefaultFontWeight,
  getEllipsisStyle,
} from './lib';

function _Typography(props: TypographyPropsType, ref: ForwardedRef<HTMLDivElement>) {
  const {
    component = 'div',
    children,
    type = 't2',
    disabled = false,
    size = 's2',
    isInline = false,
    color = '',
    fontWeight = getDefaultFontWeight(),
    isEllipsis = false,
    className = '',
    underline = false,
    link,
    onClick,
    ...rest
  } = props;

  const router = useRouter();
  const extraCSS: CSSObject = {};
  if (color) extraCSS.color = color;
  if (fontWeight) extraCSS.fontWeight = fontWeight;

  const handleClickContainer = useCallback(() => {
    if (disabled) return;
    if (link && isExternalLink(link)) {
      console.log('open external link: ', link);
      window.open(link, '_blank', 'noreferrer');
      return;
    } else if (link) {
      router.push(link);
    }
    onClick && onClick();
  }, [disabled, link, router, onClick]);

  return (
    <MuiTypography
      component={component}
      ref={ref}
      css={[
        getStyle(),
        getTypeStyle(type, disabled, !!link || !!onClick, underline),
        getSizeStyle(size),
        getInlineStyle(isInline),
        isEllipsis ? getEllipsisStyle() : css({}),
        css(extraCSS),
        css({
          cursor: disabled ? 'not-allowed' : link || onClick ? 'pointer' : '',
        }),
      ]}
      className={clsx('CharonTypography', className)}
      onClick={handleClickContainer}
      {...rest}
    >
      {children}
    </MuiTypography>
  );
}

const Typography = React.forwardRef(_Typography);

export type { TypographyPropsType };
export default Typography;
