/*
 * Client Side E2E
 */

type ReqType = any;
type ResType = any;

interface InterceptorContentType {
  req?: {
    operationName: string;
    content: any;
  };
  res?: {
    operationName: string;
    content: any;
  };
}

type KeyType = keyof InterceptorContentType;

const TAG_PREFIX = 'e2e-intercept-stamp-';

function getSessionList(sessionName: string) {
  const TAG = TAG_PREFIX + sessionName;
  const list = document.querySelectorAll(`div[data-e2e="${TAG}"]`);
  return list;
}

function isSessionExists(sessionName: string) {
  const list = getSessionList(sessionName);
  return list.length > 0;
}

function addStamp(content: InterceptorContentType, sessionName: string, key: KeyType) {
  const list = getSessionList(sessionName);
  list.forEach(function (a) {
    const span = document.createElement('span');
    span.setAttribute('data-e2e', key);
    span.innerHTML = JSON.stringify(content);
    a.appendChild(span);
  });
}

function insertInterceptContent(req: ReqType, res: ResType) {
  const sessionName = req.operationName;
  if (!isSessionExists(sessionName)) return;

  const reqObj = {
    operationName: req.operationName,
    content: {
      variables: req.variables,
    },
  };
  addStamp({ req: reqObj }, sessionName, 'req');

  const resData = res.data;
  if (resData) {
    let operationName: string = '';
    for (const key in resData) {
      if (key) {
        operationName = key;
        break;
      }
    }
    const resObj = {
      operationName,
      content: {
        data: resData,
      },
    };
    addStamp({ res: resObj }, sessionName, 'res');
  }
}

export { insertInterceptContent };
