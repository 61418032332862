/**
 * Pluto的Sentry插件
 * - 发送rrweb日志
 * - 在遇到错误时根据是否打开主动上报选项来弹出提示框
 * - TODO: 离线日志处理
 */
import { DsnComponents, EventProcessor, Hub, Integration } from '@sentry/types';
import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';

import PRRWeb, { RRWebEvent } from '~/lib/p-rrweb';

const { publicRuntimeConfig } = getConfig();

export class PlutoIntegration implements Integration {
  public static id: string = 'Pluto-Integration';

  public readonly name: string = PlutoIntegration.id;

  /**
   * Sentry Hub
   */
  public hub: Hub | undefined;

  public constructor() {
    if (publicRuntimeConfig.SENTRY_ENABLE === 'yes') {
      PRRWeb.start();
    }
  }

  /**
   * Sentry 初始化
   * @param addGlobalEventProcessor
   * @param getCurrentHub
   */
  setupOnce(
    addGlobalEventProcessor: (callback: EventProcessor) => void,
    getCurrentHub: () => Hub
  ): void {
    this.hub = getCurrentHub();

    addGlobalEventProcessor((event, hint) => {
      if (this.hub && this.hub.getIntegration(PlutoIntegration)) {
        if (!event.event_id) {
          return event;
        }
        if (event.extra?.rrwebEvents) {
          // 对于反馈的这种情况，录像的停止时间和事件发送的事件不一致，需要能自定义
          this._sendRRWeb(event.extra.rrwebEvents as RRWebEvent[], event.event_id);
          delete event.extra.rrwebEvents;
        } else {
          this._sendRRWeb(PRRWeb.events, event.event_id);
        }
      }
      return event;
    });
  }

  /**
   * rrweb 录像上传对应地址
   * TODO: 转发到node server
   * @param dsn
   * @param eventId
   * @returns
   */
  public attachmentUrlFromDsn(dsn: DsnComponents | undefined, eventId: string) {
    if (!dsn) {
      throw new Error('dsn is undefined');
    }

    const { host, path, projectId, port, protocol, user } = dsn;

    return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
      path !== '' ? `/${path}` : ''
    }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=rrweb`;
  }

  /**
   * 发送录像
   * @param rrwebEvents
   * @param event_id
   * @returns
   */
  private async _sendRRWeb(rrwebEvents: Array<RRWebEvent>, event_id: string) {
    const client = Sentry.getCurrentHub().getClient();

    const endpoint = this.attachmentUrlFromDsn(client?.getDsn(), event_id);

    const formData = new FormData();
    formData.append(
      'rrweb',
      new Blob([JSON.stringify({ events: rrwebEvents })], {
        type: 'application/json',
      }),
      'rrweb.json'
    );

    return fetch(endpoint, {
      method: 'POST',
      body: formData,
    }).catch((ex) => {
      // we have to catch this otherwise it throws an infinite loop in Sentry
      console.error(ex);
    });
  }
}
