// Owner: Zhen Liu
// Link: https://www.figma.com/file/yH2At4lyrzmEgK6cnNGRTG/SDS-%E8%AE%BE%E8%AE%A1%E7%B3%BB%E7%BB%9F?node-id=182%3A4481

import React from 'react';
import clsx from 'clsx';
import MuiTooltip from '@mui/material/Tooltip';

import { popperProps } from './lib';
import { TooltipProps } from './type';

export default function Tooltip(props: TooltipProps) {
  const { children, arrow = true, className, size = 'small', ...rest } = props;
  return (
    <MuiTooltip
      arrow={arrow}
      placement="top"
      PopperProps={popperProps(size)}
      className={clsx('CharonTooltip-root', className)}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}

/**
 * 处理子组件可能为 disabled 的情况
 * @param props
 * @returns
 */
export function WithTooltip<T>(props: {
  title?: NonNullable<React.ReactNode>;
  disabled?: boolean;
  tooltipProps?: TooltipProps;
  children: React.ReactElement<T>;
}) {
  const { title = '', disabled } = props;

  return (
    <Tooltip title={title} {...props.tooltipProps}>
      {disabled ? (
        <span
          style={{
            display: 'inline-flex',
          }}
        >
          {props.children}
        </span>
      ) : (
        props.children
      )}
    </Tooltip>
  );
}
