import { css } from '@emotion/react';
import { drawerClasses } from '@mui/material/Drawer';

import globalCSSObj from '~/styles/global.module.scss';

export const getDrawerCSS = (width: string, bottomHeight: string | undefined) =>
  css({
    bottom: bottomHeight,
    [`.${drawerClasses['paper']}`]: {
      width: width,
      height: bottomHeight
        ? `calc(100% - ${globalCSSObj.topNaviHeight} - ${bottomHeight})`
        : `calc(100% - ${globalCSSObj.topNaviHeight})`,
      marginTop: globalCSSObj.topNaviHeight,
    },
    '> .MuiBackdrop-root': {
      opacity: '0 !important',
    },
  });
